import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ProfilLayout from "./Layout/ProfilLayout";
import Layout from "./Layout/index";
import Page404 from "./Pages/404";
import ForgotPassword from "./Pages/Auth/ForgotPassword/index";
import ResetPassword from "./Pages/Auth/ResetPassword/index";
import SignIn from "./Pages/Auth/SignIn/index";
import SignUp from "./Pages/Auth/SignUp/index";
import { FinalStep } from "./Pages/Auth/SignUp/process/FinalStep";
import { ProcessStep1 } from "./Pages/Auth/SignUp/process/Step1";
import { ProcessStep2 } from "./Pages/Auth/SignUp/process/Step2";
import { ProcessStep3 } from "./Pages/Auth/SignUp/process/Step3";
import { ProcessStep4 } from "./Pages/Auth/SignUp/process/Step4";
import { ProcessStep5 } from "./Pages/Auth/SignUp/process/Step5";
import UpdatePassword from "./Pages/Auth/UpdatePassword/index";
import Candidatures from "./Pages/Candidatures";
import Contrats from "./Pages/Contrats/index";
import Dashboard from "./Pages/Dashboard";
import MissionPage from "./Pages/Mission";
import Missions from "./Pages/Missions/index";
import MissionsFavoris from "./Pages/MissionsFavoris";
import Parrainage from "./Pages/Parrainage/index";
import DocumentsLegaux from "./Pages/Profil/DocumentsLegaux/index";
import Invoicing from "./Pages/Profil/Invoicing/index";
import InformationsGenerales from "./Pages/Profil/InformationsGenerales";
import Parametres from "./Pages/Profil/Parametres/index";
import PreferencesMissions from "./Pages/Profil/PreferencesMissions/index";
import ProfilPro from "./Pages/Profil/ProfilPro/index";
import Timesheet from "./Pages/Timesheet";
import "./Styles/Base.css";

const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route
            path="/auth"
            exact
            render={() => {
              return <Redirect to="/auth/sign-in" />;
            }}
          />
          <Route path="/auth/sign-in" exact>
            <SignIn />
          </Route>
          <Route path={"/auth/sign-up"} exact>
            <SignUp></SignUp>
          </Route>

          <Route path={"/auth/sign-up/step-1"} exact>
            <ProcessStep1 />
          </Route>
          <Route path={"/auth/sign-up/step-2"} exact>
            <ProcessStep2 />
          </Route>
          <Route path={"/auth/sign-up/step-3"} exact>
            <ProcessStep3 />
          </Route>
          <Route path={"/auth/sign-up/step-4"} exact>
            <ProcessStep4 />
          </Route>
          <Route path={"/auth/sign-up/step-5"} exact>
            <ProcessStep5 />
          </Route>
          <Route path={"/auth/sign-up/final"} exact>
            <FinalStep />
          </Route>

          <Route path="/auth/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/auth/reset-password/:token" exact>
            <ResetPassword />
          </Route>
          <Route path="/auth/update-password" exact>
            <UpdatePassword />
          </Route>

          <Route path="/" exact>
            <Dashboard />
          </Route>
          <Route path="/parrainage" exact>
            <Parrainage />
          </Route>
          <Route path="/missions" exact>
            <Missions />
          </Route>
          <Route path="/missions/favorites" exact>
            <MissionsFavoris />
          </Route>
          <Route path="/mission/:id" exact>
            <MissionPage />
          </Route>
          <Route path="/candidatures/:type" exact>
            <Candidatures />
          </Route>
          <Route path="/candidatures" exact>
            <Candidatures />
          </Route>
          <Route path="/contrats" exact>
            <Contrats />
          </Route>
          <Route path="/timesheet/:id/:year/:month" exact>
            <ProfilLayout>
              <Timesheet />
            </ProfilLayout>
          </Route>
          <Route path="/timesheet" exact>
            <ProfilLayout>
              <Timesheet />
            </ProfilLayout>
          </Route>

          {/* Profile pages */}
          <Route path="/profile">
            <ProfilLayout>
              <Switch>
                <Route
                  path="/profile"
                  exact
                  render={() => (
                    <Redirect to="/profile/informations-generales" />
                  )}
                />
                <Route path="/profile/informations-generales">
                  <InformationsGenerales />
                </Route>
                <Route path="/profile/profil-professionnel">
                  <ProfilPro />
                </Route>
                <Route path="/profile/preferences-missions">
                  <PreferencesMissions />
                </Route>
                <Route path="/profile/documents-legaux">
                  <DocumentsLegaux />
                </Route>
                <Route path="/profile/Factures">
                  <Invoicing />
                </Route>
                <Route path="/profile/parametres">
                  <Parametres />
                </Route>

                <Route render={() => <Redirect to="/404" />}></Route>
              </Switch>
            </ProfilLayout>
          </Route>

          <Route>
            <Page404 />
          </Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
