import React from "react";
import Popup from "../Popup";

import style from "./style.module.css";

export const TimesheetConfirmationPopup = ({ show, onClose, onValidate }) => {
  return (
    <Popup
      show={show}
      onClose={(e) => {
        onClose(e);
      }}
    >
      <div className="row py-5">
        <div className="d-flex flex-column">
          <span className="text-center red">
            Attention, vous n'avez pas complété la totalité des jours du mois.
            Les journées non renseignées seront considérées comme non
            travaillées.
          </span>
          <span className={`mt-5 ${style["confirm-message"]} text-center bold`}>
            Êtes-vous sûr(e) de vouloir confirmer ?
          </span>
        </div>
        <div className="col-12 d-flex flex-row justify-content-center mt-4 gap-4">
          <div className="w-auto">
            <button
              type="reset"
              className="default-btn danger-btn"
              onClick={onClose}
            >
              Modifier
            </button>
          </div>
          <div className="w-auto">
            <button type="button" className="default-btn" onClick={onValidate}>
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
};
