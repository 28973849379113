import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { SIGN_UP_ACTIONS } from "../../../../../Redux/SignUp/actions";
import { StepCounter } from "../StepCounter";
import styles from "../style.module.css";

// TODO ADD PREVIOUS BUTTONS ON MOBILE VIEW

const linkedInUriErrorStr = "Lien non valide";
const phoneErrorStr = "Veuillez saisir un numéro valide";
const Step1Schema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required()
    .min(10, phoneErrorStr)
    .max(10, phoneErrorStr)
    .matches(
      /^(?:(?:\+|00)(33|32)[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/g,
      phoneErrorStr
    ),
  linkedInUri: Yup.string()
    .required()
    .matches(
      // /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm,
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\//gm,
      linkedInUriErrorStr
    ),
});
export const ProcessStep1 = () => {
  const prefix = "+33";
  const [countryPrefix, setCountryPrefix] = useState(prefix);

  const dispatch = useDispatch();
  const history = useHistory();
  const signUpStepper = useSelector((state) => state.signUpReducer);

  const formik = useFormik({
    validationSchema: Step1Schema,
    initialValues: {
      phoneNumber: signUpStepper.phoneNumber,
      linkedInUri: signUpStepper.linkedInUri,
    },
    onSubmit: (values) => {
      dispatch({ type: SIGN_UP_ACTIONS.STEP_1, payload: values });
      history.push("step-2");
    },
  });

  const setNewFlag = (e) => {
    setCountryPrefix(e.target.value);
  };

  const phoneNumberRequiredField = formik.errors.phoneNumber &&
    formik.errors.phoneNumber === phoneErrorStr && (
      <div className={`red-text small-text align-right ${styles["error"]}`}>
        {formik.errors.phoneNumber}
      </div>
    );

  const linkedinRequiredField = formik.errors.linkedInUri &&
    formik.errors.linkedInUri === linkedInUriErrorStr && (
      <div className={`small-text red-text ${styles["error"]}`}>
        {formik.errors.linkedInUri}
      </div>
    );

  return (
    <form className={styles["container"]} onSubmit={formik.handleSubmit}>
      <div></div>
      <div>
        <section>
          <label>Sur quel numéro peut-on vous appeler ?</label>
          <div className={`${styles["step-1-select-number"]}`}>
            <select onChange={setNewFlag} value={countryPrefix}>
              <option value="+32">🇧🇪&emsp;Belgique</option>
              <option value="+34">🇪🇸&emsp;Espagne</option>
              <option value="+33">🇫🇷&emsp;France</option>
              <option value="+352">🇱🇺&emsp;Luxembourg</option>
              <option value="+380">🇺🇦&emsp;UK</option>
              <option value="+41">🇪🇸&emsp;Suisse</option>
            </select>
            <div className={styles["phoneNumber"]}>
              <span>{countryPrefix} </span>
              <input
                id="phoneNumber"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                required={true}
                minLength={10}
                maxLength={10}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          {phoneNumberRequiredField}
        </section>
        <section>
          <label>Quelle est votre URL Linkedin ?</label>
          <input
            id="linkedInUri"
            name="linkedInUri"
            value={formik.values.linkedInUri}
            onChange={formik.handleChange}
            placeholder="https://www.linkedin.com/in/mon-profil"
          />
        </section>
        {linkedinRequiredField}
      </div>
      <div>
        {formik.dirty && formik.isValid && (
          <div className="text-center mb-5">
            <button type="submit" className="sign-btn">
              Suivant
            </button>
          </div>
        )}
        <StepCounter index={1}></StepCounter>
      </div>
    </form>
  );
};
