import { useEffect, useState } from "react";
import { SuggestiveResults } from "../../../../../../Components/SuggestiveResults";
import { useSuggestions } from "../../../../../../Hooks/useSuggestions";
import { getSpokenLanguages } from "../../../../../../Services/SignStep";
import { SignUpButtonSelection } from "../SkillForm/SignUpButtonSelection";

export const LanguageForm = ({ selectedLanguages, setSelectedLanguages }) => {
  const options = [
    "Notions",
    "Capacité professionnelle limitée",
    "Capacité professionnelle complète",
    "Bilingue ou natif",
  ];

  const { search, onChangeSearch, displaySuggestions, onClickSuggestion } =
    useSuggestions({ callFunction: null });

  const [languages, setLanguages] = useState([]);

  const onDeleteLang = (index) => {
    const newLangs = [...selectedLanguages];
    newLangs.splice(index, 1);
    setSelectedLanguages(newLangs);
  };

  const selectSuggestionHandler = (newLang) => {
    if (!selectedLanguages.find(({ label }) => label === newLang)) {
      setSelectedLanguages((oldLang) => [
        ...oldLang,
        { label: newLang, level: "Notions" },
      ]);
    }
    onClickSuggestion();
  };

  const onChangeSkillLvlHandler = (index, str) => {
    const langs = [...selectedLanguages];
    langs[index].level = str;
    setSelectedLanguages(langs);
  };

  useEffect(() => {
    getSpokenLanguages().then((res) => setLanguages(res.data));
  }, []);

  let langSuggestions = languages?.length > 0 && languages.map((lang) => lang);
  langSuggestions =
    langSuggestions?.length > 0 &&
    langSuggestions.filter((lang) =>
      lang.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <div>
      <section>
        <label>Quelle(s) langue(s) parlez-vous ?</label>
        <input
          id="language"
          name="language"
          placeholder="Français"
          onChange={onChangeSearch}
        />
        {displaySuggestions && langSuggestions && (
          <SuggestiveResults
            results={langSuggestions}
            onClick={selectSuggestionHandler}
          ></SuggestiveResults>
        )}
        {(selectedLanguages || []).map(
          ({ label, level: selectedLevel }, index) => {
            return (
              <SignUpButtonSelection
                key={index}
                index={index}
                label={label}
                onClick={() => onDeleteLang(index)}
                options={options}
                onClickOptions={onChangeSkillLvlHandler}
                level={selectedLevel}
              />
            );
          }
        )}
      </section>
    </div>
  );
};
