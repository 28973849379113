import { FiUser } from "react-icons/fi";

export const b64ToObjectUrl = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return URL.createObjectURL(blob);
};

export const getConsultantLoginPicture = (b64Data, contentType = "") => {
  if (!b64Data || !contentType) {
    return <FiUser className="header-3" />;
  }

  const url = b64ToObjectUrl(b64Data, contentType);
  return <img src={url} alt="profile wow" />;
};
