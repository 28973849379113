import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { FiEye } from "react-icons/fi";
import { useFormik } from "formik";
import { updatePassword } from "../../../Services/Auth";
import * as Yup from "yup";
import toast from "./../../../Helpers/toast";
import { useSelector } from "react-redux";
import { getConsultant } from "../../../Services/User";
import { useHistory } from "react-router-dom";

const UpdatePwdSchema = Yup.object().shape({
  oldPassword: Yup.string().matches(
    "(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]).*$",
    "Doit contenir à la fois : 8 caractères minimum, un caractère en majuscule, un caractère en minuscule, un chiffre et un caractère spécial."
  ),
  newPassword: Yup.string().matches(
    "(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]).*$",
    "Doit contenir à la fois : 8 caractères minimum, un caractère en majuscule, un caractère en minuscule, un chiffre et un caractère spécial."
  ),
});

const UpdatePassword = () => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const consultantId = useSelector((state) => state.userReducer.consultantId);
  const [currentEmail, setCurrentEmail] = useState("");
  const history = useHistory();

  const formik = useFormik({
    validationSchema: UpdatePwdSchema,
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    onSubmit: (values) => {
      const form = { ...values, email: currentEmail };
      updatePassword(form).then((res) => {
        toast("success", "Mot de passe changé avec succès");
        history.goBack();
      });
    },
  });

  useEffect(() => {
    if (consultantId && !currentEmail) {
      getConsultant(consultantId).then((res) =>
        setCurrentEmail(res.data.user.email)
      );
    }
  }, [consultantId, currentEmail]);

  return (
    <form
      className={`w-100 mx-auto ${style["update-password"]}`}
      onSubmit={formik.handleSubmit}
    >
      <img
        className="wow-logo mb-5"
        src="/wow-ny-logo/wow-inline.svg"
        alt="WheelOfWork logo"
      />
      <div className="header-4">Changer votre mot de passe</div>
      <div className="w-100 d-flex flex-column">
        <label htmlFor="oldPassword" className="default-input-label">
          Ancien Mot de passe
        </label>
        <div className="w-100 position-relative">
          <input
            id="oldPassword"
            name="oldPassword"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            className="default-input"
            type={show1 ? "text" : "password"}
            required={true}
            placeholder=""
            autoComplete="off"
          />
          <FiEye className="show-pwd" onClick={(e) => setShow1(!show1)} />
        </div>
      </div>
      <div className="w-100 d-flex flex-column">
        <label htmlFor="newPassword" className="default-input-label">
          Nouveau mot de passe
        </label>
        <div className="w-100 position-relative">
          <input
            id="newPassword"
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            className="default-input"
            type={show2 ? "text" : "password"}
            required={true}
            placeholder=""
            autoComplete="off"
          />
          <FiEye className="show-pwd" onClick={(e) => setShow2(!show2)} />
        </div>
        <div className="small-text red-text mt-1" style={{ height: "48px" }}>
          {formik.errors.newPassword}
        </div>
      </div>
      <button type="submit" className="default-btn">
        Continuer
      </button>
    </form>
  );
};

export default UpdatePassword;
