import React, { useState } from "react";
import { withRouter } from "react-router";
import style from "./style.module.css";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { resetPassword } from "../../../Services/Auth";
import toast from "./../../../Helpers/toast";

const ResetPwdSchema = Yup.object().shape({
  password: Yup.string().matches(
    "(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]).*$",
    "Doit contenir à la fois : 8 caractères minimum, un caractère en majuscule, un caractère en minuscule, un chiffre et un caractère spécial."
  ),
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Les mots de passe ne sont pas identiques"
    ),
  }),
});

const ResetPassword = ({ match, history }) => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const formik = useFormik({
    validationSchema: ResetPwdSchema,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      resetPassword({
        token: match.params.token,
        password: values.password,
      }).then((res) => {
        history.push("/auth/sign-in");
        toast("success", "Mot de passe changé avec succès");
      });
    },
  });
  return (
    <form
      className={`w-100 mx-auto ${style["reset-password"]}`}
      onSubmit={formik.handleSubmit}
    >
      <img
        className="wow-logo mb-5"
        src="/wow-ny-logo/wow-inline.svg"
        alt="WheelOfWork logo"
      />
      <div className={`header-4 ${style["white-color"]}`}>
        Réinitialisez votre mot de passe
      </div>
      <div className="w-100 d-flex flex-column">
        <div className="w-100 position-relative">
          <input
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            className="sign-input"
            type={show1 ? "text" : "password"}
            required={true}
            placeholder="Mot de passe"
            autoComplete="off"
          />
          <FiEye className="show-pwd" onClick={(e) => setShow1(!show1)} />
        </div>
        <div className="small-text red-text mt-1" style={{ height: "40px" }}>
          {formik.errors.password}
        </div>
      </div>
      <div className="w-100 d-flex flex-column">
        <div className="w-100 position-relative">
          <input
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            className="sign-input"
            type={show2 ? "text" : "password"}
            required={true}
            placeholder="Confirmer mot de passe"
            autoComplete="off"
          />
          <FiEye className="show-pwd" onClick={(e) => setShow2(!show2)} />
        </div>
        <div className="small-text red-text mt-1" style={{ height: "40px" }}>
          {formik.errors.confirmPassword}
        </div>
      </div>
      <button
        type="submit"
        style={{ backgroundColor: "white", color: "black" }}
        className="default-btn"
      >
        Continuer
      </button>
      <div className={`mt-4 small-text ${style["white-color"]}`}>
        Je n'ai pas de compte -
        <Link to="/auth/sign-up" className="green-text mx-1">
          créer un compte
        </Link>
      </div>
    </form>
  );
};

export default withRouter(ResetPassword);
