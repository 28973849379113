export const getRangeWeekDaysBetweenDate = (startDate, endDate, holidays) => {
  let days = [];
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  while (date1 < date2) {
    const dayNo = date1.getDay();
    const newDate = new Date(date1);
    let isDayHoliday = false;

    if (holidays?.length > 0) {
      const matchingHoliday = holidays.find(
        (h) => new Date(h.date).getDate() === newDate.getDate()
      );
      if (matchingHoliday) {
        isDayHoliday = true;
      }
    }

    if (dayNo !== 0 && dayNo !== 6 && !isDayHoliday) {
      days.push({ dayNo, date: newDate });
    }

    date1.setDate(date1.getDate() + 1);
  }
  return days;
};

export const getAllWeekendsEvents = (year, month) => {
  const currentDay = new Date(year, month, 0).getDate();

  const resEvents = [];
  for (let day = 1; day <= currentDay; day++) {
    const date = new Date(year, month, day);
    const indexJour = date.getDay();
    if (indexJour === 0 || indexJour === 6) {
      resEvents.push(date);
    }
  }

  return resEvents;
};
