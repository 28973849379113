import React from "react";
import { useSelector } from "react-redux";
import { Redirect, withRouter } from "react-router";
import AppLayout from "./AppLayout/index";
import AuthLayout from "./AuthLayout/index";

const Layout = ({ children, location }) => {
  const isUserConnected = useSelector((state) => state.userReducer.isLoggedIn);
  const BootstrappedLayout = () => {
    if (
      !isUserConnected &&
      (location.pathname.split("/").includes("auth") ||
        location.pathname === "/auth/update-password" ||
        location.pathname.includes("/auth/reset-password"))
    )
      return <AuthLayout>{children}</AuthLayout>;
    if (!isUserConnected)
      return (
        <Redirect
          to={{
            pathname: "/auth/sign-in",
            state: { previousRoute: location.pathname },
          }}
        />
      );
    return <AppLayout>{children}</AppLayout>;
  };
  return <>{BootstrappedLayout()}</>;
};

export default withRouter(Layout);
