import { FiUser } from "react-icons/fi";
import { getConsultantLoginPicture } from "./Helpers/base64-decode";
import toast from "./Helpers/toast";

export const GOOGLE_STORAGE_KEY = "googleSignedIn";
export const LINKEDIN_STORAGE_KEY = "linkedinSignedIn";
export const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const windowHeight = window.innerHeight;
  const yOffset = -(windowHeight / 8);
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export const getGoogleStorageConnection = () =>
  window.sessionStorage.getItem(GOOGLE_STORAGE_KEY);

export const setGoogleStorageConnection = (sessionVal) => {
  window.sessionStorage.setItem(GOOGLE_STORAGE_KEY, sessionVal);
};

/**
 * Method returning the picture of the consultant
 * If the connection method is google, then return google picture
 * otherwise return the picture saved in database by the consultant
 * @param currentConsultant
 * @returns
 */
export const displayConsultantPictureWithConnectionMethod = (
  currentConsultant
) => {
  // If user is connected with linkedin return fiUser
  if (getSessionStorageVal(LINKEDIN_STORAGE_KEY)) {
    return <FiUser className="header-3" />;
  }

  // if consultant does not have picture return fiUser
  if (!currentConsultant || !currentConsultant.profilePicture) {
    return <FiUser className="header-3" />;
  }

  // if user is connected with google return the avatar
  if (getGoogleStorageConnection() === "true") {
    return (
      <img src={currentConsultant.profilePicture.logoUrl} alt="wow profile" />
    );
  }

  // else return it's photo or fiUser if not exist
  return getConsultantLoginPicture(
    currentConsultant?.profilePicture?.base64Document,
    currentConsultant?.profilePicture?.filetype
  );
};

export const toastError = (error) => {
  if (!error || !error.response) {
    return;
  }
  const str = error.response.data.reason;
  const code = error.response.data.status;
  switch (code) {
    case 403:
      toast("error", str);
      break;
    default:
      return toast("error", str);
  }
};

export const getSessionStorageVal = (key) => {
  return window.sessionStorage.getItem(key);
};
