import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FiCheck, FiEye } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import Api from "../../../Helpers/api";
import toast from "../../../Helpers/toast";
import { userActions } from "../../../Redux/User/actions";
import { takeControlAdminPanel } from "../../../Services/Auth";
import style from "./style.module.css";
const queryString = require("query-string");

const SigninSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  password: Yup.string().matches(
    "(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]).*$",
    "Doit contenir à la fois : 8 caractères minimum, un caractère en majuscule, un caractère en minuscule, un chiffre et un caractère spécial."
  ),
});

const SignIn = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const formik = useFormik({
    validationSchema: SigninSchema,
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    onSubmit: (values) => {
      const remember = values.remember;
      const state = { ...values };
      delete state.remember;
      Api.post("/auth/login", { ...values })
        .then((res) => {
          if (res.status === 201 && res.data.id) {
            if (remember) {
              localStorage.setItem("CONSULTANT_ID", res.data.id);
              localStorage.setItem("USER_ID", res.data.user.id);
            } else {
              sessionStorage.setItem("CONSULTANT_ID", res.data.id);
              sessionStorage.setItem("USER_ID", res.data.user.id);
            }
            dispatch({
              type: userActions.LOGIN_USER,
              payload: { consultant: res.data },
            });
            if (history.location?.state?.previousRoute) {
              history.push(history.location.state.previousRoute);
            } else {
              history.push("/");
            }
          }
        })
        .catch((e) => {
          if (e.response.status === 403)
            toast("error", "Votre compte n'est pas activé.");
          else toast("error", "Email ou mot de passe incorrect.");
        });
    },
  });

  useEffect(() => {
    const consultantId = queryString.parse(location.search).consultantId;
    const bearer = queryString.parse(location.search).bearer;
    if (consultantId && bearer) {
      // clear all storage
      localStorage.clear();
      sessionStorage.clear();
      dispatch({
        type: userActions.LOGOUT_USER,
      });
      takeControlAdminPanel(consultantId, bearer).then((res) => {
        sessionStorage.setItem("CONSULTANT_ID", res.data.id);
        dispatch({
          type: userActions.LOGIN_USER,
          payload: { consultant: res.data },
        });
        history.push("/");
      });
    }
    if (localStorage.getItem("CONSULTANT_ID")) {
      history.push("/");
    }
    const code = queryString.parse(location.search).code;
    if (code) {
      Api.patch("/account-verification/", { code }).then((res) => {
        toast("success", "Félicitations, votre compte a été validé !");
      });
    }
  }, [location]);

  const routeToCreate = () => {
    history.push(`/auth/sign-up`);
  };

  const routeToPassword = () => {
    history.push("forgot-password");
  };

  return (
    <form className={`${style["sign-in"]}`} onSubmit={formik.handleSubmit}>
      <div className={`${style["white-color"]} w-100`}>
        <h3>ME CONNECTER</h3>
        <p>
          Ou
          <span
            onClick={routeToCreate}
            className={`${style["white-color"]} underline ${style["margin-left"]}`}
          >
            créer un compte
          </span>
        </p>
      </div>
      <div className="w-100 d-flex flex-column">
        <input
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          type="email"
          required={true}
          className="sign-input"
          placeholder="Email"
          autoComplete="off"
        />
      </div>
      <div className="w-100 d-flex flex-column">
        <div className={`${style["max-width"]} w-100 position-relative m-auto`}>
          <input
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            className="sign-input"
            type={show ? "text" : "password"}
            required={true}
            placeholder="Mot de passe"
            autoComplete="off"
          />
          <FiEye className="show-pwd" onClick={(e) => setShow(!show)} />
        </div>
        {formik.errors?.password && (
          <div className="small-text red-text mt-1" style={{ height: "48px" }}>
            {formik.errors.password}
          </div>
        )}
      </div>
      <button className={`${style["button"]} action`} type="submit">
        Connexion
      </button>
      <div
        className={`${style["max-width"]} w-100 d-flex flex-row justify-content-between align-items-center`}
      >
        <div className="mx-2 d-flex flex-row align-items-center">
          <input
            id="remember"
            name="remember"
            value={formik.values.remember}
            onChange={formik.handleChange}
            type="checkbox"
            hidden={true}
          />
          <label htmlFor="remember" className="custom-checkbox">
            <FiCheck />
          </label>
          <label
            htmlFor="remember"
            className={`${style["white-color"]} small-text`}
          >
            Se souvenir de moi
          </label>
        </div>
        <div
          onClick={routeToPassword}
          className={`${style["white-color"]} small-text ${style["forgotten-password"]}`}
        >
          Mot de passe oublié?
        </div>
      </div>
    </form>
  );
};

export default SignIn;
