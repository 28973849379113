import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { months } from "../../Helpers/data";
import { editProfileFiles } from "../../Services/User";
import Helper from "../Helper";
import toast from "../../Helpers/toast";
import { AddInvoice as addInvoice } from "../../Services/Invoice";
import Popup from "../Popup/index";
import style from "./style.module.css";

const AddInvoice = ({ show, onClose }) => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const history = useHistory();
  const factureRef = useRef(null);
  const [facture, setFacture] = useState(null);
  const [missions, setMissions] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mission: missions.length ? missions[0].id : "",
      amount: "",
      month: new Date().getMonth() + 1,
      type: 'INVOICE',
      year: Number(new Date().getFullYear()),
    },
    onSubmit: (values) => {
      if (facture) {
        const { month, year, mission, amount, type } = values;
        addInvoice({
          month,
          year,
          amount,
          consultant: { id: currentConsultant.id },
          type,
          mission: { id: mission },
        })
          .then((res) => {
            const formData = new FormData();
            formData.append("missionId", res.data.mission.id);
            formData.append("consultantId", res.data.consultant.id);
            formData.append("invoiceId", res.data.id);
            formData.append("file", facture);

            editProfileFiles(formData, "invoice", {
              consultantId: res.data.consultant.id,
              missionId: res.data.mission.id,
              invoiceId: res.data.id,
            }).then((res) => {
              toast("success", "Facture ajoutée avec succès !");
              history.push("/profile/factures");
              onClose();
            });
          })
          .catch((e) => {
            if (e.response) {
              if (e.response.status === 404) {
                toast(
                  "error",
                  "Vous pourrez ajouter une facture une fois votre CRA créé et validé"
                );
              }
              if (e.response.status === 403) {
                if (e.response.data.message === "Wage ported consultant cannot submit invoices" || e.response.data.reason === "Wage ported consultant cannot submit invoices") {
                  toast(
                    "error",
                    "Vous ne pouvez pas ajouter de facture sur le mois sélectionné car vous étiez en portage salarial"
                  );
                }
                else {
                  toast(
                    "error",
                    "Vous pourrez ajouter une facture une fois votre CRA validé"
                  );
                }
              }
              // if (e.response.status === 409) {
              //   toast(
              //     "error",
              //     "Une facture a déjà été uploadée pour cette mission et le mois indiqué"
              //   );
              // }
            }
          });
      } else {
        toast("error", "Veuillez saisir une facture");
      }
    },
  });

  const uploadFacture = (e) => {
    if (e.target.files[0]) {
      setFacture(e.target.files[0]);
    }
  };
  useEffect(() => {
    if (currentConsultant) {
      setMissions(currentConsultant.missions);
    }
  }, [currentConsultant]);

  return (
    <Popup show={show} onClose={onClose}>
      <form
        className="row px-2 px-md-5 py-5 gap-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="col-12 d-flex flex-column gap-2">
          <div className="wow-body bold">Choisir une mission</div>
          <select
            name="mission"
            id="mission"
            value={formik.values.mission}
            onChange={formik.handleChange}
            className="default-input"
            required
          >
            {missions.map((mission) => (
              <option key={mission.id} value={mission.id}>
                {mission.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 d-flex flex-row gap-5">
          <div className="w-100 d-flex flex-column gap-2">
            <div className="wow-body bold">Mois</div>
            <select
              name="month"
              id="month"
              value={formik.values.month}
              onChange={formik.handleChange}
              className="default-input"
              required
            >
              {months.map((m, i) => {
                return (
                  <option key={i} value={i + 1}>
                    {m}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <div className="wow-body bold">Année</div>
            <input
              name="year"
              id="year"
              value={formik.values.year}
              onChange={formik.handleChange}
              type="text"
              className="default-input"
              required
            />
          </div>
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="wow-body bold">Saisir le montant (T.T.C)</div>
          <div className={style["amount-input"]}>
            <input
              name="amount"
              id="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              type="number"
              className={`default-input ${style["montant-input"]}`}
              required
            ></input>
          </div>
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="wow-body bold">Type de document</div>
          <div className={style["amount-input"]}>
            <select
              name="type"
              id="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              className={`default-input ${style["montant-input"]}`}
              required
            >
              <option value="INVOICE">Facture</option>
              <option value="CLAIM">Note de frais</option>
            </select>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="wow-body bold">Transmettre votre facture <Helper link="mailto:wheelofwork@suppliers.pennylane.com" content="Un seul document est accepté par mois et par mission. Vos factures de notes de frais, sont à nous transmettre à l’adresse : wheelofwork@suppliers.pennylane.com"></Helper>
          </div>
          <input
            type="file"
            ref={factureRef}
            onChange={uploadFacture}
            hidden
            accept="application/pdf, .xlsx, .xls, .csv"
          />
          <div
            className={`${style["facture-container"]} w-100 d-flex flex-column flex-md-row gap-2 align-items-center justify-content-between`}
          >
            <div className="small-text">
              {facture ? facture.name : "Aucun fichier choisi"}
            </div>
            <div>
              <button
                type="button"
                className="default-btn secondary-btn"
                onClick={(e) => factureRef.current.click()}
              >
                Transmettre la facture
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-row justify-content-center">
          <div>
            <button
              type="submit"
              className={`default-btn`}
            //  ${
            //   status !== "REFUSED" &&
            //   status !== "PENDING" &&
            //   style["submit-btn-disabled"]
            // }`}
            >
              Confirmer
            </button>


          </div>
        </div>
      </form>
    </Popup>
  );
};

export default AddInvoice;
