import { useFormik } from "formik";
import React, { useState } from "react";
import { FiCheck, FiEye } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { SIGN_UP_ACTIONS } from "../../../Redux/SignUp/actions";
import toast from "./../../../Helpers/toast";
import { checkIfExists } from "./../../../Services/Auth";
import style from "./style.module.css";

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email("Invalid email"),
  password: Yup.string().matches(
    "(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]).*$",
    "Doit contenir à la fois : 8 caractères minimum, un caractère en majuscule, un caractère en minuscule, un chiffre et un caractère spécial."
  ),
});

// const handleGoogleConnection = () => {
//   window.open(`${process.env.REACT_APP_API_BASE_URL}/auth/google`, "_parent");
// };

const SignUp = ({ history }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const signUpStepper = useSelector((state) => state.signUpReducer);

  const formik = useFormik({
    validationSchema: SignUpSchema,
    initialValues: {
      firstName: signUpStepper.firstName,
      lastName: signUpStepper.lastName,
      email: signUpStepper.email,
      password: signUpStepper.password,
      accept: false,
    },
    onSubmit: (values) => {
      var state = { ...values };
      if (values.accept) {
        checkIfExists(state)
          .then((res) => {
            if (res.data) {
              toast(
                "error",
                "Un compte existe déjà avec ces identifiants, avez-vous oublié votre mot de passe ?"
              );
            } else {
              delete state.accept;
              dispatch({ type: SIGN_UP_ACTIONS.INITIAL, payload: values });
              history.push("/auth/sign-up/step-1");
            }
          })
          .catch((e) => {
            console.log("exists e: ", e);
          });
      } else toast("error", "Veuillez accepter les conditions générales");
    },
  });

  return (
    <form className={style["sign-up"]} onSubmit={formik.handleSubmit}>
      <h1 className={`header-4 ${style["white-color"]}`}>
        Devenir membre de la communauté WheelOfWork
      </h1>

      <section className={style["first-block"]}>
        <input
          placeholder="NOM"
          id="lastName"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          required={true}
        />
        <input
          placeholder="Prenom"
          id="firstName"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          required={true}
        />
      </section>

      <section>
        <input
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          type="email"
          required={true}
          placeholder="Email"
          autoComplete="off"
        />
      </section>

      <section className="position-relative">
        <input
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          type={show ? "text" : "password"}
          required={true}
          placeholder="Mot de passe"
          autoComplete="off"
        />
        <FiEye className={style["show-pwd"]} onClick={(e) => setShow(!show)} />
        {formik.errors?.password && (
          <p className="small-text red-text" style={{ height: "40px" }}>
            {formik.errors.password}
          </p>
        )}
      </section>

      <div className="w-100 mx-2 d-flex flex-row align-items-center">
        <input
          id="accept"
          name="accept"
          value={formik.values.accept}
          onChange={formik.handleChange}
          type="checkbox"
          hidden={true}
        />
        <label
          htmlFor="accept"
          style={{ border: "1px solid white" }}
          className="custom-checkbox"
        >
          <FiCheck />
        </label>
        <label
          htmlFor="accept"
          className={`small-text ${style["white-color"]}`}
        >
          J'accepte les&nbsp;
          <a
            href="https://www.wheelofwork.com/conditions-generales-utilisation/"
            target="_blank"
            rel="noreferrer"
            className={`${style["white-color"]} ${style["connect"]}`}
          >
            conditions générales
          </a>
          &nbsp;et&nbsp;
          <a
            href="https://www.wheelofwork.com/donnees-personnelles/"
            target="_blank"
            rel="noreferrer"
            className={`${style["white-color"]} ${style["connect"]}`}
          >
            la politique de confidentialité
          </a>
        </label>
      </div>
      <button
        type="submit"
        style={{ backgroundColor: "white", color: "black" }}
        className="default-btn"
      >
        Créer un compte
      </button>
      {/* <div className={`${style["white-color"]} wow-body mx-auto`}>Ou</div> */}
      {/*
        DISABLE THE GOOGLE ACCOUNT CONNECTION
      {<div className="mx-auto">
        <img
          className="mx-2 cursor-pointer"
          src="/google.png"
          alt="google logo of WheelOfWork"
          onClick={handleGoogleConnection}
        />
      </div>} */}
      <div className={`${style["white-color"]} small-text`}>
        J'ai déjà un compte.
        <Link
          to="/auth/sign-in"
          className={`${style["white-color"]} mx-1 ${style["connect"]}`}
        >
          Se connecter
        </Link>
      </div>
    </form>
  );
};

export default withRouter(SignUp);
