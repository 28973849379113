import React from "react";
import style from "./style.module.css";

const Notification = ({ notification }) => {
  return (
    <div
      className={
        style["notification"] + " w-100 d-flex flex-row align-items-start gap-3"
      }
      status={notification.status}
    >
      <div className="w-100 d-flex flex-column gap-1">
        <div className="small-text">{notification.message}</div>
        <div className="small-text black-text">
          {new Date(notification.createdAt).toLocaleDateString()}
        </div>
      </div>
    </div>
  );
};

export default Notification;
