import { ProtectedApi } from "../Helpers/api";

export const GetTimesheet = async (id, payload) => {
  return ProtectedApi.get(`/timesheet/mission/${id}`, {
    params: { ...payload },
  });
};

export const CreateTimesheet = async (payload) => {
  return ProtectedApi.post(`/timesheet/create`, { ...payload });
};
export const UpdateTimesheet = async (payload) => {
  return ProtectedApi.post(`/timesheet/update`, { ...payload });
};
