import React, { useState } from 'react'
import { FiMenu, FiSettings, FiXCircle } from 'react-icons/fi';
import { withRouter } from 'react-router';
import styles from './style.module.css';

const getLocation = (pathname) => {
    if (pathname && pathname.startsWith("/timesheet/")) {
        return "/timesheet";
    }
    else {
        return pathname;
    }
}

const NavigationMobile = ({ history, location }) => {

    const isItemActive = (str) => str === getLocation(location.pathname);

    const menuOptions = [
        { path: '/profile/informations-generales', name: 'Informations Générales', isActive: isItemActive('/profile/informations-generales') },
        { path: '/profile/profil-professionnel', name: 'Profil professionnel', isActive: isItemActive('/profile/profil-professionnel') },
        { path: '/profile/documents-legaux', name: 'Documents légaux', isActive: isItemActive('/profile/documents-legaux') },
        { path: '/timesheet', name: 'Comptes rendus d\'activité', isActive: isItemActive('/timesheet') },
        { path: '/profile/factures', name: 'Factures', isActive: isItemActive('/profile/factures') },
        { path: '/profile/parametres', name: 'Paramètres avancés', isActive: isItemActive('/profile/parametres') }
    ];

    const [isMobile, setIsMobile] = useState(false);
    const displayMenuProfil = () => {
        setIsMobile((isMobil) => !isMobil);
    }

    const translatePathToMenuItem = (str) => {
        let res = '';
        switch (str) {
            case '/profile/informations-generales':
                res = 'Informations Générales';
                break;
            case '/profile/profil-professionnel':
                res = 'Profil professionnel';
                break;
            case '/profile/documents-legaux':
                res = 'Documents légaux';
                break;
            case '/timesheet':
                res = 'Comptes rendus d\'activité';
                break;
            case '/profile/factures':
                res = 'Factures';
                break;
            case '/profile/parametres':
                res = 'Paramètres avancés';
                break;
            default:
                res = 'Informations Générales';
                break;
        }
        return res;
    }

    const menuBackDropMobile = <div className={styles["mobileView"]}>
        <div className={styles['mobileViewContainer']}>
            <h1><FiSettings className='mx-1' /> Profil et activité</h1>
            <ul className={styles['ul']}>
                {menuOptions.map(opt =>
                    <li
                        className={`wow-subtitle ${opt.isActive ? `${styles['li-active']} ${styles['li']}` : `${styles['li']}`}`}
                        key={opt.path}
                        onClick={(e) => history.push(opt.path)}>
                        {opt.name}
                    </li>)
                }
            </ul>
        </div>
        <div onClick={displayMenuProfil} className={styles['close-mobile-menu']}>
            <FiXCircle />
        </div>
    </div >

    const focusMobileMenuButton =
        <div onClick={displayMenuProfil} className={styles["mobile-menu-btn"]}>
            <FiMenu className="mx-2" />
        </div>;

    return <div className={styles['container']}>
        {isMobile && menuBackDropMobile}
        {focusMobileMenuButton}
        <p className='text-center wow-subtitle'>{translatePathToMenuItem(getLocation(location.pathname))}</p>
    </div>
}

export default withRouter(NavigationMobile)