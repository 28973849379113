import { SIGN_UP_ACTIONS } from "./actions";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phoneNumber: "",
  linkedInUri: "",
  job: {
    id: "",
    label: "",
    level: "",
  },
  languages: [],
  skills: [],
  addresses: [],
  preference: "",
  role: "CONSULTANT",
};

const signUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_ACTIONS.INITIAL:
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      };
    case SIGN_UP_ACTIONS.STEP_1:
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
        linkedInUri: action.payload.linkedInUri,
      };
    case SIGN_UP_ACTIONS.STEP_2:
      return {
        ...state,
        job: {
          id: action.payload.id,
          label: action.payload.label,
          level: action.payload.level,
        },
      };
    case SIGN_UP_ACTIONS.STEP_3:
      return {
        ...state,
        languages: action.payload.languages,
        skills: action.payload.skills,
      };
    case SIGN_UP_ACTIONS.STEP_4:
      return {
        ...state,
        addresses: action.payload.addresses,
        preference: action.payload.preference,
      };
    case SIGN_UP_ACTIONS.STEP_5:
      return {
        ...state,
        averageSale: action.payload.averageSale,
        availibility: action.payload.availability,
      };
    default:
      return {
        ...state,
      };
  }
};

export default signUpReducer;
