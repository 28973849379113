import React from "react";
import { FiHelpCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import style from "./style.module.css";
import uuid from 'react-uuid';
class Helper extends React.Component {
  constructor(props) {
    super(props);
    this.uuid =  uuid();
  }
  render () {
  if (!this.props.link){
    return <span className={`${style["container"]}`}>
    <FiHelpCircle className={`${style["helperIcon"]}`}  data-tip  data-for={this.uuid}/>
    {this.props.content?
      <ReactTooltip className={`${style["toolTip"]}`} id={this.uuid} >
        <span>{this.props.content}</span>
      </ReactTooltip>
    :null}
    </span> 
  }
  else{
    if (this.props.link.startsWith("http") ||this.props.link.startsWith("mailto")){
      return <a className={`${style["container"]}`} href={this.props.link}>
      <FiHelpCircle className={`${style["helperIcon"]}`}  data-tip  data-for={this.uuid}/>
        {this.props.content?
          <ReactTooltip className={`${style["toolTipMail"]}`} id={this.uuid}>
            <span>{this.props.content}</span>
          </ReactTooltip>
        :null}
      </a>
    }else{
      return <Link to={this.props.link} className={`${style["container"]}`}>
      <FiHelpCircle className={`${style["helperIcon"]}`}  data-tip  data-for={this.uuid}/>
        {this.props.content?
          <ReactTooltip className={`${style["toolTip"]}`} id={this.uuid}>
            <span>{this.props.content}</span>
          </ReactTooltip>
        :null}
      </Link>
    }
  }
}
};

export default Helper;
