import { ProtectedApi } from "../Helpers/api";

export const AddInvoice = async (payload) => {
  return ProtectedApi.post("/invoice", { ...payload });
};

export const GetInvoices = async (payload = {}) => {
  const { missionId, dateOptions } = payload;

  let url = `/invoice`;

  const queryParams = [];

  if (missionId) {
    queryParams.push(`missionId=${missionId}`);
  }
  if (dateOptions?.year) {
    queryParams.push(`year=${dateOptions.year}`);
  }
  if (dateOptions?.month) {
    queryParams.push(`month=${dateOptions.month}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  return ProtectedApi.get(url);
};
