import { ProtectedApi } from "../Helpers/api";

export const GetContracts = (payload) => {
  return ProtectedApi.get(
    `/contract?consultantId=${payload.consultantId}&missionId=${payload.missionId}`
  );
};

export const GetActiveContracts = (payload) => {
  const { consultantId, missionId, dateOptions } = payload;

  let url = `/contract/active?consultantId=${consultantId}`;

  if (missionId) {
    url += `&missionId=${missionId}`;
  }
  if (dateOptions?.year) {
    url += `&year=${dateOptions.year}`;
  }
  if (dateOptions?.month) {
    url += `&month=${dateOptions.month}`;
  }
  if (dateOptions?.filterByMonthYear) {
    url += `&filterByMonthYear=${dateOptions.filterByMonthYear}`;
  }

  return ProtectedApi.get(url);
};
