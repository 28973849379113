import React, { useState } from "react";
import Popup from "../../Popup";
import { v4 as uuidv4 } from "uuid";

export const TimesheetDayPopup = ({
  show,
  selectedDay,
  onValidate,
  onClose,
}) => {
  const [selectedDuration, setSelectedDuration] = useState(0);
  const handleDurationChange = (e) => {
    const value = e.target.value;
    setSelectedDuration(value);
  };

  const handleValidate = () => {
    const event = {
      id: uuidv4(),
      date: selectedDay.dateStr,
      unparsedDate: selectedDay.date,
      percentage: Number(selectedDuration),
      display: "background",
    };
    onValidate(event);
  };
  return (
    <Popup
      show={show}
      onClose={(e) => {
        onClose(e);
      }}
    >
      <form className="row px-2 px-md-5 py-5 gap-4">
        <div className="d-flex flex-row justify-content-around">
          <div className="d-flex flex-column align-items-center">
            <div className="wow-body bold">Date sélectionnée</div>
            <input
              className="default-input mt-4"
              value={selectedDay.date.toLocaleDateString()}
            />
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="wow-body bold">Durée (en journée)</div>
            <div className="d-flex flex-row form-group mt-4">
              <div className="d-flex flex-row align-items-center">
                <input
                  type="radio"
                  checked={selectedDuration === "0"}
                  onChange={handleDurationChange}
                  value="0"
                  id="not-day-worked"
                  name="not-day-worked"
                />
                <label htmlFor="not-day-worked" className="mx-2">
                  0
                </label>
              </div>
              <div className="d-flex flex-row align-items-center mx-4">
                <input
                  type="radio"
                  checked={selectedDuration === "0.5"}
                  onChange={handleDurationChange}
                  value="0.5"
                  id="semi-worked-day"
                  name="semi-worked-day"
                />
                <label htmlFor="semi-worked-day" className="mx-2">
                  0.5
                </label>
              </div>
              <div className="d-flex flex-row align-items-center">
                <input
                  type="radio"
                  checked={selectedDuration === "1"}
                  onChange={handleDurationChange}
                  value="1"
                  id="full-day-worked"
                  name="full-day-worked"
                />
                <label htmlFor="full-day-worked" className="mx-2">
                  1
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-row justify-content-center mt-4 gap-4">
          <div className="w-auto">
            <button
              type="reset"
              className="default-btn danger-btn"
              onClick={onClose}
            >
              Annuler
            </button>
          </div>
          <div className="w-auto">
            <button
              type="button"
              className="default-btn"
              onClick={handleValidate}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Popup>
  );
};
