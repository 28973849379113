import { useFormik } from "formik";
import React from "react";
import Popup from "../Popup";

const AddReference = ({ show, onClose, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      email: "",
      phoneNumber: "",
      description: "",
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  return (
    <Popup
      show={show}
      onClose={(e) => {
        onClose(e);
        formik.handleReset();
      }}
    >
      <form
        className="row px-2 px-md-5 py-4 gap-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="col-12 col-md-10 header-4">
          Ajouter une recommandation
        </div>
        <div className="col-12 d-flex flex-column flex-md-row gap-3">
          <div className="w-100 d-flex flex-column">
            <label htmlFor="lastName" className="small-text bold mb-2">
              Nom
            </label>
            <input
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              placeholder=""
              required
            />
          </div>
          <div className="w-100 d-flex flex-column">
            <label htmlFor="firstName" className="small-text bold mb-2">
              Prénom
            </label>
            <input
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-column">
          <label htmlFor="email" className="small-text bold mb-2">
            Email
          </label>
          <input
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            placeholder=""
            required={!formik.values.phoneNumber}
          />
        </div>
        <div className="col-12 d-flex flex-column">
          <label htmlFor="phoneNumber" className="small-text bold mb-2">
            Numéro de téléphone
          </label>
          <input
            id="phoneNumber"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            placeholder=""
            required={!formik.values.email}
          />
        </div>
        <div className="col-12 d-flex flex-column">
          <label htmlFor="description" className="small-text bold mb-2">
            Des informations sur la personne qui vous recommande
          </label>
          <textarea
            id="description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            className="default-input"
            placeholder="Dans quel contexte vous avez travaillé avec cette personne, lien hiérarchique ..."
            required
          />
        </div>
        <div className="col-12 d-flex flex-row justify-content-center justify-content-md-end gap-4">
          <div className="w-auto">
            <button
              type="reset"
              className="default-btn danger-btn"
              onClick={onClose}
            >
              Annuler
            </button>
          </div>
          <div className="w-auto">
            <button type="submit" className="default-btn">
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Popup>
  );
};

export default AddReference;
