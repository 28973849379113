import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { GetContracts } from "./../../Services/Contract";
import { GetConsultantMissions } from "./../../Services/Missions";
import { contractsStatuses, contractsTypes } from "./../../Helpers/data";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { b64ToObjectUrl } from "./../../Helpers/base64-decode";
import { getDocumentByIdAndType } from "../../Services/User";

const Contrats = () => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [selectedMission, setSelectedMission] = useState({});
  const [missions, setMissions] = useState([]);
  const [contracts, setContracts] = useState([]);

  const onMissionChange = (e) => {
    const missionId = e.target.value;
    setLoading(true);
    setSelectedMission(missionId);
    GetContracts({ consultantId: currentConsultant.id, missionId }).then(
      (res) => {
        setContracts(res.data);
        setLoading(false);
      }
    );
  };

  const getContractFileHandler = (contractFile) => {
    if (!contractFile?._id) {
      return;
    }
    getDocumentByIdAndType(contractFile._id, "contract").then((res) => {
      const file = res.data;
      if (!file) {
        return;
      }
      const url = b64ToObjectUrl(file.base64Document, file.filetype);
      window.open(url, "blank");
    });
  };

  useEffect(() => {
    if (currentConsultant) {
      GetConsultantMissions(currentConsultant.id).then((res) => {
        setMissions(res.data);
      });
      if (location.state && location.state.id) {
        setSelectedMission(
          missions.find((mission) => mission.id === location.state.id)
        );
        setLoading(true);
        GetContracts({
          consultantId: currentConsultant.id,
          missionId: location.state.id,
        }).then((res) => {
          setContracts(res.data);
          setLoading(false);
        });
      }
    }
  }, [location, currentConsultant]);

  return (
    <div className="container">
      <div className="row card p-4 p-md-5 gap-4">
        <div className="col-12 d-flex flex-column gap-3">
          <div className="small-text bold">Choisir une mission</div>
          <select
            className="default-input"
            onChange={onMissionChange}
            value={selectedMission}
          >
            <option value="default">Choisir une mission</option>
            {missions.map((m) => {
              return (
                <option key={m.id} value={m.id}>
                  {m.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className={`col-12 ${style["contracts-table"]}`}>
          {loading ? (
            <div
              className="w-100 position-relative"
              style={{ height: "300px" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <div
                className={`${style["contracts-table-item"]} ${style["contracts-table-header"]}`}
              >
                <div className="small-text">Type</div>
                <div className="small-text d-none d-md-flex">Date début</div>
                <div className="small-text d-none d-md-flex">Date Fin</div>
                <div className="small-text">Fichier</div>
                <div className="small-text d-none d-md-flex">Statut</div>
              </div>
              {contracts.length > 0 ? (
                contracts.map((contract, i) => {
                  return (
                    <div key={i} className={`${style["contracts-table-item"]}`}>
                      <div className="small-text bold">
                        {contractsTypes[contract.type]}
                      </div>
                      <div className="small-text d-none d-md-flex">
                        {contract?.startDate &&
                          new Date(contract.startDate).toLocaleDateString()}
                      </div>
                      <div className="small-text d-none d-md-flex">
                        {contract &&
                          contract.expirationDate &&
                          contract.expirationDate.substring(0, 10)}
                      </div>
                      <div
                        className="small-text blue-text d-md-flex cursor-pointer"
                        rel="noreferrer"
                        href="/"
                        onClick={() => getContractFileHandler(contract?.file)}
                      >
                        Ouvrir
                      </div>
                      {(() => {
                        let status = "MISSING";
                        if (contract) {
                          if (contract.startDate && contract.expirationDate) {
                            const currentDate = new Date().setHours(0, 0, 0, 0);
                            const startDate = new Date(contract.startDate).setHours(0, 0, 0, 0);
                            const expirationDate = new Date(contract.expirationDate).setHours(0, 0, 0, 0);
                            
                            if (currentDate < startDate) {
                              status = "PENDING";
                            } else if (currentDate >= startDate && currentDate <= expirationDate) {
                              status = "ONGOING";
                            } else {
                              status = "EXPIRED";
                            }
                          } else {
                            status = contract.status;
                          }
                        }
                        return (
                          <div
                            className={`${style["contract-status"]} small-text d-none d-md-flex`}
                            status={status}
                          >
                            {contractsStatuses[status] || "Manquant"}
                          </div>
                        );
                      })()}
                    </div>
                  );
                })
              ) : (
                <div className="w-100 p-5 small-text text-center">
                  Aucun contrat
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contrats;
