import React, { useState } from "react";
import { useEffect } from "react";
import { GetMissionFavorites } from "../../Services/Missions";
import Mission from "./../../Components/Mission/index";
import Loader from "./../../Components/Loader/index";
import { useSelector } from "react-redux";

const MissionsFavoris = () => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [loading, setLoading] = useState(false);

  const [missions, setMissions] = useState([]);
  const handleChangeLike = () => {
    GetMissionFavorites(currentConsultant.id)
      .then((res) => {
        setMissions(res.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    if (currentConsultant) {
      setLoading(true);
      GetMissionFavorites(currentConsultant.id)
        .then((res) => {
          setMissions(res.data);
          setLoading(false);
        })
        .catch((e) => setLoading(false));
    }
  }, [currentConsultant]);
  return (
    <div className="container-lg">
      <div className="row justify-content-center align-items-center mx-1">
        <div className="col-12 col-md-11 header-4 mb-4">
          Mes missions favorites
        </div>
        <div className="col-12 d-flex flex-column gap-5 my-3">
          {loading ? (
            <div
              className="w-100 card position-relative"
              style={{ height: "300px" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {missions.length === 0 ? (
                <div className="w-100 card p-5 wow-body text-center">
                  Aucune mission
                </div>
              ) : (
                <>
                  {missions.map((c, i) => {
                    return (
                      <Mission
                        key={i}
                        mission={c.mission}
                        favoriteId={c.id}
                        onChangeLike={handleChangeLike}
                        isFavorite
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MissionsFavoris;
