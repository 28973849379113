import React, { useCallback, useEffect } from "react";
import style from "./style.module.css";
import Notification from "./../Notification/index";
import { FiArrowRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  GetConsultantNotifications,
  MarkConsultantNotificationsAsRead,
} from "../../../Services/Notifications";
import { userActions } from "../../../Redux/User/actions";

const Notifications = ({ open = false, onClose }) => {
  const dispatch = useDispatch();
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const notifications = useSelector((state) => state.userReducer.notifications);

  const updateConsultantNotifications = useCallback(
    (notifications) => {
      dispatch({
        type: userActions.SET_CURRENT_CONSULTANT_NOTIFICATIONS,
        payload: notifications,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else document.body.style.overflowY = "scroll";
  }, [open]);

  useEffect(() => {
    if (currentConsultant) {
      GetConsultantNotifications(currentConsultant.id).then((res) =>
        updateConsultantNotifications(res.data)
      );
    }
  }, [currentConsultant]);

  const handleMarkAsRead = () => {
    MarkConsultantNotificationsAsRead(currentConsultant.id).then((res) =>
      updateConsultantNotifications(res.data)
    );
  };
  return (
    <div
      className={`${style["notifications-container"]} ${
        open ? style["open"] : ""
      }`}
    >
      <div
        className={style["notifications-container-bg"]}
        onClick={(e) => onClose()}
      ></div>
      <div
        className={`${style["notifications"]} ${
          open ? style["open"] : ""
        } no-scrollbar`}
      >
        <div className="w-100 d-flex flex-row justify-content-between align-items-center px-4">
          <FiArrowRight
            className="header-3 cursor-pointer"
            onClick={(e) => onClose()}
          />
          <div
            className="small-text bold cursor-pointer"
            onClick={handleMarkAsRead}
          >
            Tout marquer comme lu
          </div>
        </div>
        {notifications.map((n, i) => {
          return <Notification key={i} notification={n} />;
        })}
      </div>
    </div>
  );
};

export default Notifications;
