import React from "react";
import style from "./style.module.css";
import { FiSearch } from "react-icons/fi";
import { useFormik } from "formik";
import { withRouter } from "react-router";
import HelperButton from "../HelperButton";

const SearchBar = ({ history, search = false }) => {
  const formik = useFormik({
    initialValues: {
      search: "",
      location: "",
    },
    onSubmit: (values) => {
      history.push(
        `/missions?search=${values.search}&address=${values.location}`
      );
    },
  });
  return (
    <form
      className={
        style["search-bar"] + " row d-flex flex-row justify-content-center"
      }
      onSubmit={formik.handleSubmit}
    >
      <div
        className={`col-12 col-md-9 px-3 px-md-5 py-4 py-md-5 ${search ? "d-none d-md-flex" : "d-flex"
          } flex-column flex-md-row gap-3 gap-md-0 justify-content-center'`}
      >
        <input
          name="search"
          value={formik.values.search}
          onChange={formik.handleChange}
          className={style["search-bar-input"] + "  default-input"}
          placeholder="Titre de la mission"
          autoComplete="off"
        />
        <input
          name="location"
          value={formik.values.location}
          onChange={formik.handleChange}
          className={style["search-bar-input"] + "  default-input"}
          placeholder="Ville"
          autoComplete="off"
        />
        <button
          type="submit"
          className={`${style["search-bar-btn"]} default-btn ${style['ml-3']}`}
        >
          Chercher
          <FiSearch className="mx-1" />
        </button>
        <span className={`${style["helperButton"]}`}><HelperButton className={style["helperButton"]} link="https://wheelofwork.notion.site/Nouveau-sur-l-App-Trouvez-votre-premi-re-mission-f6f12fb9cfe645de9e883396f862221a" ></HelperButton></span>

      </div>
    </form>
  );
};

export default withRouter(SearchBar);
