import React from 'react'


const Parrainage = () => {
    return (
      <div className="container">
        <div className="row flex-column flex-md-row align-items-center justify-content-center justify-content-md-between gap-5">
          <div className="col-10 col-md-6 d-flex flex-column gap-3">
            <div className="w-100 d-flex flex-column gap-2">
              <div className="header-3">
                Découvrez le programme WoW Sponsorship !
              </div>
              <div className="wow-body d-flex flex-column gap-3">
                Participez à l’animation de votre réseau et repartez avec une prime de parrainage.
                <br />
                <br />
                <b>Comment ça fonctionne ?</b>
                <ol className="d-flex flex-column gap-4">
                  <li>Apportez un profil, une mission, ou même les deux sur WheelofWorkSponsorship </li>
                  <li>
                  Détendez-vous, vous n’avez rien à faire. Nos agents de talent s'occupent de tout !
                  </li>
                  <li>
                  Chaque recommandation aboutie vous fait gagner de l'argent et ainsi développe votre activité.
                  </li>
                </ol>
                <b>La récompense ?</b>
                <ul className="d-flex flex-column gap-4">
                  <li>Apportez un profil et percevez 4% du TJM achat</li>
                  <li>Apportez une mission et percevez 4% du TJM achat</li>
                  <li>Apportez les deux et percevez 6% du TJM achat</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-11 col-md-5">
            <img src="/parrainage-page.svg" alt="404 page" className="w-100" />
          </div>
        </div>
      </div>
    );
}

export default Parrainage