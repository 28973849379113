import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import ToggleSwitch from "../../../Components/ToggleSwitch";
import Popup from "./../../../Components/Popup/index";
import { deleteUser, editUserPreferences } from "./../../../Services/User";
import { useDispatch, useSelector } from "react-redux";
import toast from "./../../../Helpers/toast";
import Loader from "../../../Components/Loader";

const Parametres = () => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [settings, setSettings] = useState(null);

  const dispatch = useDispatch();

  const logout = useCallback(
    () => dispatch({ type: "user/LOGOUT" }),
    [dispatch]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      legalDocumentsEmail: true,
      timesheetEmail: true,
      invoiceEmail: true,
      ...settings,
    },
    onSubmit: (values) => {
      editUserPreferences(currentConsultant.id, {
        preferences: { ...values },
      }).then((res) => toast("success", "Sauvegardé"));
    },
  });

  useEffect(() => {
    setLoading(true);
    if (currentConsultant) {
      setSettings(currentConsultant.preferences);
      setLoading(false);
    }
  }, [currentConsultant]);
  return (
    <div className="card col-12 col-md p-4 p-md-5 d-flex flex-column gap-5 position-relative">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="w-100 d-flex flex-column gap-2">
            <div className="wow-subtitle bold">Gérer mes notifications</div>
            <div className="small-text mb-4">
              Souhaitez-vous recevoir les notifications de WheelOfWork par mail
              ?
            </div>
            <form
              className="w-100 w-md-50 d-flex flex-column gap-5"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                <div className="wow-body bold">Documents à transmettre</div>
                <ToggleSwitch
                  value={formik.values.legalDocumentsEmail}
                  onChange={formik.handleChange}
                  name="legalDocumentsEmail"
                />
              </div>
              <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                <div className="wow-body bold">
                  Compte rendu d'activité à envoyer
                </div>
                <ToggleSwitch
                  value={formik.values.timesheetEmail}
                  onChange={formik.handleChange}
                  name="timesheetEmail"
                />
              </div>
              <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                <div className="wow-body bold">Facture à transmettre</div>
                <ToggleSwitch
                  value={formik.values.invoiceEmail}
                  onChange={formik.handleChange}
                  name="invoiceEmail"
                />
              </div>
              <div className="mx-auto">
                <button type="submit" className="default-btn">
                  Sauvegarder
                </button>
              </div>
            </form>
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <div className="wow-subtitle bold">Supprimer mon compte</div>
            <div className="small-text">
              Nous regrettons de vous voir partir, toutefois, si vous souhaitez
              quitter la communauté WheelOfWork, vous pouvez supprimer votre
              compte en cliquant sur le bouton ci-dessous.
            </div>
            <div className="my-4 mx-auto">
              <button
                type="button"
                className="default-btn danger-btn-2"
                onClick={(e) => setOpen(true)}
              >
                Supprimer mon compte
              </button>
            </div>
          </div>
          {open && (
            <Popup size="sm" show={open} onClose={(e) => setOpen(false)}>
              <div className="row p-4 gap-4 justify-content-center">
                <div className="col-12 small-text">
                  Etes vous sûr de vouloir supprimer votre compte ?
                </div>
                <div className="col-5">
                  <button
                    className="default-btn"
                    onClick={(e) => {
                      deleteUser(currentConsultant).then((res) => {
                        logout();
                      });
                    }}
                  >
                    Confirmer
                  </button>
                </div>
                <div className="col-5">
                  <button
                    className="default-btn danger-btn"
                    onClick={(e) => setOpen(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </Popup>
          )}
        </>
      )}
    </div>
  );
};

export default Parametres;
