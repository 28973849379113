import React from "react";
import { FiHelpCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import style from "./style.module.css";
import uuid from 'react-uuid';
class HelperButton extends React.Component {
  constructor(props) {
    super(props);
    this.uuid =  uuid();
  }
  render () {
  if (!this.props.link){
    return <span >
    <FiHelpCircle className={`${style["helperIcon"]}`}  data-tip  data-for={this.uuid}/>
    {this.props.content?
      <ReactTooltip className={`${style["toolTip"]}`} id={this.uuid} >
        <span>{this.props.content}</span>
      </ReactTooltip>
    :null}
    </span> 
  }
  else{
    if (this.props.link.startsWith("http")){
      return <a target="_blank" rel="noopener noreferrer" href={this.props.link}>
      <button className={`${style["helperButton"]} default-btn primary-btn ${this.props.small ? style["helperButtonSmall"] : ``}`} data-tip data-for={this.uuid}>Aide</button>
      {this.props.content ?
        <ReactTooltip className={`${style["toolTip"]}`} id={this.uuid}>
          <span></span>
        </ReactTooltip>
        : null}
    </a>
    }else{
      return <Link to={this.props.link} >
        <button className={`${style["helperButton"]} default-btn primary-btn ${this.props.small?style["helperButtonSmall"]:``}`}  data-tip  data-for={this.uuid}>Aide</button>
        {this.props.content?
          <ReactTooltip className={`${style["toolTip"]}`} id={this.uuid}>
            <span>{this.props.content}</span>
          </ReactTooltip>
        :null}
      </Link>
    }
  }
}
};

export default HelperButton;
