import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import style from "./style.module.css";
import {
  FiBell,
  FiChevronDown,
  FiSettings,
  FiHeart,
  FiCircle,
  FiHelpCircle,
  FiLogOut,
  FiMenu,
  FiXCircle,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import Notifications from "./../Notifications/index";
import Indisponibilité from "./Indisponibilité";
import {
  getConsultant,
  updateConsultantAvailability,
} from "./../../../Services/User";
import { userActions } from "../../../Redux/User/actions";
import { useHistory } from "react-router-dom";
import { ProtectedApi } from "../../../Helpers/api";
import {
  displayConsultantPictureWithConnectionMethod,
} from "../../../utils";

const Navbar = () => {
  const history = useHistory();

  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const notifications = useSelector((state) => state.userReducer.notifications);

  const dispatch = useDispatch();
  const consultantId = useSelector((state) => state.userReducer.consultantId);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const [openDispo, setOpenDispo] = useState(false);
  const [openIndispo, setOpenIndispo] = useState(false);

  const handleNotification = (open) => {
    setOpenNotif(open);
  };

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch({
      type: userActions.LOGOUT_USER,
    });
    ProtectedApi.post("/auth/logout").then(() => {
      history.push("/auth/sign-in");
    });
  };
  const setDispo = useCallback(
    (availability) => {
      dispatch({
        type: userActions.SET_CURRENT_CONSULTANT_AVAILIBITY,
        payload: availability,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!currentConsultant) {
      getConsultant(consultantId).then((res) => {
        dispatch({
          type: userActions.SET_CURRENT_CONSULTANT,
          payload: { consultant: res.data },
        });
      });
    }
  }, [currentConsultant, dispatch, consultantId]);
  return (
    <>
      <div className={`container-fluid ${style["navbar"]}`}>
        <div className="container-xl">
          <div className="row align-items-center justify-content-between">
            <Link to="/" className="wow-logo col-auto d-none d-xl-block">
              <img
                className="wow-logo col-auto d-none d-xl-block"
                src="/wow-ny-logo/wow-inline.svg"
                alt="WheelOfWork logo"
              />
            </Link>
            <div
              className="col-auto d-flex d-xl-none align-items-center cursor-pointer"
              onClick={(e) => setShowMenu(true)}
            >
              <FiMenu className="mx-2" />
              <div className="wow-body bold">Menu</div>
            </div>
            <div className="col d-none d-xl-flex flex-row justify-content-around">
              <NavLink
                exact
                to="/"
                className={`wow-body ${style["nav-link"]}`}
                activeClassName={`wow-body ${style["nav-link"]} ${style["active"]}`}
              >
                Accueil
              </NavLink>
              <NavLink
                exact
                to="/missions"
                className={`wow-body ${style["nav-link"]}`}
                activeClassName={`wow-body ${style["nav-link"]} ${style["active"]}`}
              >
                Trouver une mission
              </NavLink>
              <NavLink
                exact
                to="/candidatures"
                className={`wow-body ${style["nav-link"]}`}
                activeClassName={`wow-body ${style["nav-link"]} ${style["active"]}`}
              >
                Mes candidatures
              </NavLink>
              <NavLink
                exact
                to="/contrats"
                className={`wow-body ${style["nav-link"]}`}
                activeClassName={`wow-body ${style["nav-link"]} ${style["active"]}`}
              >
                Mes contrats
              </NavLink>
            </div>
            <div
              className={`col-auto d-flex flex-row align-items-center justify-content-around position-relative ${style["profile"]}`}
            >
              <div className={style["bell-container"]}>
                <FiBell
                  className="cursor-pointer"
                  onClick={(e) => handleNotification(true)}
                />
                {notifications.find((n) => n.status === "PENDING") && (
                  <div className={style["new-notif"]}></div>
                )}
              </div>
              <div
                className={
                  style["profile-picture-container"] + " cursor-pointer"
                }
                onClick={(e) => setShowDropDown(!showDropDown)}
              >
                {displayConsultantPictureWithConnectionMethod(
                  currentConsultant
                )}
              </div>
              {currentConsultant?.user.firstName && (
                <span
                  className="wow-body bold d-none d-lg-flex cursor-pointer text-truncate"
                  style={{ maxWidth: "140px" }}
                  onClick={(e) => setShowDropDown(!showDropDown)}
                >
                  {currentConsultant.user.firstName
                    .substring(0, 1)
                    .toUpperCase() +
                    currentConsultant.user.firstName.substring(1).toLowerCase()}
                </span>
              )}
              <FiChevronDown
                className="cursor-pointer"
                onClick={(e) => setShowDropDown(!showDropDown)}
              />
              {showDropDown || openDispo || openIndispo ? (
                <div
                  className={style["close-dropdown"]}
                  onClick={(e) => {
                    if (showDropDown) setShowDropDown(false);
                    if (openDispo) setOpenDispo(false);
                    if (openIndispo) setOpenIndispo(false);
                  }}
                ></div>
              ) : (
                <></>
              )}
              {/* Navbar dropdown */}
              <div
                className={`${showDropDown ? " d-flex" : "d-none"} ${
                  style["nav-dropdown"]
                }`}
              >
                {/* <div className='small-text black-text mx-3'>{user.email}</div> */}
                <Link
                  to="/profile"
                  className="d-flex flex-row align-items-center"
                  onClick={(e) => setShowDropDown(false)}
                >
                  <FiSettings className="mx-3" />
                  <div className="small-text">Profil & activité</div>
                </Link>
                <Link
                  to="/missions/favorites"
                  className="d-flex flex-row align-items-center"
                >
                  <FiHeart className="mx-3" />
                  <div className="small-text">Missions favorites </div>
                </Link>
                <div
                  className="d-flex flex-row align-items-center cursor-pointer"
                  onClick={(e) => {
                    setOpenDispo(true);
                    setShowDropDown(false);
                  }}
                >
                  <FiCircle
                    className={`mx-3 ${
                      currentConsultant?.availability === "AVAILABLE"
                        ? "disponible"
                        : "indisponible"
                    }`}
                  />
                  <div className="small-text">
                    {currentConsultant?.availability === "AVAILABLE"
                      ? "Disponible"
                      : "Indisponible"}
                  </div>
                </div>
                <div className="d-flex">
                  <FiHelpCircle className="mx-3" />
                  <div className="small-text">
                    <a
                      href="https://wheelofwork.notion.site/wheelofwork/Aide-support-et-documentation-sur-l-App-d81630768dda45d18ac7146f2e4ba8a4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Aide
                    </a>
                  </div>
                </div>
                <div className={style["dropdown-line"]}></div>
                <div
                  className="d-flex flex-row align-items-center cursor-pointer"
                  onClick={(e) => logout()}
                >
                  <FiLogOut className="mx-3" />
                  <div className="small-text">Déconnexion</div>
                </div>
              </div>

              {/* Disponibility dropdown */}
              <div
                className={`${openDispo ? "d-flex" : "d-none"} ${
                  style["disponibility-dropdown"]
                }`}
              >
                <div className="wow-subtitle bold d-flex flex-row align-items-center">
                  {"Tu es " +
                    (currentConsultant?.availability === "AVAILABLE"
                      ? "disponible"
                      : "indisponible")}
                  <FiCircle
                    className={`mx-3 ${
                      currentConsultant?.availability === "AVAILABLE"
                        ? "disponible"
                        : "indisponible"
                    }`}
                  />
                </div>
                <div className="small-text">
                  Disponible pour une nouvelle mission ?
                </div>
                <button
                  className={`default-btn ${
                    currentConsultant?.availability === "AVAILABLE"
                      ? "danger-btn"
                      : ""
                  }`}
                  onClick={(e) => {
                    if (currentConsultant?.availability !== "AVAILABLE") {
                      updateConsultantAvailability(consultantId, {
                        isAvailable: true,
                      }).then((res) => setDispo(res.data));
                    } else setOpenIndispo(true);
                    setOpenDispo(false);
                  }}
                >
                  {
                    "Je suis " +
                    (currentConsultant?.availability === "AVAILABLE"
                      ? "indisponible"
                      : "disponible")
                  }
                </button>
              </div>

              {/* Indisponible dropdown */}
              {openIndispo && (
                <div
                  className={`${openIndispo ? "d-flex" : "d-none"} ${
                    style["disponibility-dropdown"]
                  }`}
                >
                  <Indisponibilité
                    consultantId={consultantId}
                    setDispo={setDispo}
                    onClose={() => setOpenIndispo(false)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`${style["mobile-menu"]} ${
            showMenu ? style["open"] : ""
          } d-flex d-lg-none`}
        >
          <FiXCircle
            className={style["mobile-menu-close"]}
            onClick={(e) => {
              setShowMenu(false);
            }}
          />
          <img
            className="wow-logo my-5"
            src="/wow-ny-logo/wow-inline.svg"
            alt="WheelOfWork logo"
          />
          <NavLink
            exact
            to="/"
            className={`wow-subtitle ${style["nav-link"]}`}
            activeClassName={`${style["active"]}`}
            onClick={(e) => setShowMenu(false)}
          >
            Accueil
          </NavLink>
          <NavLink
            exact
            to="/missions"
            className={`wow-subtitle ${style["nav-link"]}`}
            activeClassName={`${style["active"]}`}
            onClick={(e) => setShowMenu(false)}
          >
            Trouver une mission
          </NavLink>
          <NavLink
            exact
            to="/candidatures"
            className={`wow-subtitle ${style["nav-link"]}`}
            activeClassName={`${style["active"]}`}
            onClick={(e) => setShowMenu(false)}
          >
            Mes candidatures
          </NavLink>
          <NavLink
            exact
            to="/contrats"
            className={`wow-subtitle ${style["nav-link"]}`}
            activeClassName={`${style["active"]}`}
            onClick={(e) => setShowMenu(false)}
          >
            Mes contrats
          </NavLink>
        </div>
      </div>

      <Notifications
        open={openNotif}
        onClose={() => handleNotification(false)}
      />
    </>
  );
};

export default Navbar;
