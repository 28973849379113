import React from 'react'
import { withRouter } from 'react-router'


const Page404 = ({history}) => {
    return <div className='container'>
        <div className='row flex-column-reverse flex-md-row align-items-center justify-content-center justify-content-md-between gap-5'>
            <div className='col-10 col-md-5 d-flex flex-column gap-4'>
                <div className='header-3'>
                    Erreur sur cette page mais la roue tourne, faites une nouvelle recherche !
                </div>
                <div>
                    <button className='default-btn' onClick={e=>history.goBack()}>Retourner sur la route</button>
                </div>
            </div>
            <div className='col-11 col-md-6'>
                <img src='/404.svg' alt='404 page' className='w-100' />
            </div>
        </div>
    </div>
}

export default withRouter(Page404)