import React, { useEffect, useRef, useState } from "react";
import { FiPlus, FiSearch, FiTrash, FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import {
  editProfilePro,
} from "../../../Services/User";
import AddReference from "./../../../Components/AddReference/index";
import Loader from "./../../../Components/Loader/index";
import toast from "./../../../Helpers/toast";
import {
  AddConsultantReference,
  DeleteReference,
  GetReferences,
} from "./../../../Services/Reference";
import { getSkills } from "./../../../Services/Skills";
import style from "./style.module.css";

import CustomCheckbox from "../../../Components/CustomCheckbox";
import Helper from "../../../Components/Helper";
import HelperButton from "../../../Components/HelperButton";
import {
  GetConsultantPreferencesParams,
  editUserMissionPreferences,
  editConsultantSkillMarks,
} from "./../../../Services/User";

const ProfilPro = ({ history }) => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const dispatch = useDispatch();
  const updateProgress = (payload) =>
    dispatch({ type: "UPDATE_PROGRESS", payload });

  const [descriptionLength, setDescriptionLength] = useState(true);
  const [loading, setLoading] = useState(true);
  const [openPopup, setOpen] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [skillMarks, setConsultantSkillMarks] = useState([]);
  const referencesRef = useRef(null);
  const [reference, setReference] = useState([]);
  const [description, setDescription] = useState("");
  const [showed, setShowed] = useState(false);

  const [params, setParams] = useState({
    companySizes: [],
    companyTypes: [],
    workRythmns: [],
  });
  const [preferences, setPref] = useState({
    dailyRateAverage: 0,
    duration: "",
    city: "",
    mobility: "",
    companySizes: [],
    companyTypes: [],
    workRythmns: [],
  });

  useEffect(() => {
    setLoading(true);
    if (currentConsultant) {
      const { missionPreferences } = currentConsultant;
      GetConsultantPreferencesParams().then((res) => {
        setParams(res.data);
      });
      if (missionPreferences) {
        setPref((prevState) => ({
          ...prevState,
          dailyRateAverage: missionPreferences.dailyRateAverage,
          duration: missionPreferences.duration,
          city: missionPreferences.city,
          mobility: missionPreferences.mobility,
          companySizes: missionPreferences.companySizes || [],
          workRythmns: missionPreferences.workRythmns || [],
          companyTypes: missionPreferences.companyTypes,
        }));
      }
      setLoading(false);
    }
  }, [currentConsultant]);

  const durees = [
    {
      value: (
        <>
          Courte
          <br />
          {"<6 mois"}
        </>
      ),
      duration: "SHORT",
    },
    {
      value: (
        <>
          Moyenne
          <br />
          {"entre 6 et 12 mois"}
        </>
      ),
      duration: "MEAN",
    },
    {
      value: (
        <>
          Longue
          <br />
          {">12 mois"}
        </>
      ),
      duration: "LONG",
    },
  ];
  const handleCompanySizeCheckbox = (e) => {
    const value = e.target.name;
    setPref((prevState) => {
      let prevCompanySizes = prevState.companySizes ?? [];
      if (prevCompanySizes.includes(value)) {
        prevCompanySizes = prevCompanySizes.filter((cs) => cs !== value);
      } else {
        prevCompanySizes = [...prevCompanySizes, value];
      }
      return {
        ...prevState,
        companySizes: prevCompanySizes,
      };
    });
  };

  const handleCompanyTypeCheckbox = (e) => {
    const value = e.target.name;
    setPref((prevState) => {
      let prevCompanyTypes = prevState.companyTypes ?? [];
      if (prevCompanyTypes.find((prevCompany) => prevCompany.id === value)) {
        prevCompanyTypes = prevCompanyTypes.filter((cs) => cs.id !== value);
      } else {
        prevCompanyTypes = [...prevCompanyTypes, { id: value }];
      }
      return {
        ...prevState,
        companyTypes: prevCompanyTypes,
      };
    });
  };

  const handleWorkRythmnsCheckbox = (e) => {
    const value = e.target.name;
    setPref((prevState) => {
      let prevWorkRythmns = prevState.workRythmns ?? [];
      if (prevWorkRythmns.includes(value)) {
        prevWorkRythmns = prevWorkRythmns.filter((cs) => cs !== value);
      } else {
        prevWorkRythmns = [...prevWorkRythmns, value];
      }
      return {
        ...prevState,
        workRythmns: prevWorkRythmns,
      };
    });
  };

  useEffect(() => {
    if (currentConsultant) {
      if (currentConsultant.consultantSkillMarks) setConsultantSkillMarks(currentConsultant.consultantSkillMarks);
      GetReferences(currentConsultant.id)
        .then((res) => {
          setReference([...res.data]);
          setLoading(false);
        })
        .catch((e) => console.log(e));
      if (currentConsultant.description) {
        setDescription(currentConsultant.description);
        setDescriptionLength(currentConsultant.description.length);
      }
    }
  }, [currentConsultant]);

  const onSearch = (search) => {
    getSkills(search)
      .then((res) => setSearchData(res.data))
      .catch((e) => console.log(e));
  };
  const onCloseSearch = () => {
    setSearchData([]);
  };

  const onAddSkill = (skill) => {
    const exist = skillMarks.reduce((prev, cur) => {
      return prev.skill || cur.skill.label === skill.label;
    }, false);
    if (!exist) {
      setConsultantSkillMarks([...skillMarks, { skill }]);
    }
    setSearchData([]);
  };
  const onDeleteSkill = (skillMark) => {
    setConsultantSkillMarks(skillMarks.filter((sm) => sm.skill.label !== skillMark.skill.label));
  };
  const addReference = (values) => {
    AddConsultantReference({ ...values }, currentConsultant.id)
      .then((res) => {
        setOpen(false);
        setReference([...reference, { ...values, id: res.data.id }]);
      })
      .catch((e) => console.log(e));
  };
  const onDeleteReference = (id) => {
    DeleteReference(id)
      .then((res) => {
        setReference([...reference].filter((refe) => refe.id !== id));
        // toast("success", "Réference supprimée");
      })
      .catch((e) => console.log(e));
  };

  const handleTextAreaChange = (e) => {
    const value = e.target.value;
    setDescriptionLength(value.length);
    setDescription(value);
  };

  const onSubmit = (e) => {
    editUserMissionPreferences(currentConsultant.id, {
      id: currentConsultant.id,
      missionPreferences: {
        ...preferences,
      },
    }).then(() => {
      editConsultantSkillMarks(currentConsultant.id, {
        id: currentConsultant.id,
        consultantSkillMarks: skillMarks,
      }).then((resPreferences) => {
        updateProgress(resPreferences.data);
        editProfilePro(currentConsultant.id, {
          description,
          references: reference.map((r) => ({ id: r.id })),
        }).then((res) => {
          updateProgress(res.data);
          toast("success", "Sauvegardé");
        });
      })
    });
  };

  const toggleOpen = (e) => {
    setShowed(!showed);
    if (
      !referencesRef.current.style.maxHeight ||
      referencesRef.current.style.maxHeight === "0px"
    ) {
      referencesRef.current.style.maxHeight = "100vh";
    } else {
      referencesRef.current.style.maxHeight = null;
    }
  };

  // const handleUploadOtherResume = async () => {
  //   if (cv || resumeId) {
  //     await onDeleteCv();
  //   }
  //   uploadCv.current.click();
  // };

  return (
    <div className="card col-12 col-md p-4 p-md-5 d-flex flex-column gap-5 position-relative">
      {openPopup && (
        <AddReference
          show={openPopup}
          onClose={(e) => setOpen(false)}
          onSubmit={addReference}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <div className="w-100 d-flex flex-column gap-2">
            <MobileNavigation />
            <div className="wow-subtitle bold">
              Transmettre mon CV
              <Helper content="Indispensable pour pouvoir postuler à nos missions"></Helper>
            </div>

            <input
              type="file"
              name="cv"
              id="cv"
              accept="image/*, application/pdf"
              ref={uploadCv}
              onChange={(e) => {
                if (e.target.files[0]) {
                  onUploadCv(e.target.files[0]);
                }
              }}
              hidden
            />
            {cv ? (
              <div className="w-100 d-flex flex-column flex-md-row align-items-center justify-content-between gap-3">
                <a href={cv} target="_blank" rel="noreferrer">
                  <u className="wow-body blue-text">Voir le CV</u>
                </a>
                <div className="d-flex flex-row flex-wrap justify-content-center gap-3">
                  <button
                    type="button"
                    className="default-btn danger-btn w-auto"
                    onClick={(e) => onDeleteCv()}
                  >
                    <FiTrash className="mx-1" />
                    Supprimer
                  </button>
                  <button
                    className="default-btn blue-btn w-auto"
                    onClick={handleUploadOtherResume}
                  >
                    <FiUpload className="mx-1" />
                    Transmettre un autre CV
                  </button>
                </div>
              </div>
            ) : (
              <div
                {...getRootProps()}
                className={style["upload-container"]}
                onClick={(e) => uploadCv.current.click()}
              >
                <input {...getInputProps()} />
                <FiUpload className="header-1 green-text" />
                <div className="header-4 green-text bold" id="cv">
                  Transmettre votre CV
                </div>
                <div className="wow-body green-text">
                  Fichiers supportés PNG, PDF, JPG, JPEG
                </div>
              </div>
            )}
          </div> */}

          <div className="w-100 d-flex flex-column gap-3">
            <div className="wow-subtitle bold">
              Quelques mots à propos de moi
            </div>
            <textarea
              className="text-area default-input"
              placeholder="Dites-nous en un peu plus sur vous, ce qui vous anime, les projets qui peuvent vous intéresser."
              onChange={handleTextAreaChange}
              value={description}
              maxLength="300"
            />
            <div className={`small-text ${style["counter"]}`}>
              {typeof descriptionLength === "number"
                ? 300 - descriptionLength
                : 300}
            </div>
          </div>

          <div className="w-100 d-flex flex-column gap-2">
            {searchData.length > 0 && (
              <div
                className={style["close-search-bg"]}
                onClick={(e) => onCloseSearch()}
              ></div>
            )}
            <div className="wow-subtitle bold mb-2" id="cr">
              Mes compétences
              <Helper content="Renseignez 3 compétences minimum pour pouvoir augmenter votre score et ainsi, postuler à nos missions"></Helper>
            </div>

            <div className="small-text">Renseignez vos compétences </div>
            <div className="w-100 position-relative">
              <FiSearch className={style["search-icon"]} />
              <input
                type="text"
                onChange={(e) => {
                  if (e.target.value !== "") {
                    onSearch(e.target.value);
                  } else {
                    onCloseSearch();
                  }
                }}
                className={style["search-input"] + " default-input"}
                placeholder="Rechercher ici des compétences"
              />

              <div className={style["search-content"]}>
                {searchData.map((d, i) => (
                  <div key={i} onClick={(e) => onAddSkill(d)}>
                    {d.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="small-text">
              Une compétence est manquante :{" "}
              <a
                className={style["link-skill"]}
                href="https://airtable.com/shrqq2Fapa7QQCoRt"
              >
                cliquez-ici
              </a>
            </div>
            <div className="w-100 d-flex flex-row flex-wrap gap-3">
              {skillMarks.map((sm, i) => (
                <span key={i} className={style["skill"]}>
                  {sm.skill.label}
                  <FiX
                    className={style["delete-skill"]}
                    onClick={(e) => onDeleteSkill(sm)}
                  />
                </span>
              ))}
            </div>
          </div>

          <div className="w-100 d-flex flex-column gap-3" id="ra">
            <div
              className="wow-subtitle bold w-100 d-flex flex-row justify-content-between cursor-pointer"
              onClick={toggleOpen}
            >
              Mes références
              <span>{showed ? "-" : "+"}</span>
            </div>
            <div className="small-text">
              Ajoutez le contact d'une personne qui vous recommande.
            </div>
            <div
              ref={referencesRef}
              className={`w-100 d-flex flex-row flex-wrap align-items-center my-4 gap-3 ${style["accordion-form"]}`}
            >
              {reference.map((reference, i) => {
                return (
                  <div
                    key={i}
                    className={
                      `w-100 d-flex flex-column py-4 px-3 gap-2 ` +
                      style["reference"]
                    }
                  >
                    <FiTrash
                      className={style["delete-reference"]}
                      onClick={(e) => onDeleteReference(reference.id)}
                    />
                    <div className="wow-body bold">
                      {reference.lastName + " " + reference.firstName}
                    </div>
                    <div className="small-text bold">
                      {reference.description}
                    </div>
                    <div className="small-text bold">{reference.email}</div>
                    <div className="small-text bold">
                      {reference.phoneNumber}
                    </div>
                  </div>
                );
              })}
              <div className={style["add-ref"]} onClick={(e) => setOpen(true)}>
                <FiPlus />
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <div className="wow-subtitle bold mb-2" id="pref">
              Mes préférences de missions
              <Helper content="Renseignez toutes les informations pouvoir augmenter votre score et ainsi, postuler à nos missions"></Helper>
            </div>
            <div className="wow-body">
              Quels sont vos critères dans la recherche de missions ?
            </div>
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="wow-subtitle bold">Durée de la mission</div>
            <div className="d-flex flex-row flex-wrap gap-3 justify-content-center">
              {durees.map((value, i) => {
                return (
                  <div key={i}>
                    <button
                      type="button"
                      className={`default-btn ${
                        value.duration === preferences.duration
                          ? ""
                          : "primary-btn"
                      }`}
                      style={{ width: "260px" }}
                      onClick={(e) =>
                        setPref({ ...preferences, duration: value.duration })
                      }
                    >
                      {value.value}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-100 d-flex flex-row justify-content-between gap-3 align-items-center">
            <div className="wow-subtitle bold">
              Taux journalier souhaité (€)
            </div>
            <input
              type="number"
              name="dailyRateAverage"
              className="default-input w-50"
              onChange={(e) =>
                setPref({ ...preferences, dailyRateAverage: e.target.value })
              }
              placeholder="Entrer votre taux journalier en euros"
              value={preferences.dailyRateAverage}
            />
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <div className="wow-subtitle bold">Lieu de travail</div>
            <div className="wow-body bold">Ville de résidence</div>
            <input
              type="text"
              name="city"
              value={preferences.city}
              onChange={(e) =>
                setPref({ ...preferences, city: e.target.value })
              }
              className="default-input mb-3"
              placeholder="Entrez une ville"
            />
            <div className="wow-body bold">Mobilité</div>
            <select
              type="text"
              className="default-input"
              value={preferences.mobility}
              onChange={(e) =>
                setPref({ ...preferences, mobility: e.target.value })
              }
            >
              <option></option>
              <option value="CITY">Ville</option>
              <option value="DEPARTMENT">Région</option>
              <option value="COUNTRY">Pays</option>
              <option value="INTERNATIONAL">International</option>
              <option value="TELETRAVAIL">Télétravail</option>
            </select>
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="wow-subtitle bold">Secteur(s)</div>
            <div className="d-flex flex-row flex-wrap gap-4">
              {params.companyTypes.map((ct, i) => {
                const isChecked = !!preferences.companyTypes.find(
                  (companyType) => companyType.id === ct.id
                );
                return (
                  <div key={i} className={style["checkbox-container"]}>
                    <CustomCheckbox
                      value={ct.id}
                      name={ct.id}
                      label={ct.label}
                      checked={isChecked}
                      onChange={handleCompanyTypeCheckbox}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="wow-subtitle bold">Taille d'entreprises</div>
            <div className="d-flex flex-row flex-wrap gap-4">
              {params.companySizes.map((cs, i) => {
                return (
                  <div key={i} className={style["checkbox-container"]}>
                    <CustomCheckbox
                      value={cs[0]}
                      name={cs[0]}
                      label={cs[1]}
                      checked={preferences.companySizes.includes(cs[0])}
                      onChange={handleCompanySizeCheckbox}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="wow-subtitle bold">Rythme de travail souhaité</div>
            <div className="d-flex flex-row flex-wrap gap-4">
              {params.workRythmns.map((wr, i) => {
                return (
                  <div key={i} className={style["checkbox-container"]}>
                    <CustomCheckbox
                      value={wr[0]}
                      name={wr[0]}
                      label={wr[1]}
                      checked={preferences.workRythmns.includes(wr[0])}
                      onChange={handleWorkRythmnsCheckbox}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={`${style["helperButtonContainer"]} mx-auto my-4`}>
            <button type="submit" className="default-btn" onClick={onSubmit}>
              Sauvegarder
            </button>
            <span className={`${style["helperButton"]}`}>
              <HelperButton
                className={style["helperButton"]}
                link="https://wheelofwork.notion.site/Comment-compl-ter-mon-profil-b1061bd3baa6455da0f2294575f947ff"
              ></HelperButton>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(ProfilPro);