import { userActions } from "./actions";

const initialState = {
  isLoggedIn:
    !!localStorage.getItem("USER_ID") || !!sessionStorage.getItem("USER_ID"),
  consultantId:
    localStorage.getItem("CONSULTANT_ID") ??
    sessionStorage.getItem("CONSULTANT_ID"),
  notifications: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.LOGIN_USER:
      return {
        ...state,
        isLoggedIn: true,
        consultantId: action.payload.consultant.id,
        consultant: action.payload.consultant,
      };
    case userActions.LOGOUT_USER:
      return { ...state, isLoggedIn: false };
    case userActions.SET_CURRENT_CONSULTANT:
      return { ...state, consultant: action.payload.consultant };
    case userActions.SET_CURRENT_CONSULTANT_AVAILIBITY:
      return {
        ...state,
        consultant: { ...state.consultant, availability: action.payload },
      };
    case userActions.SET_CURRENT_CONSULTANT_SOCIETY:
      return {
        ...state,
        consultant: { ...state.consultant, society: action.payload },
      };
    case userActions.SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS:
      return {
        ...state,
        consultant: {
          ...state.consultant,
          documents: [
            ...state.consultant.documents?.filter(
              (docs) => docs.type !== "legal_files"
            ),
            {
              type: "legal_files",
              documents: [...action.payload],
            },
          ],
        },
      };
    case userActions.UPDATE_PROGRESS:
      return { ...state, consultant: action.payload };
    case userActions.SET_CURRENT_CONSULTANT_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    default:
      return { ...state };
  }
};

export default userReducer;
