import Api, { ProtectedApi } from "../Helpers/api";

export const login = (payload) => {
  return Api.post("/auth/login", {
    ...payload,
  });
};
export const load = async () => {
  return ProtectedApi.get("/auth/token/verify/consultant");
};
export const register = (payload) => {
  return Api.post("/auth/signup", { ...payload });
};

export const checkIfExists = (payload) => {
  return Api.post("/auth/checkIfExists", { ...payload });
};
export const updatePassword = async (payload) => {
  return ProtectedApi.patch("/auth/password", { ...payload });
};
export const forgetPassword = (payload) => {
  return Api.post("/auth/otp/reset", { ...payload });
};
export const resetPassword = (payload) => {
  return Api.post(`/auth/otp/reset/updatePassword`, { ...payload });
};
export const RefreshAccessToken = () => {
  return Api.get("/auth/token/refresh");
};

export const takeControlAdminPanel = (consultantId, bearer) => {
  // put the admin token in query param to differenciate the connection method
  return Api.get("/auth/signed-admin-control-panel", {
    params: { consultantId, bearer },
  });
};
