import React from 'react'
import { NavLink } from 'react-router-dom'
import style from './style.module.css'


const Navigation = () => {
    return <div className='card col-3 d-none d-md-flex p-5 gap-5'>
        <NavLink
            to='/profile/informations-generales'
            className={`${style['profile-navlink']} small-text`}
             activeClassName={`${style['active']}`}>
                 Informations générales
        </NavLink>
        <NavLink
            to='/profile/profil-professionnel'
            className={`${style['profile-navlink']} small-text`}
             activeClassName={`${style['active']}`}>
                 Profil professionnel
        </NavLink>
        <NavLink
            to='/profile/documents-legaux'
            className={`${style['profile-navlink']} small-text`}
             activeClassName={`${style['active']}`}>
                 Documents légaux
        </NavLink>
        <NavLink
            to='/timesheet'
            className={`${style['profile-navlink']} small-text`}
             activeClassName={`${style['active']}`}>
                 Comptes rendus d'activité
        </NavLink>
        <NavLink
            to='/profile/factures'
            className={`${style['profile-navlink']} small-text`}
             activeClassName={`${style['active']}`}>
                 Factures
        </NavLink>
    {/* <NavLink
            to='/profile/preferences-missions'
            className={`${style['profile-navlink']} small-text`}
             activeClassName={`${style['active']}`}>
                 Préférences de missions
</NavLink>*/}
        <NavLink
            to='/profile/parametres'
            className={`${style['profile-navlink']} small-text`}
             activeClassName={`${style['active']}`}>
                Paramètres avancés
        </NavLink>
    </div>
}

export default Navigation