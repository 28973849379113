import React, { useEffect, useState } from "react";
import Popup from "../Popup";

const TimesheetPopup = ({ show, onClose, onValidate, events }) => {
  const [sortedEvents, setSortedEvents] = useState([]);
  const [comment, setComment] = useState("");
  const duree = events.reduce(
    (prevValue, currValue) => prevValue + currValue.percentage,
    0
  );

  const handleCommentTextArea = (e) => setComment(e.target.value);

  useEffect(() => {
    if (events) {
      const sortedEvents = events.sort(
        (e1, e2) => new Date(e1.unparsedDate) - new Date(e2.unparsedDate)
      );
      setSortedEvents(sortedEvents);
    }
  }, [events]);

  const handleValidation = () => onValidate(events, comment);

  return (
    <Popup
      show={show}
      onClose={(e) => {
        onClose(e);
      }}
    >
      <form className="row px-2 px-md-5 py-5 gap-1">
        <div className="col-12 d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column wow-body bold mb-4">
              Date de début de la période sélectionnée
              <input
                className="default-input mt-4"
                value={
                  sortedEvents[0] &&
                  sortedEvents[0].unparsedDate.toLocaleDateString()
                }
              />
            </div>
            <div className="d-flex flex-column wow-body bold mb-2">
              Date de fin de la période sélectionnée
              <input
                className="default-input mt-4"
                value={
                  sortedEvents[sortedEvents.length - 1] &&
                  sortedEvents[
                    sortedEvents.length - 1
                  ].unparsedDate.toLocaleDateString()
                }
              />
            </div>
          </div>

          <div className="wow-body bold mb-2">Durée</div>
          <input className="default-input mt-4" value={`${duree} jours`} />
        </div>
        <div className="col-12 d-flex flex-column my-4">
          <label htmlFor="comment" className="wow-body bold mb-2">
            Laisser un commentaire
          </label>
          <textarea
            id="comment"
            value={comment}
            name="comment"
            className="default-input text-area"
            onChange={handleCommentTextArea}
          />
        </div>
        <div className="col-12 d-flex flex-row justify-content-center justify-content-md-end gap-4">
          <div className="w-auto">
            <button
              type="reset"
              className="default-btn danger-btn"
              onClick={onClose}
            >
              Annuler
            </button>
          </div>
          <div className="w-auto">
            <button
              type="button"
              className="default-btn"
              onClick={handleValidation}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Popup>
  );
};

export default TimesheetPopup;
