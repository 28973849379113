export const WorkRythmn = {
  FULLTIME: "Temps plein",
  PARTIAL: "Temps partiel",
};

export const Duration = {
  LONG: "Longue durée",
  MEAN: "Durée moyenne",
  SHORT: "Courte durée",
};

export const WorkLocation = {
  HYBRID: 'Hybride',
  ONSITE: 'Sur site',
  REMOTE: 'À distance',
};
