import React, { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import style from './style.module.css';

const Slider = () => {
    const [step, setStep] = useState(false);

    const slideContent = "Wheel of Work vous propose les missions les plus pointues du marché, celles qui vous permettront d’intégrer les entreprises les plus réputées de leur secteur.";
    const titleOne = "SUPER SELECTIVE MISSIONS";
    const titleTwo = "SUPER ATTENTIVE BACK UP";

    const updateStep = () => {
        setStep((oldStep) => !oldStep);
    }

    const sliderClasses = step ? `${style['slider']}` : `${style['slider']} ${style['slider-bg']}`;

    return <div className={sliderClasses} onClick={updateStep}>
        <div>
            {!step && <h3 className={`${style['slider-title']}`}>{titleOne}</h3>}
            {step && <h3 className={`${style['slider-title']}`}>{titleTwo}</h3>}
            <div className={`${style['slider-content']}`}>
                {slideContent}
            </div>
        </div>
        <div className={style['slider-dots']}>
            <FiChevronLeft></FiChevronLeft>
            <FiChevronRight></FiChevronRight>
        </div>
    </div>
}

export default Slider