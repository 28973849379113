import React, { useState } from "react";
import { FiSearch, FiXCircle, FiSliders, FiArrowLeft } from "react-icons/fi";
import SearchBar from "../../Components/SearchBar";
import style from "./style.module.css";
import CustomCheckbox from "./../../Components/CustomCheckbox/index";
import Mission from "../../Components/Mission";
import Pagination from "../../Components/Pagination";
import { useEffect } from "react";
import { GetMissions, SearchMission } from "../../Services/Missions";
import Loader from "./../../Components/Loader/index";
import { GetMissionsFilters } from "./../../Services/Missions";
import { durations as Durations } from "../../Helpers/data";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const queryString = require("query-string");

const sortMissionsByCreatedAt = (missions) => {
  return missions.sort((m1, m2) => {
    // Convertir les dates en objets Date si elles ne le sont pas déjà
    const date1 = new Date(m1.createdAt);
    const date2 = new Date(m2.createdAt);
    
    // Trier de façon décroissante (du plus récent au plus ancien)
    return date2 - date1;
  });
};

const Missions = () => {
  const location = useLocation();
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [page, setPage] = useState(1);
  const [nbPages, setNbPages] = useState(1);

  const [loading, setLoading] = useState(false);

  const [durations, setDurations] = useState([]);
  const [durationsFilters, setDurationsFilters] = useState([]);

  const [workRythmn, setWorkRythmns] = useState([]);
  const [workRythmsFilters, setWorkRythmsFilters] = useState([]);

  const [workLocations, setWorkLocations] = useState([]);
  const [workLocationsFilters, setWorkLocationsFilters] = useState([]);

  const [openSearch, setOpenSearch] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const [missions, setMissions] = useState([]);

  // Variables d'état pour les filtres mobiles
  const [durationsFiltersMobile, setDurationsFiltersMobile] = useState([]);
  const [workRythmsFiltersMobile, setWorkRythmsFiltersMobile] = useState([]);
  const [workLocationsFiltersMobile, setWorkLocationsFiltersMobile] = useState([]);

  const renderCheckboxes = (items, filters, setFilters) => {
    return items.map((item, i) => (
      <CustomCheckbox
        key={i}
        label={item[1]}
        name={item[0]}
        value={item[1]}
        checked={filters.includes(item[1])}
        onChange={(e) => {
          const newChecked = e.target.checked;
          setPage(1);
          setFilters(prevFilters => 
            newChecked
              ? [...prevFilters, item[1]]
              : prevFilters.filter(f => f !== item[1])
          );
        }}
      />
    ));
  };

  useEffect(() => {
    if (currentConsultant) {
      GetMissionsFilters(currentConsultant ? currentConsultant.id : '').then((res) => {
        setDurations(res.data.duration);
        setWorkRythmns(res.data.workRythmn);
        setWorkLocations(res.data.workLocation);
      });
    }
  }, [currentConsultant]);

  useEffect(() => {
    setLoading(true);
    if (currentConsultant) {
      GetMissions({
        page: page - 1,
        itemsPerPage: 10,
        duration: [...durationsFilters],
        workRythmn: [...workRythmsFilters],
        workLocation: [...workLocationsFilters],
        consultantId: currentConsultant.id,
      })
        .then((res) => {
          const sortedMissions = sortMissionsByCreatedAt(res.data.missions);
          setMissions(sortedMissions);
          setNbPages(res.data.numberOfPages);
          setLoading(false);
        })
        .catch((e) => setLoading(false));
    }
  }, [
    currentConsultant,
    page,
    durationsFilters,
    workRythmsFilters,
    workLocationsFilters,
  ]);

  useEffect(() => {
    if (location && (location.search || location.address)) {
      const params = queryString.parse(location.search);
      SearchMission(params.search, params.address).then((res) =>
        setMissions(res.data)
      );
    }
  }, [location]);

  const handleChangeLike = (missionUpdated) => {
    const updatedMissions = [
      ...missions.filter((mission) => mission.id !== missionUpdated.id),
      missionUpdated,
    ];
    const sortedMissions = sortMissionsByCreatedAt(updatedMissions);

    setMissions(sortedMissions);
  };
  return (
    <div className={"container-lg " + style["missions-page"]}>
      <div className="row justify-content-center mb-3 mb-md-5">
        <div className="col-12 col-md-10 mx-1 d-flex flex-row justify-content-between align-items-center">
          <div className="header-4 font-family-tt">Trouvez votre mission idéale</div>
          <div>
            <button
              type="button"
              className="default-btn d-flex d-md-none p-3"
              onClick={(e) => setOpenSearch(true)}
            >
              <FiSearch />
            </button>
          </div>
        </div>
      </div>
      <SearchBar search={true} />
      <div
        className={
          "card d-flex flex-row justify-content-center  d-md-none " +
          style["filter-btn"]
        }
      >
        <button
          className="w-50 default-btn "
          onClick={(e) => setOpenFilters(true)}
        >
          Filtre
          <FiSliders className="mx-2" />
        </button>
      </div>
      <div className="row justify-content-center mx-1 mt-3 mt-md-5 mb-4">
        <div className="col-12 col-md-10 d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center mt-0 mt-md-5">
          <div className="d-flex flex-row align-items-baseline w-100">
            <div className="small-text black-text mx-2">
              {missions.length} résultat{missions.length > 1 && 's'}
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-1 gap-4">
        <div className="col-4 d-none card p-5 d-md-flex flex-column gap-5">
          <div className="d-flex flex-column gap-4">
            <div className="subtitle bold">Durée</div>
            {renderCheckboxes(durations, durationsFilters, setDurationsFilters)}
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="subtitle bold">Type de contrat</div>
            {renderCheckboxes(workRythmn, workRythmsFilters, setWorkRythmsFilters)}
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="subtitle bold">Sur site / A distance</div>
            {renderCheckboxes(workLocations, workLocationsFilters, setWorkLocationsFilters)}
          </div>
        </div>
        <div className="col d-flex flex-column gap-3 align-items-center">
          {loading ? (
            <div
              className="w-100 position-relative card"
              style={{ height: "300px" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {missions.length > 0 ? (
                <>
                  {missions.map((mission, i) => {
                    return (
                      <Mission
                        mission={mission}
                        key={i}
                        onChangeLike={handleChangeLike}
                      />
                    );
                  })}
                  <div className="mt-5">
                    <Pagination
                      currentPage={page}
                      nbPages={nbPages}
                      setPage={setPage}
                    />
                  </div>
                </>
              ) : (
                <div className="w-100 card p-5 wow-body text-center">
                  Aucune mission
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Mobile version of search bar     */}
      <div
        className={`${style["search-mobile"]} ${openSearch ? style["open"] : ""
          } container-fluid d-flex flex-column d-md-none`}
      >
        <FiXCircle
          className={style["search-mobile-close"]}
          onClick={(e) => setOpenSearch(false)}
        />
        <SearchBar onSearch={() => setOpenSearch(false)} />
      </div>

      {/* Mobile version of filters */}
      <div
        className={`${style["filters-mobile"]} ${openFilters ? style["open"] : ""} container-fluid d-flex flex-column d-md-none`}>
        <FiArrowLeft
          className={style["filters-mobile-return"]}
          onClick={(e) => {
            setDurationsFiltersMobile([...durationsFilters]);
            setWorkRythmsFiltersMobile([...workRythmsFilters]);
            setWorkLocationsFiltersMobile([...workLocationsFilters]);
            setOpenFilters(false);
          }}
        />
        <div className="row justify-content-center">
          <div className="col-10 d-flex flex-column gap-5">
            <div className="d-flex flex-column gap-4">
              <div className="subtitle bold">Durée</div>
              {renderCheckboxes(durations, durationsFiltersMobile, setDurationsFiltersMobile)}
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="subtitle bold">Type de contrat</div>
              {renderCheckboxes(workRythmn, workRythmsFiltersMobile, setWorkRythmsFiltersMobile)}
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="subtitle bold">Sur site / A distance</div>
              {renderCheckboxes(workLocations, workLocationsFiltersMobile, setWorkLocationsFiltersMobile)}
            </div>
            <button
              className="default-btn"
              onClick={(e) => {
                setDurationsFilters([...durationsFiltersMobile]);
                setWorkRythmsFilters([...workRythmsFiltersMobile]);
                setWorkLocationsFilters([...workLocationsFiltersMobile]);
                setOpenFilters(false);
              }}
            >
              Appliquer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Missions;