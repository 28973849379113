import React from 'react'
// import style from './style.module.css'
import Popup from '../../Popup/index';
import { FiArrowLeft } from 'react-icons/fi';
// import { useFormik } from 'formik';


const Postuler = ({ missionName, show, onClose, onSubmit }) => {
    // const uploadCv = useRef(null)
    // const [cv, setCv] = useState(null)

    // const formik = useFormik({
    //     initialValues: {
    //         motivation: ''
    //     },
    //     onSubmit: values => {
    //         onSubmit(values)
    //     }
    // })
    return <Popup show={show} onClose={onClose}>
        <div className='row px-2 px-md-5 py-4 gap-5 justify-content-center'>
            <div className='col-12 d-flex flex-row align-items-center'>
                <FiArrowLeft className='header-3 mx-2 cursor-pointer red-text' onClick={onClose} />
                <div className='wow-body red-text cursor-pointer' onClick={onClose}>Retour vers la mission</div>
            </div>
            {/* <div className='col-12 d-flex flex-column gap-4'>
                <div className='wow-subtitle bold'>Votre CV</div>
                <div className='w-100 d-flex flex-column gap-2'>
                    <div className='small-text bold text-truncate'>Utiliser le cv energistré ou télécharger un autre cv</div>
                    <div className={style['cv-container'] + ' w-100 d-flex flex-row justify-content-between align-items-center gap-3'}>
                        <div className='small-text text-truncate'>{cv ? cv.name : 'Aucun CV'}</div>
                        <input
                            type="file"
                            name="cv"
                            id="cv"
                            ref={uploadCv}
                            hidden
                            onChange={e => { if (e.target.files[0]) setCv(e.target.files[0]) }}
                            accept='image/*, application/pdf' />
                        <div className='w-100' style={{ maxWidth: '240px' }}>
                            <button type='button' className='default-btn secondary-btn' onClick={e => uploadCv.current.click()}>
                                <FiUpload className='mx-1' />
                                Télécharger un CV
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='col-12 wow-subtitle text-center gap-4'>
                Etes-vous sûr(e) de vouloir postuler à la mission <b>{missionName}</b> ?
            </div>
            <div className='col-auto'>
                <button type="submit" className='default-btn' onClick={onSubmit}>Confirmer</button>
            </div>
            <div className='col-auto'>
                <button type="submit" className='default-btn danger-btn' onClick={onClose}>Annuler</button>
            </div>
        </div>
    </Popup>
}

export default Postuler