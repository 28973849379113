import styles from "./style.module.css";

export const SuggestiveResults = ({ results, onClick }) => {
  const onClickHandler = (el) => {
    onClick(el);
  };
  return (
    <div className={`${styles["container"]}`}>
      <ul>
        {results.map((res, index) => (
          <li key={index} onClick={() => onClickHandler(res)}>
            {res && res.label ? res.label : res}
          </li>
        ))}
      </ul>
    </div>
  );
};
