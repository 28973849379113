import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import Navigation from "./../../Components/Profil/Navigation/index";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { withRouter } from "react-router";
import { getConsultant } from "./../../Services/User";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userActions } from "../../Redux/User/actions";

const ProfilLayout = ({ children, location }) => {
  const dispatch = useDispatch();
  const consultantId = useSelector((state) => state.userReducer.consultantId);

  const [showProgress, setShowProgress] = useState(false);
  const [progressData, setProgressData] = useState({});
  useEffect(() => {
    getConsultant(consultantId).then((res) => {
      const data = res.data;
      dispatch({
        type: userActions.SET_CURRENT_CONSULTANT,
        payload: { consultant: res.data },
      });
      if (data.accomplishmentPourcentage < 100) {
        setShowProgress(true);
        setProgressData({ ...data });
      } else setShowProgress(false);
    });
  }, [location]);
  return (
    <div className="container-lg">
      {showProgress && (
        <div
          className={`row card py-4 px-4 px-md-5 mb-3 gap-1 position-relative`}
        >
          <div className="col-12 d-flex flex-row justify-content-end">
            <FiXCircle
              className={style["close-progress"]}
              onClick={(e) => setShowProgress(false)}
            />
          </div>
          <div className="col-12 wow-subtitle bold mb-3">
            Complétez votre compte
          </div>
          <div className="col-12 wow-body">
            Compléter votre profil pour postuler à des missions
          </div>
          <div className="col-12 d-flex flex-row align-items-center mb-4">
            <div
              className={`progress-bar-container ${style["progress-container"]}`}
            >
              <div
                className="progress-bar-progress"
                style={{ width: `${progressData.accomplishmentPourcentage}%` }}
              ></div>
            </div>
            <div className="small-text bold mx-1 mx-md-5">
              {progressData.accomplishmentPourcentage} % complété
            </div>
          </div>
          <div className="col-12 d-flex flex-column gap-2">
            <Link
              to="/profile/informations-generales"
              className={`small-text ${
                progressData.generalInformation ? "green-text" : ""
              }`}
            >
              <FiCheckCircle className="mx-1" />
              Renseignez vos informations générales
            </Link>
            <Link
              to="/profile/profil-professionnel"
              className={`small-text ${
                progressData.resumePath ? "green-text" : ""
              }`}
            >
              <FiCheckCircle className="mx-1" />
              Téléchargez votre CV
            </Link>
            <Link
              to="/profile/profil-professionnel"
              className={`small-text ${
                progressData.skills ? "green-text" : ""
              }`}
            >
              <FiCheckCircle className="mx-1" />
              Renseignez vos compétences
            </Link>
            <Link
              to="/profile/profil-professionnel"
              className={`small-text ${
                progressData.reference ? "green-text" : ""
              }`}
            >
              <FiCheckCircle className="mx-1" />
              Ajoutez une recommandation
            </Link>
            <Link
              to="/profile/preferences-missions"
              className={`small-text ${
                progressData.missionsPreference ? "green-text" : ""
              }`}
            >
              <FiCheckCircle className="mx-1" />
              Saisissez vos préférences de missions
            </Link>
          </div>
        </div>
      )}
      <div className="row gap-3" style={{ minHeight: "400px" }}>
        <Navigation />
        {children}
      </div>
    </div>
  );
};

export default withRouter(ProfilLayout);
