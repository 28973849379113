import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Helper from "../../Components/Helper";
import { capitalizeFirstLetter } from "../../Helpers/string";
import { GetActiveContracts } from "../../Services/Contract";
import { GetTimesheet } from "../../Services/Timesheet";
import { GetInvoices } from "../../Services/Invoice";
import CurrentMission from "./../../Components/Dashboard/CurrentMission/index";
import Loader from "./../../Components/Loader/index";
import { getAccomplishments } from "./../../Services/Accomplishment";
import style from "./style.module.css";

import { FiCheck, FiX, FiExternalLink, FiUsers } from 'react-icons/fi';
import { LuRotate3D, LuHeartHandshake } from 'react-icons/lu';
import { HashLink } from "react-router-hash-link";
import HelperButton from "../../Components/HelperButton";
import { scrollWithOffset } from "../../utils";
const Dashboard = () => {
  const consultantId = useSelector((state) => state.userReducer.consultantId);
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );

  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [timesheets, setTimesheets] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [accomplishments, setAccomplishments] = useState([]);

  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  };

  const currentDate = new Date();
  const lastMonthDate = new Date();
  lastMonthDate.setMonth(currentDate.getMonth() - 1);
  
  const contractDates = [
    normalizeDate(currentDate),
    normalizeDate(lastMonthDate),
  ];
  
  const generateCurrentMissionElements = (contractDates, contracts, timesheets) => {
    return contractDates.map((date, i) => {
      const concernedContract = contracts.find((c) => {
        const startDate = normalizeDate(new Date(c.startDate));
        const expirationDate = normalizeDate(new Date(c.expirationDate));
  
        return startDate <= date && date <= expirationDate;
      });
  
      if (concernedContract) {
        const concernedTimesheet = timesheets.find(
          (t) => t.year === date.getFullYear() && t.month === date.getMonth() + 1
        );
        const concernedInvoice = invoices.find(
          (t) => t.year === date.getFullYear() && t.month === date.getMonth() + 1
        );
        return (
          <CurrentMission
            key={i}
            year={date.getFullYear()}
            month={date.getMonth() + 1}
            mission={concernedContract.mission}
            timesheet={concernedTimesheet}
            invoice={concernedInvoice}
          />
        );
      }
  
      return null; // Ne pas retourner d'élément si aucun contrat n'est trouvé
    }).filter(element => element !== null); // Filtrer les éléments nuls pour éviter les trous dans le rendu
  };

  useEffect(() => {
    if (currentConsultant) {
      setProgress(currentConsultant.accomplishmentPercent);
  
      GetActiveContracts({
        filterByMonthYear: true,
        consultantId: consultantId,
      }).then((resFirstContract) => {
        const firstContract = resFirstContract.data[0];
  
        const startDate = new Date(firstContract?.startDate);
        const startMonth = startDate.getUTCMonth();
        const startYear = startDate.getUTCFullYear();
  
        const lastMonth = lastMonthDate.getUTCMonth();
        const lastYear = lastMonthDate.getUTCFullYear();
  
        const promises = [];
        const contracts = [];
        const invoicesPromises = [];
  
        if (firstContract) {
          contracts.push(firstContract);
  
          promises.push(
            GetTimesheet(firstContract.mission.id, {
              consultantId: consultantId,
              month: currentDate.getMonth() + 1,
              year: currentDate.getUTCFullYear(),
            }).then((resTimesheet) => resTimesheet?.data?.timesheet)
          );
  
          invoicesPromises.push(
            GetInvoices({
              missionId: firstContract.mission.id,
              dateOptions: {
                month: currentDate.getMonth() + 1,
                year: currentDate.getUTCFullYear(),
              },
            }).then((resInvoice) => resInvoice.data)
          );
        }
  
        if (
          !(startYear < lastYear || (startYear === lastYear && startMonth <= lastMonth))
        ) {
          promises.push(
            GetActiveContracts({
              consultantId: consultantId,
              dateOptions: {
                filterByMonthYear: true,
                month: lastMonth + 1,
                year: lastYear,
              },
            }).then((resSecondContract) => {
              const secondContract = resSecondContract.data[0];
  
              if (secondContract) {
                contracts.push(secondContract);
              
                const timesheetPromise = GetTimesheet(secondContract.mission.id, {
                  consultantId: consultantId,
                  month: lastMonth + 1,
                  year: lastYear,
                }).then((resTimesheet) => resTimesheet?.data?.timesheet);
              
                const invoicePromise = GetInvoices({
                  missionId: secondContract.mission.id,
                  dateOptions: {
                    month: lastMonth + 1,
                    year: lastYear,
                  },
                }).then((resInvoice) => resInvoice.data);
              
                invoicesPromises.push(invoicePromise);
                return timesheetPromise;
              }
            })
          );
        } else {
          promises.push(
            GetTimesheet(firstContract.mission.id, {
              consultantId: consultantId,
              month: lastMonth + 1,
              year: lastYear,
            }).then((resTimesheet) => resTimesheet?.data?.timesheet)
          );
  
          invoicesPromises.push(
            GetInvoices({
              missionId: firstContract.mission.id,
              dateOptions: {
                month: lastMonth + 1,
                year: lastYear,
              },
            }).then((resInvoice) => resInvoice.data)
          );
        }
  
        Promise.all([...promises, ...invoicesPromises]).then((results) => {
          const timesheets = results.slice(0, promises.length);
          const invoices = results.slice(promises.length);
  
          const validTimeSheets = timesheets.filter((timesheet) => timesheet != null);
          const validInvoices = invoices.flat().filter((invoice) => invoice != null);
  
          setTimesheets(validTimeSheets);
          setContracts(contracts);
          setInvoices(validInvoices);
        }).then(() => setLoading(false));
      });
  
      getAccomplishments(consultantId).then((res) => {
        setAccomplishments(res.data);
      });
    }
  }, [currentConsultant]);
  

  const currentMissionElements = generateCurrentMissionElements(contractDates, contracts, timesheets);

  return (
    <div className="container">
      {loading ? (
        <div
          className="w-100 card position-relative"
          style={{ height: "300px" }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {contracts.length !== 0 ? (
            <div className="row justify-content-center">
              <div className="col-12 d-flex header-3 flex-row justify-content-between align-items-center mb-4 flex-wrap gap-3">
                {`Bonjour ${capitalizeFirstLetter(
                  currentConsultant.user.firstName
                )}`}
              </div>
              <div className="col-12 d-flex flex-column gap-4">
                <div className="header-4 bold">Missions courantes</div>
                {currentMissionElements}
              </div>
            </div>
          ) : progress === 100 ? (
            <div className="row justify-content-center">
              <div className="col-12 d-flex flex-row justify-content-between align-items-center mb-4 flex-wrap gap-3">
                <div className="header-3">
                  {`Bonjour ${capitalizeFirstLetter(
                    currentConsultant.user.firstName
                  )}`}
                </div>
                <Link to="/missions" className="d-none d-md-block">
                  <button className="default-btn">Trouver une mission</button>
                </Link>
              </div>
              <div className="col-12 col-md-6 d-flex flex-column align-items-center gap-4">
                <img
                  src="/dashboard.svg"
                  alt="dashboard wheelofwork logo"
                  className="w-75"
                />
                <div className="wow-subtitle bold">
                  Vous n'avez pas de mission.
                </div>
                <div>
                  <Link to="/missions">
                    <button className="default-btn">Trouver une mission</button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="row card px-4 px-md-5 p-4 gap-3 mb-4 mx-1">
              <div className="col-12 d-flex flex-row justify-content-between align-items-center mb-3 flex-wrap gap-3">
                <div className="header-3">
                  {`Bonjour ${currentConsultant.user.firstName
                    .substring(0, 1)
                    .toUpperCase()}${currentConsultant.user.firstName
                      .substring(1)
                      .toLowerCase()}`}
                </div>
              </div>
              <div className="col-12 wow-subtitle bold">
                Complétez votre profil

              </div>
              <div className="col-12 wow-body">
                Une fois votre profil complété à
                <span className="bold"> 90 %</span>, vous pourrez postuler à
                l'ensemble de nos missions.
              </div>
              <div className="col-12 d-flex flex-row align-items-center">
                <div
                  className={`progress-bar-container ${style["progress-container"]}`}
                >
                  <div
                    className="progress-bar-progress"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <div className="small-text bold mx-1 mx-md-5">
                  {progress} % complété
                </div>
              </div>

              <div className={style["accomplishment"]}>
                <HashLink className="col-10 col-md-3" to="/profile">
                  {accomplishments.generalInfos ? <FiCheck className={style["check-icon"]}></FiCheck> : <FiX className={style["x-icon"]}></FiX>}Indiquez vos informations générales
                </HashLink>
              </div>
              <div className={style["accomplishment"]}>
                <HashLink className="col-10 col-md-3" to="/profile/profil-professionnel#cv">
                  {accomplishments.resume ? <FiCheck className={style["check-icon"]}></FiCheck> : <FiX className={style["x-icon"]}></FiX>}Transmettez votre CV
                </HashLink>
              </div>
              <div className={style["accomplishment"]}>
                <HashLink className="col-10 col-md-3" to="/profile/profil-professionnel#cr" scroll={scrollWithOffset}>
                  {accomplishments.skills ? <FiCheck className={style["check-icon"]}></FiCheck> : <FiX className={style["x-icon"]}></FiX>}Ajoutez vos compétences
                </HashLink>
              </div>
              <div className={style["accomplishment"]}>
                <HashLink className="col-10 col-md-3" to="/profile/profil-professionnel#pref" scroll={scrollWithOffset}>
                  {accomplishments.missionsPreferences ? <FiCheck className={style["check-icon"]}></FiCheck> : <FiX className={style["x-icon"]}></FiX>}Renseignez vos préférences de mission
                </HashLink>
              </div>
              <div className={style["accomplishment"]}>
                <HashLink className="col-10 col-md-3" to="/profile/profil-professionnel#ra" scroll={scrollWithOffset}>
                  {accomplishments.referals ? <FiCheck className={style["check-icon"]}></FiCheck> : <FiX className={style["x-icon"]}></FiX>}Communiquez vos références
                </HashLink>
              </div>

              <div className={`${style["helperButtonContainer"]}`}>
                <Link className="col-10 col-md-3" to="/profile">
                  <button className="default-btn">Compléter mon profil</button>
                </Link>
                <span className={`${style["helperButton"]}`}><HelperButton link="https://wheelofwork.notion.site/wheelofwork/Aide-support-et-documentation-sur-l-App-d81630768dda45d18ac7146f2e4ba8a4?p=b1061bd3baa6455da0f2294575f947ff&pm=c" ></HelperButton></span>
              </div>
            </div>
          )}
        </>
      )}
      <div className="row my-5 gap-4 flex-column flex-md-row mx-1">
        <div className="col card d-flex flex-column justify-content-between gap-2 pb-4 text-center">
          <div className="w-100 d-flex flex-column gap-4">
            <div className={style["dashboard-img-container"]}>
              <img src="/illustration-1.svg" alt="trouver une mission" />
            </div>
            <div className=" header-4 font-family-tt">
              Trouvez la mission de vos rêves
            </div>
            <div className=" small-text">
              Cherchez et trouvez la mission qui vous correspondra en quelques
              secondes.
            </div>
          </div>
          <Link to="/missions">
            <button className="default-btn w-75 w-md-50 m-auto">
              Trouvez une mission
            </button>
          </Link>
        </div>
        <div className="col card d-flex flex-column justify-content-between gap-2 pb-4">
          <div className="w-100 d-flex flex-column gap-4 text-center">
            <div className={style["dashboard-img-container"]}>
              <img src="/illustration-2.svg" alt="document légaux" />
            </div>
            <div className="w-100 header-4 font-family-tt">
              Les documents requis<span className={style["helperIcon"]}><Helper content="Uniquement nécessaire quand la mission est validée"></Helper></span>

            </div>
            <div className="w-100 small-text">
              Téléchargez les documents nécessaires pour partir en mission.
            </div>
          </div>
          <Link to="/profile/documents-legaux">
            <button className="default-btn w-75 w-md-50 m-auto">
              Transmettre mes documents
            </button>
          </Link>
        </div>
        <div className="col card d-flex flex-column justify-content-between gap-2 pb-4">
          <div className="w-100 d-flex flex-column gap-4 text-center">
            <div className={style["dashboard-img-container"]}>
              <img src="/illustration-3.svg" alt="trouver une mission" />
            </div>
            <div className="w-100 header-4 font-family-tt">
              Mes préférences de missions
            </div>
            <div className="w-100 small-text">
              Renseignez vos préférences de missions pour favoriser le matching
              idéal.
            </div>
          </div>
          <Link to="/profile/preferences-missions" className="-self-end">
            <button className="default-btn w-75 w-md-50 m-auto">
              Préférences de missions
            </button>
          </Link>
        </div>
      </div>
      <div className="row bg-black card p-4 gap-4 my-5 mx-1">
        <div className="col-12 d-flex flex-column flex-md-row align-items-center gap-3">
          <div className="flex-fill d-flex flex-column gap-4">
            <div className="text-white header-4 font-family-tt">Parrainage</div>
            <div>
              <div className="text-white small-text">
                Chez WheelOfWork, nous croyons à la force du réseau !
              </div>
              <div className="text-white small-text">
                Participez à
                l'animation de notre communauté et repartez avec une prime de
                parrainage.
              </div>
            </div>
            <div className="d-flex flex-row gap-2">
              <Link to="/parrainage">
                <button className="default-btn bg-white text-black">En savoir plus</button>
              </Link>
              <a href="https://sponsorship.wheelofwork.com" target="_blank" rel="noopener noreferrer">
                <button style={{backgroundColor:"var(--blue-ny)"}} className="default-btn text-black d-flex gap-2"><span>Je sponsorise</span><FiExternalLink/></button>
              </a>
            </div>
          </div>

          <div id="test" className="d-flex flex-fill align-self-stretch flex-row gap-1">
            <div className="d-flex flex-fill align-self-stretch flex-column gap-2">
              <div className="d-flex flex-fill align-self-stretch flex-row gap-2">
                <div style={{backgroundColor:"var(--blue-ny)"}} className="rounded d-flex flex-fill justify-content-center align-items-center flex-column gap-4">            
                  <FiUsers className="header-3" />
                </div>
                <div style={{backgroundColor:"var(--orange-light-ny)"}} className="rounded d-flex flex-fill justify-content-center align-items-center flex-column gap-4">            
                  <LuRotate3D className="header-3" />
                </div>
              </div>
              <div style={{backgroundColor:"var(--green-ny)"}} className="rounded d-flex flex-fill justify-content-center align-items-center flex-column gap-4">            
                <LuHeartHandshake className="header-3" />
              </div>
            </div>
            {/* <div style={{transform:"rotate(-90deg)", maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto"}} 
            className="d-flex align-self-stretch flex-fill">
              <img
                  src="/wow-ny-logo/wow-sponsorship-logo.svg"
                  alt="WheelOfWork logo"
                  className={`text-white d-flex align-self-stretch flex-fill`}
                />
            </div> */}
          </div>
        </div>
      </div>
      <div className="row card p-4 gap-3 my-5 mx-1">
        <div className="col-12 d-flex flex-column flex-md-row align-items-center gap-3">
          <img
            src="/partenaire.svg"
            alt="partenaire"
            className={style["partenaire-img"]}
          />
          <div className="w-100 w-md-75 d-flex flex-column gap-4">
            <div className="header-4 font-family-tt">Partenariats (Bientôt disponible)</div>
            <div className="small-text">
              Découvrez les partenariats négociés par WheelOfWork auprès des
              acteurs du <i>Future Of Work</i>.<br />
              Construisez votre stack technique & administrative en testant les
              outils et en bénéficiant de codes promo.{" "}
            </div>
            {/* <a
              href={freelanceStackUrl}
              target="_blank"
              rel="noreferrer"
              style={{ width: "240px" }}
            >
              <button className="default-btn">En savoir plus</button>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
