import React, { useRef } from "react";
import { FiPaperclip } from "react-icons/fi";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import { legalFilesStatuses } from "../../../Helpers/data";
import HelperButton from "../../HelperButton";
import style from "../style.module.css";
import { getDocumentByIdAndType } from "../../../Services/User";

const ListItem = ({
  file,
  title,
  name,
  type,
  helpLink,
  onUploadFile = (f) => f,
}) => {
  const inputRef = useRef(null);

  const getDocumentHandler = (document) => {
    if (!document._id) {
      return;
    }
    getDocumentByIdAndType(document._id, type).then((res) => {
      const file = res.data;
      if (!file) {
        return;
      }
      const url = b64ToObjectUrl(file.base64Document, file.filetype);
      window.open(url, "blank");
    });
  };

  return (
    <div className={`${style["documents-table-item"]}`}>
      <div className="small-text bold">{title}</div>
      <span className={`${style["helperButton"]}`}>
        {helpLink ? (
          <HelperButton
            small="true"
            className={`${style["helperButtonInside"]}`}
            link={helpLink}
          ></HelperButton>
        ) : null}
      </span>
      <div className="small-text d-none d-md-flex">
        {file?.expirationDate &&
          new Date(file.expirationDate).toLocaleDateString()}
      </div>
      <div
        className="wow-body d-none d-md-flex cursor-pointer"
        onClick={() => getDocumentHandler(file)}
        target="_blank"
        rel="noreferrer"
      >
        <FiPaperclip style={{ transform: "rotate(-45deg)" }} />
      </div>
      {file?.expirationDate && new Date(file?.expirationDate) < new Date() ? (
        <div
          className={`${style["document-status"]} small-text`}
          status="REFUSED"
        >
          Périmé
        </div>
      ) : (
        <div
          className={`${style["document-status"]} small-text`}
          status={file?.status ?? ""}
        >
          {legalFilesStatuses[file?.status] ?? "Manquant"}
        </div>
      )}
      <button className="default-btn" onClick={(e) => inputRef.current.click()}>
        Transmettre
      </button>
      <input
        type="file"
        name={name}
        title={title}
        id={name}
        accept="image/*, application/pdf"
        hidden
        ref={inputRef}
        onChange={onUploadFile}
      />
    </div>
  );
};

export default ListItem;
