import { useReducer, useState } from "react";
import { FiCheck } from "react-icons/fi";
import CustomCheckbox from "../../../../Components/CustomCheckbox";
import { updateBankAccountDetails, updateSociety } from "../../../../Services/User";
import styles from './style.module.css';

const INVOICE_ENUM = {
    BANK: 'BANK',
    IBAN: 'IBAN',
    BIC: 'BIC',
    AUTO: 'AUTO',
    TVA: 'TVA',
    ID: 'ID',
}

const invoiceReducer = (state, payload) => {
    switch (payload.type) {
        case INVOICE_ENUM.BANK: return { ...state, bank: payload.value };
        case INVOICE_ENUM.IBAN:
            return { ...state, iban: payload.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() };
        case INVOICE_ENUM.BIC: return { ...state, bic: payload.value };
        case INVOICE_ENUM.AUTO: return { ...state, shallAutoGenerate: !state.shallAutoGenerate };
        case INVOICE_ENUM.TVA: return { ...state, isCompelledTva: !state.isCompelledTva };
        case INVOICE_ENUM.ID: return { ...state, id: payload.value };
        default: console.log('No case matching'); break;
    }
}

const initialInputState = {
    bank: { isTouched: false },
    iban: { isTouched: false },
    bic: { isTouched: false },
    shallAutoGenerate: { isTouched: false },
    isCompelledTva: { isTouched: false },
};

const bank = 'bank';
const iban = 'iban';
const bic = 'bic';

const FacturationAutomatique = ({ societyId, societyCapital, bankAccount }) => {
    const initialInvoiceState = {
        id: bankAccount ? bankAccount.id : undefined,
        bank: bankAccount ? bankAccount.bank : '',
        iban: bankAccount ? bankAccount.iban : '',
        bic: bankAccount ? bankAccount.bic : '',
        shallAutoGenerate: bankAccount ? bankAccount.shallAutoGenerate : false,
        isCompelledTva: bankAccount ? bankAccount.isCompelledTva : false
    };

    const [inputsEventState, setInputsEventState] = useState(initialInputState);

    const [invoiceState, dispatchInvoiceState] = useReducer(invoiceReducer, initialInvoiceState);

    const checkboxConfig1 = {
        value: invoiceState.shallAutoGenerate,
        checked: invoiceState.shallAutoGenerate,
        name: INVOICE_ENUM.AUTO,
        onChange: () => {
            onSubmitHandler(null, 'shallAutoGenerate', !invoiceState.shallAutoGenerate);
            dispatchInvoiceState({ type: INVOICE_ENUM.AUTO });
        },
        label: 'Je souhaite générer automatiquement ma facturation',
        readOnly: false
    };

    const checkboxConfig2 = {
        value: invoiceState.isCompelledTva,
        checked: invoiceState.isCompelledTva,
        name: INVOICE_ENUM.TVA,
        onChange: () => {
            onSubmitHandler(null, 'isCompelledTva', !invoiceState.isCompelledTva);
            dispatchInvoiceState({ type: INVOICE_ENUM.TVA });
        },
        label: 'Je suis soumis à la T.V.A',
        readOnly: false
    };

    const onChangeBankHandler = (event) => {
        dispatchInvoiceState({ type: INVOICE_ENUM.BANK, value: event.target.value });
    };
    const onChangeBicHandler = (event) => {
        dispatchInvoiceState({ type: INVOICE_ENUM.BIC, value: event.target.value })
    };
    const onChangeIbanHandler = (event) => {
        dispatchInvoiceState({ type: INVOICE_ENUM.IBAN, value: event.target.value })
    };

    const onChangeCapitalHandler = (event) => {
        const capital = event.target.value;
        updateSociety(societyId, { capital }).then();
    }


    const setTouchedInput = (property) => {
        setInputsEventState((oldInputsState) => {
            oldInputsState[property].isTouched = true;
            return { ...oldInputsState };
        });
    }

    const resetTouchedInput = (property) => {
        setInputsEventState((oldState) => {
            oldState[property].isTouched = false;
            return { ...oldState }
        })
    }

    const onSubmitHandler = (event, property, extraValue) => {
        const invoiceForm = { ...invoiceState };
        if (event) {
            event.preventDefault();
        }

        if (extraValue === undefined && property && !invoiceState[property].trim().length) {
            return;
        }

        if (extraValue === false || extraValue === true) {
            invoiceForm[property] = extraValue;
        }

        updateBankAccountDetails(invoiceForm, societyId).then((res) => {
            setTouchedInput(property);
            if (!invoiceState.id) {
                dispatchInvoiceState({ type: INVOICE_ENUM.ID, value: res.data.id })
            };
        });
    }

    return (<form>
        <h3 className="wow-subtitle bold">Facturation</h3>
        <div className="mt-4 mb-3">
            <label className="bold green">Cochez ces cases dans le cas où :</label>
            <CustomCheckbox  {...checkboxConfig1} classes="my-3" />
            <CustomCheckbox {...checkboxConfig2} />
        </div>
        <div className="flex d-flex align-items-center">
            <label className="bold">Capital de l'entreprise (en €)</label>
            <input className={`default-input ${styles['max-width']}`} type="number" min={0} onBlur={onChangeCapitalHandler} defaultValue={societyCapital} max={999999}/>
        </div>
        <div className="mt-5" style={{ border: "1px solid var(--green-ny)", borderRadius: "1em", padding: "12px", boxShadow: "0 8px 24px rgba(24, 39, 75, 0.08),0 6px 12px rgba(24, 39, 75, 0.30)" }}>
            <div className="wow-subtitle bold mb-3 green-ny">Mon relevé d'identité bancaire</div>
            <div className="w-50 m-auto">
                <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label className="small-text bold mb-2 cursor-pointer" htmlFor={bank}>
                        Banque
                    </label>
                    <div className="d-flex">
                        <input id={bank} name={bank} className={`default-input ${styles['input']}`} type="text" value={invoiceState.bank}
                            onFocus={() => { resetTouchedInput(bank) }}
                            onChange={onChangeBankHandler}
                            onBlur={(event) => onSubmitHandler(event, bank)} />
                        {inputsEventState.bank.isTouched && <FiCheck color="green" className={`${styles['ficheck']}`}></FiCheck>}
                    </div>

                </div>

                <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label className="small-text bold mb-2 cursor-pointer" htmlFor={bic}>
                        BIC
                    </label>
                    <div className="d-flex">
                        <input id={bic} name={bic} className={`default-input ${styles['input']}`} type="text" value={invoiceState.bic}
                            onFocus={() => { resetTouchedInput(bic) }}
                            onChange={onChangeBicHandler}
                            onBlur={(event) => onSubmitHandler(event, bic)} />
                        {inputsEventState.bic.isTouched && <FiCheck color="green" className={`${styles['ficheck']}`}></FiCheck>}
                    </div>
                </div>

                <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label className="small-text bold mb-2 cursor-pointer" htmlFor={iban}>
                        IBAN
                    </label>
                    <div className="d-flex">
                        <input id={iban} name={iban} className={`default-input ${styles['input']}`} type="text" value={invoiceState.iban}
                            onFocus={() => { resetTouchedInput(iban) }}
                            onChange={onChangeIbanHandler}
                            onBlur={(event) => onSubmitHandler(event, iban)} />
                        {inputsEventState.iban.isTouched && <FiCheck color="green" className={`${styles['ficheck']}`}></FiCheck>}
                    </div>
                </div>
            </div>
        </div>
    </form>)
}

export default FacturationAutomatique;