import React from 'react'
import { FiCheck } from 'react-icons/fi'
import styles from './style.module.css'  // Assurez-vous d'avoir un fichier CSS module

const CustomCheckbox = ({
  value, name, onChange, label, readOnly, checked, classes
}) => {
  let classNames = "d-flex flex-row align-items-center";
  if (classes) {
    classNames = `${classes} ${classNames}`;
  }

  const handleChange = (e) => {    
    if (onChange) {
      onChange({
        target: {
          name: name,
          checked: !checked,
          value: value
        }
      });
    }
  };

  return (
    <div className={classNames}>
      <div 
        className={`${styles.customCheckbox} ${checked ? styles.checked : ''}`}
        onClick={handleChange}
      >
        {checked && <FiCheck className={styles.checkIcon} />}
      </div>
      <label className="small-text" onClick={handleChange}>
        {label}
      </label>
    </div>
  );
}

export default CustomCheckbox