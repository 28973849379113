import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FiUpload, FiUser } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Helper from "../../../Components/Helper";
import HelperButton from "../../../Components/HelperButton";
import Loader from "../../../Components/Loader";
import MobileNavigation from "../../../Components/Profil/MobileNavigation";
import { displayConsultantPictureWithConnectionMethod as getUserPictureWithConnectionMethod } from "../../../utils";
import toast from "./../../../Helpers/toast";
import {
  addProfilePicture,
  editGeneralInformationsProfile,
} from "./../../../Services/User";
import style from "./style.module.css";

const InformationsGenerales = () => {
  const AccountSchema = Yup.object({
    user: Yup.object({
      phoneNumber: Yup.string().matches(
        /^(?:(?:\+|00)(33|32)[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/g,
        "Votre numéro de téléphone doit être composé de 10 chiffres minimum et 12 chiffres maximum"
      ),
    }),
  });

  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [data, setData] = useState({});
  const uploadPic = useRef(null);

  const updateProgress = (payload) =>
    dispatch({ type: "UPDATE_PROGRESS", payload });

  useEffect(() => {
    if (currentConsultant) {
      const user = { ...currentConsultant.user };
      if (currentConsultant.profilePicture) {
        setImage(getUserPictureWithConnectionMethod(currentConsultant));
      }
      setData(user);
      setLoading(false);
    }
  }, [currentConsultant]);

  const onUploadPic = (e) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    addProfilePicture(formdata, currentConsultant.id).then((res) => {
      const image = (
        <img src={URL.createObjectURL(e.target.files[0])} alt="wow profile" />
      );
      setImage(image);
      toast("success", "Photo ajoutée avec succès !");
    });
  };

  const formik = useFormik({
    validationSchema: AccountSchema,
    enableReinitialize: true,
    initialValues: {
      user: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        birthDate: "",
        gender: "",
        linkedInUri: "",
        address: {
          ...data.address,
          street: "",
          postalCode: "",
          city: "",
        },
        ...data,
      },
    },
    onSubmit: (values) => {
      const state = { ...values };
      delete state.user.email;
      editGeneralInformationsProfile(currentConsultant.id, state)
        .then((res) => {
          updateProgress(res.data);
          toast("success", "Sauvegardé");
        })
        .catch((e) =>
          toast(
            "error",
            "Une erreur est survenue, veuillez vous rapprocher du support."
          )
        );
    },
  });
  return (
    <form
      className="card col-12 col-md p-4 p-md-5 d-flex flex-column gap-4 position-relative"
      onSubmit={formik.handleSubmit}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <MobileNavigation />
          <div className="w-100 d-flex flex-row align-items-center">
            <div className={style["profile-pic-container"]}>
              {image ?? <FiUser className="header-1" />}
            </div>
            <input
              id="img"
              type="file"
              hidden
              accept=".png, .jpg, jpeg"
              ref={uploadPic}
              onChange={onUploadPic}
            />
            <div>
              <button
                type="button"
                className="default-btn blue-btn"
                onClick={(e) => uploadPic.current.click()}
              >
                Transmettre une photo
                <FiUpload className="mx-1 mx-md-2" />
              </button>
            </div>
          </div>
          <div></div>
          <div className="wow-subtitle bold">
            Informations générales
            <Helper content="Remplissez toutes les informations pour augmenter le score de votre profil et pouvoir postuler aux missions"></Helper>
          </div>

          <div className="w-100 d-flex flex-column flex-md-row gap-4 gap-md-5 mb-0 mb-md-4">
            <div className="w-100 d-flex flex-column">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="user.firstName"
              >
                Prénom
              </label>

              <input
                id="user.firstName"
                name="user.firstName"
                value={formik.values.user.firstName}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder=""
              />
            </div>
            <div className="w-100 d-flex flex-column">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="user.lastName"
              >
                Nom
              </label>
              <input
                id="user.lastName"
                name="user.lastName"
                value={formik.values.user.lastName}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder=""
              />
            </div>
          </div>

          <div className="w-100 d-flex flex-column">
            <div className="small-text bold mb-2 cursor-pointer">Sexe</div>
            <div className="w-100 d-flex flex-column flex-md-row gap-4 gap-md-5">
              <div className="d-flex flex-row align-items-center">
                <input
                  type="radio"
                  id="Male"
                  name="user.gender"
                  value="Male"
                  checked={formik.values.user.gender === "Male"}
                  onChange={formik.handleChange}
                  hidden
                />
                <label className="custom-radio" htmlFor="Male" />
                <label
                  htmlFor="Male"
                  className="small-text mx-2 cursor-pointer"
                >
                  Homme
                </label>
              </div>
              <div className="d-flex flex-row align-items-center">
                <input
                  type="radio"
                  id="Female"
                  name="user.gender"
                  value="Female"
                  checked={formik.values.user.gender === "Female"}
                  onChange={formik.handleChange}
                  hidden
                />
                <label className="custom-radio" htmlFor="Female" />
                <label
                  htmlFor="Female"
                  className="small-text mx-2 cursor-pointer"
                >
                  Femme
                </label>
              </div>
              <div className="d-flex flex-row align-items-center">
                <input
                  type="radio"
                  id="Other"
                  name="user.gender"
                  value="Other"
                  checked={formik.values.user.gender === "Other"}
                  onChange={formik.handleChange}
                  hidden
                />
                <label className="custom-radio" htmlFor="Other" />
                <label
                  htmlFor="Other"
                  className="small-text mx-2 cursor-pointer"
                >
                  Ne se prononce pas
                </label>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column">
            <label
              className="small-text bold mb-2 cursor-pointer"
              htmlFor="user.birthDate"
            >
              Date de naissance
            </label>
            <input
              id="user.birthDate"
              name="user.birthDate"
              value={formik.values.user.birthDate?.split("T")[0]}
              onChange={formik.handleChange}
              className="default-input"
              type="date"
              max={`${new Date().getFullYear()}-${
                new Date().getMonth() > 8
                  ? new Date().getMonth() + 1
                  : "0" + (new Date().getMonth() + 1)
              }-${
                new Date().getDate() > 9
                  ? new Date().getDate()
                  : "0" + new Date().getDate()
              }`}
            />
          </div>
          <div className="w-100 d-flex flex-column">
            <label
              className="small-text bold mb-2 cursor-pointer"
              htmlFor="user.address"
            >
              Adresse personnelle
            </label>
            <div className="w-100 d-flex flex-column flex-md-row gap-2">
              <input
                id="user.address.street"
                name="user.address.street"
                value={formik.values.user.address?.street}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder="Adresse"
              />
              <input
                id="user.address.postalCode"
                name="user.address.postalCode"
                value={formik.values.user.address?.postalCode}
                onChange={formik.handleChange}
                className="default-input"
                type="number"
                placeholder="Code Postal"
              />
              <input
                id="user.address.city"
                name="user.address.city"
                value={formik.values.user.address?.city}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder="Ville"
              />
            </div>
          </div>
          <div className="w-100 d-flex flex-column">
            <label
              className="small-text bold mb-2 cursor-pointer"
              htmlFor="user.phoneNumber"
            >
              Numéro de téléphone
            </label>
            <input
              id="user.phoneNumber"
              name="user.phoneNumber"
              value={formik.values.user.phoneNumber}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              placeholder=""
            />
            {formik.errors.user && formik.errors.user.phoneNumber ? (
              <div className="small-text red-text mt-1">
                {formik.errors.user.phoneNumber}
              </div>
            ) : null}
          </div>
          <div className="w-100 d-flex flex-column">
            <label
              className="small-text bold mb-2 cursor-pointer"
              htmlFor="user.email"
            >
              Email
            </label>
            <input
              id="user.email"
              name="user.email"
              value={formik.values.user.email}
              onChange={formik.handleChange}
              disabled
              className="default-input"
              type="text"
              placeholder=""
            />
          </div>
          <div className="w-100 d-flex flex-column">
            <label
              className="small-text bold mb-2 cursor-pointer"
              htmlFor="user.linkedInUri"
            >
              URL LinkedIn
            </label>
            <input
              id="user.linkedInUri"
              name="user.linkedInUri"
              value={formik.values.user.linkedInUri}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              placeholder=""
            />
          </div>
          <div className="w-100 d-flex flex-column">
            <label className="small-text bold mb-2 cursor-pointer">
              Mot de passe
            </label>
            <input
              className="default-input"
              type="password"
              disabled
              value="00000000000000000"
            />
            {/* <Link
              to="/auth/update-password"
              className="small-text green-text bold my-2"
            >
              Réinitialiser mon mot de passe
            </Link> */}
          </div>
          <div className={`${style["helperButtonContainer"]} mx-auto my-4`}>
            <button type="submit" className="default-btn">
              Sauvegarder
            </button>
            <span className={`${style["helperButton"]}`}>
              <HelperButton
                className={style["helperButton"]}
                link="https://wheelofwork.notion.site/Comment-compl-ter-mon-profil-b1061bd3baa6455da0f2294575f947ff"
              ></HelperButton>
            </span>
          </div>
        </>
      )}
    </form>
  );
};

export default InformationsGenerales;
