import React, { useState } from "react";
import { useEffect } from "react";
import { FiCheckCircle, FiHeart, FiShare2 } from "react-icons/fi";
import MissionCard from "../../Components/Mission";
import Postuler from "../../Components/Mission/Postuler";
import style from "./style.module.css";
import {
  LikeOrDislikeMission,
  GetMission,
  GetMissionSuggestion,
  PostulerMission,
} from "./../../Services/Missions";
import { useHistory, useRouteMatch } from "react-router";
import Loader from "./../../Components/Loader/index";
import toast from "./../../Helpers/toast";
import { useSelector } from "react-redux";
import {
  WorkLocation,
  WorkRythmn,
} from "../../Helpers/enum-translations";
import { b64ToObjectUrl } from "../../Helpers/base64-decode";
import HelperButton from "../../Components/HelperButton";

const MissionPage = () => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const match = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [like, setLike] = useState(false);

  const [mission, setMission] = useState(null);
  const [missions, setMissions] = useState([]);

  useEffect(() => {
    if (currentConsultant) {
      GetMission(match.params.id, currentConsultant.id)
        .then((res) => {
          setMission(res.data);
          setLike(!!res.data.favorite);
          GetMissionSuggestion(currentConsultant.id).then((res) => {
            setMissions(res.data);
            setLoading(false);
          });
        })
        .catch((e) => {
          history.push("/missions");
        });
    }
  }, [match, history, currentConsultant]);

  const handleCandidateButton = () => {
    if (currentConsultant.accomplishmentPercent < 90) {
      toast("error", "Vous n'avez pas complété totalement votre profil");
    } else {
      setOpen(true);
    }
  };

  const handleChangeLike = (missionUpdated) => {
    const updatedMissions = [
      ...missions.filter((mission) => mission.id !== missionUpdated.id),
      missionUpdated,
    ];
    const sortedMissions = updatedMissions.sort((m1, m2) =>
      m1.label.localeCompare(m2.label)
    );

    setMissions(sortedMissions);
  };

  const onLikeOrDislike = (e) => {
    let payload = {
      mission: { id: mission.id },
      consultant: { id: currentConsultant.id },
    };
    if (mission.favorite) {
      payload = { ...payload, id: mission.favorite.id };
    }
    LikeOrDislikeMission(payload).then((res) => {
      setLike(!like);
    });
  };

  const logo = mission?.society?.logo ?? mission?.logo;
  return (
    <div className={style["mission-page"] + " container-lg position-relative"}>
      {loading ? (
        <div
          className="w-100 position-relative card"
          style={{ height: "300px" }}
        >
          <Loader />
        </div>
      ) : (
        mission && (
          <>
            {open && (
              <Postuler
                missionName={mission.label}
                show={open}
                onClose={(e) => setOpen(false)}
                onSubmit={() => {
                  PostulerMission({
                    mission: { id: match.params.id },
                    consultant: { id: currentConsultant.id },
                  })
                    .then((res) => {
                      setOpen(false);
                      toast("success", "Candidature sauvegardée");
                    })
                    .catch(() => {
                      setOpen(false);
                      toast("error", "Vous avez déjà postulé à cette mission");
                    });
                }}
              />
            )}
            {/* Header */}
            <div
              className={`${style["mission-header"]} row card mx-1 align-items-center`}
            >
              <div className={style["cover-img"]}>
                {mission?.society && (
                  <img
                  src={`/sector/${mission.society?.sector?.label?.toLowerCase()}.png`}
                  alt="cover mission"
                />
                )}
              </div>
              <div className="col-12 col-md-10 d-flex flex-column gap-3 z-1">
                <div className={style["entreprise-logo"] + " mx-auto mx-md-0"}>
                  {logo && !mission.isConfidential ? (
                    <img
                      src={b64ToObjectUrl(logo.base64Document, logo.filetype)}
                      alt="society img"
                    />
                  ) : (
                    <img src={"/wow-ny-logo/wow-circle-white.svg"} alt="wheel pf work logo"
                         style={{backgroundColor: 'black',border: '4px solid black',borderRadius: '5%'}} />
                  )}
                </div>
                <div className="wow-body">
                  {mission.isConfidential
                    ? "Entreprise confidentielle"
                    : mission.society?.label}
                </div>
                <div className="w-100 d-flex flex-row align-items-start gap-3">
                  <div className="header-4 w-100">{mission.label}</div>
                  <div className="d-none d-md-flex flex-row align-items-center gap-3 w-25">
                    <FiHeart
                      className={`${style["like"]} header-2 cursor-pointer ${
                        like ? "liked" : ""
                      }`}
                      onClick={onLikeOrDislike}
                    />
                    <button
                      className="default-btn"
                      onClick={handleCandidateButton}
                    >
                      Postuler
                    </button>
                    <span className={`${style["helperButton"]}`}><HelperButton link="https://wheelofwork.notion.site/wheelofwork/Aide-support-et-documentation-sur-l-App-d81630768dda45d18ac7146f2e4ba8a4?p=b1061bd3baa6455da0f2294575f947ff&pm=c" ></HelperButton></span>
             
                  </div>
                </div>
              </div>
              <FiHeart
                className={`${style["like"]} ${
                  style["mission-like"]
                } d-flex d-md-none header-2 cursor-pointer ${
                  like ? "liked" : ""
                }`}
                onClick={onLikeOrDislike}
              />
            </div>

            {/* Caractéristiques */}
            <div className="row gap-3 justify-content-center mt-4 mx-1">
              <div className="card col-10 col-md d-flex flex-column p-4 gap-2">
                <div className="wow-subtitle bold">Durée</div>
                <div className="small-text">{mission.monthsDuration} mois</div>
              </div>
              <div className="card col-10 col-md d-flex flex-column p-4 gap-2">
                <div className="wow-subtitle bold">Localisation</div>
                <div className="small-text">{mission.address.city}</div>
              </div>
              <div className="card col-10 col-md d-flex flex-column p-4 gap-2">
                <div className="wow-subtitle bold">Date début</div>
                <div className="small-text">
                  {new Date(mission.startDate).toLocaleDateString()}
                </div>
              </div>
              <div className="card col-10 col-md d-flex flex-column p-4 gap-2">
                <div className="wow-subtitle bold">Type de contrat</div>
                <div className="small-text">
                  {WorkRythmn[mission.workRythmn]}
                </div>
              </div>
              <div className="card col-10 col-md d-flex flex-column p-4 gap-2">
                <div className="wow-subtitle bold">Sur site / A distance</div>
                <div className="small-text">
                  {WorkLocation[mission.workLocation]}
                </div>
              </div>
            </div>

            <div className="row justify-content-center gap-3 mx-1 my-5">
              {/* Description */}
              <div className="col-12 col-md card d-flex flex-column p-4 p-md-5 gap-3">
                {mission.skills.length > 0 && (
                  <div className="wow-subtitle bold">Compétences requises</div>
                )}
                <div className="d-flex flex-row w-100 flex-wrap gap-3">
                  {mission.skills?.map((skill, i) => {
                    return (
                      <p key={i} className="small-text blue-text">
                        {skill.label}
                      </p>
                    );
                  })}
                </div>
                <div className="wow-subtitle bold">Description</div>
                <div className="small-text">
                  <pre className="mission-description">
                    {mission.description}
                  </pre>
                </div>
                {/* <p className='small-text see-more bold'>Voir Plus</p> */}
                <div className="d-none d-md-flex w-50">
                  <button
                    className="default-btn"
                    onClick={handleCandidateButton}
                  >
                    Postuler
                  </button>
                </div>
              </div>

              {/* Tasks */}
              <div className="col-12 col-md-5 card d-flex flex-column p-4 gap-4">
                <p className="wow-subtitle bold">Les points clés</p>
                {mission.tasks.map((task, i) => {
                  return (
                    <p
                      key={i}
                      className="d-flex flex-row"
                    >
                      <span className="fixedSvg">
                        <FiCheckCircle className="green-text" />
                      </span>
                      <span className="mx-2">{task}</span>
                    </p>
                  );
                })}
                {/* <p className='small-text see-more bold'>Voir Plus</p> */}
              </div>
            </div>

            {/* Autres Commandes */}
            <div className="row justify-content-center mx-1">
              {missions.length > 0 && (
                <div className="col-12 col-md-10 mb-5 header-4">
                  Ne manquez pas ces autres annonces
                </div>
              )}
              <div className="col-12 d-flex flex-column gap-4">
                {missions.map((ms, i) => {
                  return (
                    <MissionCard
                      key={i}
                      mission={ms}
                      onChangeLike={handleChangeLike}
                    />
                  );
                })}
              </div>
            </div>

            <div
              className={
                "card d-flex flex-row justify-content-center align-items-center  d-md-none gap-4 " +
                style["options-mobile"]
              }
            >
              <FiShare2 className="header-3" />
              <button
                className="w-50 default-btn "
                onClick={handleCandidateButton}
              >
                Postuler
              </button>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default MissionPage;
