import React from "react";
import { FiClock, FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import { durations } from "../../../Helpers/data";
import style from "./style.module.css";

const Candidature = ({ mission, status }) => {
  const logo = mission?.society?.logo ?? mission?.logo;
  return (
    <Link className={style["mission-link"]} to={`/mission/${mission.id}`}>
      <div className="card w-100 d-flex flex-column flex-md-row align-items-center px-3 px-md-0 py-4 gap-3 gap-md-0">
        <div className={style["candidature-logo"] + " mx-5"}>
          <img
            src={
              logo
                ? b64ToObjectUrl(logo.base64Document, logo.filetype)
                : "/wow-ny-logo/wow-circle-white.svg"
            }
            alt="candidature img"
          />
        </div>
        <div className="w-100 d-flex flex-column flex-md-row align-items-center align-items-md-start px-0 px-md-3 gap-3 gap-md-0">
          <div className="w-100 d-flex flex-column gap-3">
            <div className="d-flex flex-column-reverse flex-md-column gap-1 gap-md-2">
              <div className="wow-subtitle bold">{mission.label}</div>
            </div>
            <div className="w-100 d-flex flex-row flex-md-column gap-3 justify-content-between">
              <div className="w-50 w-md-100 d-flex flex-column">
                <div className="wow-body bold d-flex d-md-none">Durée</div>
                <div className="small-text black-text d-flex align-items-center">
                  <FiClock className="d-none d-md-flex mx-1" />
                  {durations[mission.duration]}
                </div>
              </div>
              <div className="w-50 w-md-100 d-flex flex-column">
                <div className="wow-body bold d-flex d-md-none">
                  Localisation
                </div>
                <div className="small-text black-text d-flex align-items-center">
                  <FiMapPin className="d-none d-md-flex mx-1" />
                  {mission.address.city}
                </div>
              </div>
            </div>
          </div>
          <div className={style["candidature-status"]} status={status}></div>
        </div>
      </div>
    </Link>
  );
};

export default Candidature;
