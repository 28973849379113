import React, { useCallback, useEffect, useState } from "react";
import { editProfileFiles } from "../../../Services/User";
import style from "../style.module.css";
import toast from "./../../../Helpers/toast";
import ListItem from "./../ListItem/index";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../Redux/User/actions";

const AutoEntrepreneurTable = () => {
  const dispatch = useDispatch();
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );

  const [files, setFiles] = useState([]);
  const updateConsultantDocuments = useCallback(
    (documents) => {
      dispatch({
        type: userActions.SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS,
        payload: documents,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentConsultant) {
      setFiles(
        currentConsultant.documents?.find((doc) => doc.type === "legal_files")
          .documents
      );
    }
  }, [currentConsultant]);

  const onUploadFile = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    const title = e.target.title;

    if (file) {
      const formdata = new FormData();
      formdata.append("file", file);
      editProfileFiles(formdata, "legal_files", {
        societyId: currentConsultant.society.id,
        consultantId: currentConsultant.id,
        status: "PENDING",
        legalFileType: name,
        filelabel: title,
      }).then((res) => {
        updateConsultantDocuments(res.data);
        toast("success", "Fichier ajouté avec succès !");
      });
    }
  };

  const RIB = files.find((doc) => doc.type === "bank_account_informations");
  const URSSAFF = files.find((doc) => doc.type === "urssaf");
  const ASSURANCE_RESPO_CIVILE = files.find(
    (doc) => doc.type === "liability_insurance"
  );
  const INSEE = files.find((doc) => doc.type === "insee");
  return (
    <div className={style["documents-table"]}>
      <div
        className={`${style["documents-table-item"]} ${style["documents-table-header"]}`}
      >
        <div className="small-text">Type Fichier</div>
        <div className="small-text"></div>
        <div className="small-text d-none d-md-flex">Date d'expiration</div>
        <div className="small-text d-none d-md-flex">Fichier</div>
        <div className="small-text">Statut</div>
      </div>
      <ListItem
        title="RIB"
        type="legal_files"
        name="bank_account_informations"
        file={RIB}
        onUploadFile={onUploadFile}
      />
      <ListItem
        title="Attestation de vigilance URSSAF"
        type="legal_files"
        helpLink="https://wheelofwork.notion.site/wheelofwork/Aide-support-et-documentation-sur-l-App-d81630768dda45d18ac7146f2e4ba8a4?p=56decab5c93440d0b2f9b54df2483369&pm=c"
        name="urssaf"
        file={URSSAFF}
        onUploadFile={onUploadFile}
      />
      <ListItem
        title="Attestation de souscription à une assurance de responsabilité civile professionnelle"
        name="liability_insurance"
        type="legal_files"
        file={ASSURANCE_RESPO_CIVILE}
        onUploadFile={onUploadFile}
      />
      <ListItem
        type={"legal_files"}
        title="Avis de situation SIRENE"
        helpLink="https://wheelofwork.notion.site/wheelofwork/Aide-support-et-documentation-sur-l-App-d81630768dda45d18ac7146f2e4ba8a4?p=2b386a72504045fd92167949259ccf77&pm=c"
        name="insee"
        file={INSEE}
        onUploadFile={onUploadFile}
      />
    </div>
  );
};

export default AutoEntrepreneurTable;
