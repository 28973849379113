import { useFormik } from "formik";
import React from "react";
import { updateConsultantAvailability } from "./../../../../Services/User";

const Indisponibilité = ({ consultantId, onClose, setDispo }) => {
  const formik = useFormik({
    initialValues: {
      indisponibilité: new Date(new Date().getTime() + 30 * 86400000)
        .toISOString()
        .split("T")[0],
      date: new Date(new Date().getTime() + 86400000)
        .toISOString()
        .split("T")[0],
    },
    onSubmit: (values) => {
      updateConsultantAvailability(consultantId, {
        isAvailable: false,
        unavailabilityTerm:
          values.indisponibilité === "date"
            ? values.date
            : values.indisponibilité,
      }).then((res) => {
        setDispo(res.data);
        onClose();
      });
    },
  });
  return (
    <form className="d-flex flex-column gap-3" onSubmit={formik.handleSubmit}>
      <div className="wow-subtitle bold">Gérer votre indisponibilité</div>
      <div className="d-flex flex-row align-items-center">
        <input
          type="radio"
          id="1mois"
          name="indisponibilité"
          value={`${
            new Date(new Date().getTime() + 30 * 86400000)
              .toISOString()
              .split("T")[0]
          }`}
          checked={
            formik.values.indisponibilité ===
            `${
              new Date(new Date().getTime() + 30 * 86400000)
                .toISOString()
                .split("T")[0]
            }`
          }
          onChange={formik.handleChange}
          hidden
        />
        <label className="custom-radio" htmlFor="1mois" />
        <label htmlFor="1mois" className="small-text mx-3 cursor-pointer">
          1 mois
        </label>
      </div>
      <div className="d-flex flex-row align-items-center">
        <input
          type="radio"
          id="3mois"
          name="indisponibilité"
          value={`${`${
            new Date(new Date().getTime() + 90 * 86400000)
              .toISOString()
              .split("T")[0]
          }`}`}
          checked={
            formik.values.indisponibilité ===
            `${`${
              new Date(new Date().getTime() + 90 * 86400000)
                .toISOString()
                .split("T")[0]
            }`}`
          }
          onChange={formik.handleChange}
          hidden
        />
        <label className="custom-radio" htmlFor="3mois" />
        <label htmlFor="3mois" className="small-text mx-3 cursor-pointer">
          3 mois
        </label>
      </div>
      <div className="d-flex flex-row align-items-center">
        <input
          type="radio"
          id="6mois"
          name="indisponibilité"
          value={`${`${
            new Date(new Date().getTime() + 180 * 86400000)
              .toISOString()
              .split("T")[0]
          }`}`}
          checked={
            formik.values.indisponibilité ===
            `${`${
              new Date(new Date().getTime() + 180 * 86400000)
                .toISOString()
                .split("T")[0]
            }`}`
          }
          onChange={formik.handleChange}
          hidden
        />
        <label className="custom-radio" htmlFor="6mois" />
        <label htmlFor="6mois" className="small-text mx-3 cursor-pointer">
          6 mois
        </label>
      </div>
      <div className="d-flex flex-row align-items-center gap-4">
        <div className="d-flex flex-row align-items-center">
          <input
            type="radio"
            id="date"
            name="indisponibilité"
            value="date"
            checked={formik.values.indisponibilité === "date"}
            onChange={formik.handleChange}
            hidden
          />
          <label className="custom-radio" htmlFor="date" />
          <label htmlFor="date" className="small-text mx-3 cursor-pointer">
            Jusqu'au
          </label>
        </div>
        <input
          type="date"
          min={
            new Date(new Date().getTime() + 86400000)
              .toISOString()
              .split("T")[0]
          }
          name="date"
          value={formik.values.date}
          onChange={formik.handleChange}
          disabled={formik.values.indisponibilité !== "date"}
          className="default-input"
        />
      </div>
      <div className="w-100 d-flex felx-row gap-4">
        <button type="button" className="default-btn danger-btn">
          Annuler
        </button>
        <button type="submit" className="default-btn">
          Valider
        </button>
      </div>
    </form>
  );
};

export default Indisponibilité;
