import { ProtectedApi } from "./../Helpers/api";

const PREFIX = "/sign-up";

export const getJobsByCriteria = async (search) => {
  return ProtectedApi.get(`${PREFIX}/jobs/search`, {
    params: { q: search },
  });
};

export const getSpokenLanguages = () => {
  return ProtectedApi.get(`${PREFIX}/languages`);
};

export const getLocalization = (location) => {
  if (location.length < 3) {
    return null;
  }
  return ProtectedApi.get(`${PREFIX}/locate`, { params: { location } });
};
