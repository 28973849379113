import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { StepCounter } from "../StepCounter";

import { useState } from "react";
import { SIGN_UP_ACTIONS } from "../../../../../Redux/SignUp/actions";
import styles from "../style.module.css";
import { LanguageForm } from "./LanguageForm";
import { SkillForm } from "./SkillForm";

export const ProcessStep3 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const signUpStepper = useSelector((state) => state.signUpReducer);

  const [selectedSkills, setSelectedSkills] = useState(signUpStepper.skills);
  const [selectedLanguages, setSelectedLanguages] = useState(
    signUpStepper.languages
  );

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const values = { skills: selectedSkills, languages: selectedLanguages };
    dispatch({ type: SIGN_UP_ACTIONS.STEP_3, payload: values });
    history.push("step-4");
  };

  return (
    <form className={styles["container"]} onSubmit={onSubmitHandler}>
      <SkillForm
        selectedSkills={selectedSkills}
        setSelectedSkills={setSelectedSkills}
      />
      <LanguageForm
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
      />
      <div>
        {selectedLanguages?.length > 0 && selectedSkills?.length > 2 && (
          <div className="text-center mb-5">
            <button type="submit" className="sign-btn">
              Suivant
            </button>
          </div>
        )}
        <StepCounter index={3}></StepCounter>
      </div>
    </form>
  );
};
