import { useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SIGN_UP_ACTIONS } from "../../../../../Redux/SignUp/actions";
import { StepCounter } from "../StepCounter";
import styles from "../style.module.css";

export const ProcessStep5 = () => {
  const maxDate = new Date();
  maxDate.setFullYear(new Date().getFullYear() + 3);

  const dispatch = useDispatch();
  const history = useHistory();
  const signUpStepper = useSelector((state) => state.signUpReducer);

  const [averageSale, setAverageSale] = useState(
    signUpStepper.averageSale ?? 0
  );
  const [availability, setAvailability] = useState(
    signUpStepper.availability ?? ""
  );

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const errors = Object.keys(signUpStepper).filter(
      (key) => !signUpStepper[key]
    );

    if (errors?.length > 0) {
      alert(
        "Votre profil n'a pas été complété correctement, veuillez-le vérifier à nouveau"
      );
      return;
    }

    const values = { averageSale, availability };
    dispatch({ type: SIGN_UP_ACTIONS.STEP_5, payload: values });
    history.push("final");
  };

  const onChangeAvailabilityHandler = (e) => {
    const availibility = new Date(e.target.value);
    if (availibility > maxDate) {
      setAvailability(`${maxDate.getFullYear()}-01-01`);
      return;
    }
    setAvailability(e.target.value);
  };

  const incrementAverageSale = () => {
    setAverageSale((oldAverage) => oldAverage + 10);
  };

  const decrementAverageSale = () => {
    setAverageSale((oldAverage) => {
      if (oldAverage - 10 <= 0) {
        return 0;
      }
      return oldAverage - 10;
    });
  };

  const onChangeAverage = (e) => {
    setAverageSale(+e.target.value);
  };

  return (
    <form className={styles["container"]} onSubmit={onSubmitHandler}>
      <div></div>
      <div>
        <section>
          <label htmlFor="averageSale">
            Quel est votre taux journalier moyen (TJM) ?
          </label>
          <div className="flex d-flex m-auto align-items-center">
            <button
              type="button"
              className={styles["average-sale-button"]}
              onClick={decrementAverageSale}
            >
              <FiMinus color="white" />
            </button>
            <input
              className={styles["average-sale-input"]}
              id="averageSale"
              name="averageSale"
              type="number"
              placeholder="€"
              value={averageSale.toString()}
              onChange={onChangeAverage}
            />
            <button
              type="button"
              className={styles["average-sale-button"]}
              onClick={incrementAverageSale}
            >
              <FiPlus color="white" />
            </button>
          </div>
        </section>
      </div>
      <div>
        <section>
          <label htmlFor="availability">
            Quand pouvez-vous commencer votre nouvelle mission avec nous ?
          </label>
          <input
            id="availability"
            name="availability"
            type="date"
            value={availability}
            max={`${maxDate.getFullYear()}-01-01`}
            onChange={onChangeAvailabilityHandler}
          />
        </section>
      </div>
      <div>
        {averageSale && availability && (
          <div className="text-center mb-5">
            <button type="submit" className="sign-btn">
              Valider
            </button>
          </div>
        )}
        <StepCounter index={5}></StepCounter>
      </div>
    </form>
  );
};
