import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="col-12 col-md-10 d-flex flex-row gap-3 gap-md-5">
      <NavLink
        exact
        to="/candidatures"
        className="small-text"
        activeClassName="green-text"
      >
        Toutes
      </NavLink>
      <NavLink
        exact
        to="/candidatures/en-attente"
        className="small-text"
        activeClassName="green-text"
      >
        A l'étude
      </NavLink>
      <NavLink
        exact
        to="/candidatures/approuvées"
        className="small-text"
        activeClassName="green-text"
      >
        Retenue(s)
      </NavLink>
      <NavLink
        exact
        to="/candidatures/refusées"
        className="small-text"
        activeClassName="green-text"
      >
        Non retenue(s)
      </NavLink>
    </div>
  );
};

export default Navbar;
