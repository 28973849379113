import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import Candidature from "../../Components/Candidatures/Candidature";
import Navbar from "./../../Components/Candidatures/Navbar/index";
import Loader from "./../../Components/Loader/index";
import { GetCandidature } from "./../../Services/Candidature";

const Candidatures = () => {
  const consultantId = useSelector((state) => state.userReducer.consultantId);
  const match = useRouteMatch();

  const [loading, setLoading] = useState(false);

  const [candidatures, setCandidatures] = useState([]);
  useEffect(() => {
    setLoading(true);
    const type = match.params.type;
    const typePayload =
      type === "en-attente"
        ? "En attente"
        : type === "approuvées"
        ? "Approuvées"
        : type === "refusées"
        ? "Refusées"
        : null;
    GetCandidature(typePayload, consultantId)
      .then((res) => {
        setCandidatures(res.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [match]);
  return (
    <div className="container-lg">
      <div className="row justify-content-center align-items-center mx-1">
        <div className="col-12 col-md-11 header-4 mb-4 mb-md-5 font-family-tt">
          Mes candidatures
        </div>
        <Navbar />
        <div className="col-12 d-flex flex-column gap-5 my-5">
          {loading ? (
            <div
              className="w-100 card position-relative"
              style={{ height: "300px" }}
            >
              <Loader />
            </div>
          ) : candidatures.length > 0 ? (
            <>
              {candidatures.map((c, i) => (
                <Candidature key={i} mission={c.mission} status={c.status} />
              ))}
            </>
          ) : (
            <div className="w-100 card p-5 wow-body text-center">
              Aucune candidature
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Candidatures;
