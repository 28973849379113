import React from 'react'
import style from './style.module.css'
import Slider from './../../Components/AuthLayout/Slider/index';

const AuthLayout = ({ children }) => {
    const routeToWowSite = () => {
        window.location.replace('https://www.wheelofwork.com');
    }

    return <div className={`${style['auth-layout']}`}>
        <div className={`${style['left-content']}`}>
            <img
                src="/wow-ny-logo/wow-white-inline.svg"
                onClick={routeToWowSite}
                alt="WheelOfWork logo"
                className={`${style['wow-site']}`}
            />
            {children}
        </div>
        <div className={`${style['right-content']}`}>
            <Slider />
        </div>
    </div>
}

export default AuthLayout