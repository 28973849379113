import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import CustomCheckbox from "../../../Components/CustomCheckbox";
import MobileNavigation from "../../../Components/Profil/MobileNavigation";
import {
  GetConsultantPreferencesParams,
  editUserMissionPreferences,
} from "./../../../Services/User";
import Loader from "./../../../Components/Loader/index";
import toast from "./../../../Helpers/toast";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Helper from "../../../Components/Helper";

const PreferencesMissions = ({ history }) => {
  const dispatch = useDispatch();
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const updateProgress = (payload) =>
    dispatch({ type: "UPDATE_PROGRESS", payload });

  const [loading, setLoading] = useState();
  const [params, setParams] = useState({
    companySizes: [],
    companyTypes: [],
    workRythmns: [],
  });
  const [preferences, setPref] = useState({
    dailyRateAverage: 0,
    duration: "",
    city: "",
    mobility: "",
    companySizes: [],
    companyTypes: [],
    workRythmns: [],
  });
  useEffect(() => {
    setLoading(true);
    if (currentConsultant) {
      const { missionPreferences } = currentConsultant;
      GetConsultantPreferencesParams().then((res) => {
        setParams(res.data);
      });
      if (missionPreferences) {
        setPref((prevState) => ({
          ...prevState,
          dailyRateAverage: missionPreferences.dailyRateAverage,
          duration: missionPreferences.duration,
          city: missionPreferences.city,
          mobility: missionPreferences.mobility,
          companySizes: missionPreferences.companySizes,
          workRythmns: missionPreferences.workRythmns,
          companyTypes: missionPreferences.companyTypes,
        }));
      }
      setLoading(false);
    }
  }, [currentConsultant]);
  const durees = [
    {
      value: (
        <>
          Courte
          <br />
          {"<6 mois"}
        </>
      ),
      duration: "SHORT",
    },
    {
      value: (
        <>
          Moyenne
          <br />
          {"entre 6 et 12 mois"}
        </>
      ),
      duration: "MEAN",
    },
    {
      value: (
        <>
          Longue
          <br />
          {">12 mois"}
        </>
      ),
      duration: "LONG",
    },
  ];

  const handleCompanySizeCheckbox = (e) => {
    const value = e.target.id;
    setPref((prevState) => {
      let prevCompanySizes = prevState.companySizes ?? [];
      if (prevCompanySizes.includes(value)) {
        prevCompanySizes = prevCompanySizes.filter((cs) => cs !== value);
      } else {
        prevCompanySizes = [...prevCompanySizes, value];
      }
      return {
        ...prevState,
        companySizes: prevCompanySizes,
      };
    });
  };

  const handleCompanyTypeCheckbox = (e) => {
    const value = e.target.value;
    setPref((prevState) => {
      let prevCompanyTypes = prevState.companyTypes ?? [];
      if (prevCompanyTypes.find((prevCompany) => prevCompany.id === value)) {
        prevCompanyTypes = prevCompanyTypes.filter((cs) => cs.id !== value);
      } else {
        prevCompanyTypes = [...prevCompanyTypes, { id: value }];
      }
      return {
        ...prevState,
        companyTypes: prevCompanyTypes,
      };
    });
  };

  const handleWorkRythmnsCheckbox = (e) => {
    const value = e.target.id;
    setPref((prevState) => {
      let prevWorkRythmns = prevState.workRythmns ?? [];
      if (prevWorkRythmns.includes(value)) {
        prevWorkRythmns = prevWorkRythmns.filter((cs) => cs !== value);
      } else {
        prevWorkRythmns = [...prevWorkRythmns, value];
      }
      return {
        ...prevState,
        workRythmns: prevWorkRythmns,
      };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    editUserMissionPreferences(currentConsultant.id, {
      id: currentConsultant.id,
      missionPreferences: {
        ...preferences,
      },
    }).then((res) => {
      updateProgress(res.data);
      toast("success", "Sauvegardé");
    });
  };

  return (
    <form
      className="card col-12 col-md p-4 p-md-5 d-flex flex-column gap-5 position-relative"
      onSubmit={onSubmit}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <MobileNavigation />
          <div className="w-100 d-flex flex-column gap-3">
            <div className="header-4 bold">
              Gérer mes préférences de missions <Helper content="Renseignez toutes les informations pouvoir augmenter votre score et ainsi, postuler à nos missions"></Helper>
            </div>
            <div className="wow-body">
              Quels sont vos critères dans la recherche de missions ?
            </div>
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="wow-subtitle bold">Durée de la mission</div>
            <div className="d-flex flex-row flex-wrap gap-3 justify-content-center">
              {durees.map((value, i) => {
                return (
                  <div key={i}>
                    <button
                      type="button"
                      className={`default-btn ${
                        value.duration === preferences.duration
                          ? ""
                          : "primary-btn"
                      }`}
                      style={{ width: "260px" }}
                      onClick={(e) =>
                        setPref({ ...preferences, duration: value.duration })
                      }
                    >
                      {value.value}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-100 d-flex flex-row justify-content-between gap-3 align-items-center">
            <div className="wow-subtitle bold">
              Taux journalier souhaité (€)
            </div>
            <input
              type="number"
              name="dailyRateAverage"
              className="default-input w-50"
              onChange={(e) =>
                setPref({ ...preferences, dailyRateAverage: e.target.value })
              }
              placeholder="Entrer votre taux journalier en euros"
              value={preferences.dailyRateAverage}
            />
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <div className="wow-subtitle bold">Lieu de travail</div>
            <div className="wow-body bold">Ville de résidence</div>
            <input
              type="text"
              name="city"
              value={preferences.city}
              onChange={(e) =>
                setPref({ ...preferences, city: e.target.value })
              }
              className="default-input mb-3"
              placeholder="Entrez une ville"
            />
            <div className="wow-body bold">Mobilité</div>
            <select
              type="text"
              className="default-input"
              value={preferences.mobility}
              onChange={(e) =>
                setPref({ ...preferences, mobility: e.target.value })
              }
            >
              <option></option>
              <option value="CITY">Ville</option>
              <option value="DEPARTMENT">Région</option>
              <option value="COUNTRY">Pays</option>
              <option value="INTERNATIONAL">International</option>
              <option value="TELETRAVAIL">Télétravail</option>
            </select>
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="wow-subtitle bold">Secteur(s)</div>
            <div className="d-flex flex-row flex-wrap gap-4">
              {params.companyTypes.map((ct, i) => {
                const isChecked = !!preferences.companyTypes.find(
                  (companyType) => companyType.id === ct.id
                );
                return (
                  <div key={i} className={style["checkbox-container"]}>
                    <CustomCheckbox
                      value={ct.id}
                      name={ct.label}
                      label={ct.label}
                      checked={isChecked}
                      onChange={handleCompanyTypeCheckbox}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="wow-subtitle bold">Taille d'entreprises</div>
            <div className="d-flex flex-row flex-wrap gap-4">
              {params.companySizes.map((cs, i) => {
                return (
                  <div key={i} className={style["checkbox-container"]}>
                    <CustomCheckbox
                      id={cs[0]}
                      name={cs[0]}
                      label={cs[1]}
                      checked={preferences.companySizes.includes(cs[0])}
                      onChange={handleCompanySizeCheckbox}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="wow-subtitle bold">Rythme de travail souhaité</div>
            <div className="d-flex flex-row flex-wrap gap-4">
              {params.workRythmns.map((wr, i) => {
                return (
                  <div key={i} className={style["checkbox-container"]}>
                    <CustomCheckbox
                      id={wr[0]}
                      name={wr[0]}
                      label={wr[1]}
                      checked={preferences.workRythmns.includes(wr[0])}
                      onChange={handleWorkRythmnsCheckbox}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mx-auto my-3">
            <button type="submit" className="default-btn">
              Sauvegarder
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default withRouter(PreferencesMissions);
