import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./router";
import store from "./Redux/index";
// import Maintenance from "./Pages/Maintenance";

ReactDOM.render(
  <Provider store={store}>
    {/* <Maintenance/> */}
    <Router />
    <ToastContainer
      position="top-right"
      autoClose={6000}
      hideProgressBar={true}
      newestOnTop={false}
      rtl={false}
      limit={2}
      closeButton={true}
    />
  </Provider>,
  document.getElementById("root")
);
