import { ProtectedApi, ProtectedFilesApi } from "./../Helpers/api";

export const getNavbarData = async () => {
  return ProtectedApi.get("/consultant?q=navbar");
};

export const addProfilePicture = (file, id) => {
  return ProtectedFilesApi.post(`/consultant/upload/logo/${id}`, file);
};

export const editGeneralInformationsProfile = async (id, payload) => {
  return ProtectedApi.patch(`/consultant/generalInfos/${id}`, {
    ...payload,
  });
};

export const editProfilePro = async (id, payload) => {
  return ProtectedApi.patch(`/consultant/profile/${id}`, {
    ...payload,
  });
};

export const deleteDocument = async (id, type) => {
  return ProtectedApi.delete(
    `/consultant-document/file?fileId=${id}&type=${type}`
  );
};

export const updateAccomplishment = async (id) => {
  return ProtectedApi.patch(`/consultant/accomplishment/${id}`);
};

export const updateConsultantAvailability = async (id, payload) => {
  return ProtectedApi.patch(`/consultant/unavailability/${id}`, { ...payload });
};

export const editProfileFiles = async (formData, type, args) => {
  const urlArgs = Object.keys(args)
    .map((key) => `&${key}=${args[key]}`)
    .join("");
  const url = `/consultant-document/files/type?type=${type}${urlArgs}`;
  return ProtectedFilesApi.post(url, formData);
};

export const editProfileLegalDocuments = async (id, payload) => {
  return ProtectedApi.patch(`/consultant/society/${id}`, {
    ...payload,
  });
};

export const editUserPreferences = async (id, payload) => {
  return ProtectedApi.patch(`/consultant/preferences/${id}`, {
    ...payload,
  });
};

export const editUserMissionPreferences = async (id, payload) => {
  return ProtectedApi.patch(`/consultant/missionPreferences/${id}`, {
    ...payload,
  });
};

export const editConsultantSkillMarks = async (id, payload) => {
  return ProtectedApi.patch(`/consultant/consultantSkillMarks/${id}`, {
    ...payload,
  });
};

export const getConsultant = (id) => {
  return ProtectedApi.get(`/consultant/${id}`);
};

export const deleteUser = async (payload) => {
  return ProtectedApi.delete(`/consultant/${payload.id}`, {
    userId: payload.user.id,
  });
};

export const GetConsultantPreferencesParams = () =>
  ProtectedApi.get("/consultant/preferences/params");

export const updateBankAccountDetails = (bankAccountDetails, societyId) => {
  const url = `/society/${societyId}/bank-account-details`;
  return ProtectedApi.patch(url, bankAccountDetails);
};

export const updateSociety = (societyId, societyFields) => {
  const url = `/society/${societyId}`;
  return ProtectedApi.patch(url, { ...societyFields, id: societyId });
};

export const getDocumentByIdAndType = (id, type) => {
  const url = `/consultant-document/files/documents/${id}/${type}`;
  return ProtectedApi.get(url);
};
