import { useFormik } from "formik";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import toast from "./../../../Helpers/toast";
import { forgetPassword } from "./../../../Services/Auth";
import style from "./style.module.css";

const ForgotPassword = () => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      forgetPassword(values).then((res) => {
        toast("success", "Email de mise à jour envoyé avec succès");
        history.push("/auth/sign-in");
      });
    },
  });

  return (
    <form
      className={`${style["forgot-password"]}`}
      onSubmit={formik.handleSubmit}
    >
      <div></div>
      <div>
        <div className={`${style["white-color"]} header-4`}>
          On dirait que vous avez oublié votre mot de passe. Récupérez-le !
        </div>
        <input
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          type="text"
          required={true}
          placeholder="Email"
          autoComplete="off"
        />
        <button
          type="submit"
          style={{ backgroundColor: "white", color: "black" }}
          className="default-btn"
        >
          Continuer
        </button>
        <div className={`mt-4 small-text ${style["white-color"]}`}>
          Je n'ai pas de compte -
          <Link
            to="/auth/sign-up"
            className={`green-text mx-1 ${style["white-color"]}`}
          >
            créer un compte
          </Link>
        </div>
      </div>
      <div></div>
    </form>
  );
};

export default ForgotPassword;
