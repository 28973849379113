import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { StepCounter } from "../StepCounter";

import { useState } from "react";
import { FiMinusCircle } from "react-icons/fi";
import { SuggestiveResults } from "../../../../../Components/SuggestiveResults";
import { useSuggestions } from "../../../../../Hooks/useSuggestions";
import { SIGN_UP_ACTIONS } from "../../../../../Redux/SignUp/actions";
import { getLocalization } from "../../../../../Services/SignStep";
import styles from "../style.module.css";

const preferencesLabel = ["En télétravail", "Hybride"];

export const ProcessStep4 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const signUpStepper = useSelector((state) => state.signUpReducer);

  const [addresses, setAddresses] = useState(signUpStepper.addresses);
  const [preference, setPreference] = useState(signUpStepper.preference);

  const { suggestions, displaySuggestions, onChangeSearch, onClickSuggestion } =
    useSuggestions({ callFunction: getLocalization });

  const selectLocationHandler = (location) => {
    onClickSuggestion();
    if (!addresses.includes(location)) {
      setAddresses((oldLocations) => [...oldLocations, location]);
    }
  };

  const onClickPreferenceHandler = (index) => {
    setPreference(preferencesLabel[index]);
  };

  const onClickLocationHandler = (index) => {
    const newLocations = [...addresses];
    newLocations.splice(index, 1);
    setAddresses(newLocations);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const values = { addresses, preference };
    dispatch({ type: SIGN_UP_ACTIONS.STEP_4, payload: values });
    history.push("step-5");
  };

  return (
    <form className={styles["container"]} onSubmit={onSubmitHandler}>
      <div></div>
      <div>
        <section>
          <label htmlFor="address">D'où travaillez-vous ?</label>
          <input
            id="address"
            name="address"
            placeholder="Search..."
            onChange={onChangeSearch}
          />
          {displaySuggestions && suggestions && (
            <SuggestiveResults
              results={suggestions}
              onClick={selectLocationHandler}
            ></SuggestiveResults>
          )}
          <div className="flex d-flex flex-wrap">
            {(addresses ?? []).map((address, index) => (
              <button
                type="button"
                key={index}
                className={styles["pinButton"]}
                onClick={() => onClickLocationHandler(index)}
              >
                <FiMinusCircle />
                <span>{address}</span>
              </button>
            ))}
          </div>
        </section>
      </div>
      <div>
        <section>
          <label>Comment préférez-vous travailler ?</label>
          <div className="m-auto w-75 flex d-flex justify-content-between">
            {preferencesLabel.map((label, index) => (
              <button
                type="button"
                style={{
                  width: "30%",
                  backgroundColor:
                    label === preference ? "var(--blue-ny)" : "white",
                  color: label === preference ? "white" : "black",
                }}
                className="sign-btn"
                key={index}
                onClick={() => onClickPreferenceHandler(index)}
              >
                {label}
              </button>
            ))}
          </div>
        </section>
      </div>
      <div></div>
      <div>
        {addresses?.length > 0 && preference && (
          <div className="text-center mb-5">
            <button type="submit" className="sign-btn">
              Suivant
            </button>
          </div>
        )}
        <StepCounter index={4}></StepCounter>
      </div>
    </form>
  );
};
