import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SuggestiveResults } from "../../../../../Components/SuggestiveResults";
import { useSuggestions } from "../../../../../Hooks/useSuggestions";
import { SIGN_UP_ACTIONS } from "../../../../../Redux/SignUp/actions";
import { getJobsByCriteria } from "../../../../../Services/SignStep";
import { StepCounter } from "../StepCounter";
import styles from "../style.module.css";

const experienceLevels = [
  { level: "Junior", description: "0-2 ans" },
  { level: "Confirmé", description: "3-7 ans" },
  { level: "Senior", description: "8-15 ans" },
  { level: "Expert", description: "15 ans et +" },
];

export const ProcessStep2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    search,
    suggestions,
    displaySuggestions,
    setDisplaySuggestions,
    onChangeSearch,
    onClickSuggestion,
  } = useSuggestions({ callFunction: getJobsByCriteria });

  const signUpStepper = useSelector((state) => state.signUpReducer);

  const [selectedLevel, setSelectedLevel] = useState(
    signUpStepper.job?.level ?? ""
  );
  const [selectedSuggestion, setSelectedSuggestion] = useState(
    signUpStepper.job ?? ""
  );

  const onClickJobExperienceHandler = (level) => {
    setSelectedLevel(level);
  };

  const selectSuggestionHandler = (e) => {
    setDisplaySuggestions((oldState) => !oldState);
    setSelectedSuggestion(e);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const form = {
      id: selectedSuggestion.id,
      label: selectedSuggestion.label,
      level: selectedLevel,
    };
    dispatch({ type: SIGN_UP_ACTIONS.STEP_2, payload: form });
    history.push("step-3");
  };

  return (
    <form className={`${styles["container"]}`} onSubmit={onSubmitHandler}>
      <div></div>
      <div>
        <section>
          <label htmlFor="job">Quel est votre métier ?</label>
          <input
            id="job"
            name="job"
            required={true}
            placeholder="Votre métier"
            value={
              selectedSuggestion?.label ? selectedSuggestion.label : search
            }
            onChange={(e) => {
              onChangeSearch(e);
            }}
            onClick={() => {
              onClickSuggestion();
              setSelectedSuggestion(null);
            }}
          />
          {displaySuggestions && suggestions?.length > 0 && (
            <SuggestiveResults
              results={suggestions}
              onClick={selectSuggestionHandler}
            ></SuggestiveResults>
          )}
        </section>
        <section>
          <label>Quelle est votre expérience dans ce métier ?</label>
          <div className="m-auto w-100 flex d-flex flex-wrap justify-content-between">
            {experienceLevels.map((exp, index) => (
              <button
                type="button"
                style={{
                  width: "100px",
                  fontSize: "O.70em",
                  backgroundColor:
                    exp.level === selectedLevel ? "var(--blue-ny)" : "white",
                  color: exp.level === selectedLevel ? "white" : "black",
                }}
                className="sign-btn"
                key={index}
                onClick={() => onClickJobExperienceHandler(exp.level)}
              >
                {exp.level}
                <br /> {exp.description}
              </button>
            ))}
          </div>
        </section>
      </div>
      <div>
        {selectedLevel !== "" && selectedSuggestion && (
          <div className="text-center mb-5">
            <button type="submit" className="sign-btn">
              Suivant
            </button>
          </div>
        )}
        <StepCounter index={2}></StepCounter>
      </div>
    </form>
  );
};
