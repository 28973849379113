import { FiMinusCircle } from "react-icons/fi";

import styles from "./style.module.css";

export const SignUpButtonSelection = ({
  label,
  onClick,
  options,
  onClickOptions,
  index,
  level,
}) => {
  const onClickSkillHandler = () => {
    onClick();
  };

  const onChangeOptionHandler = (event) => {
    onClickOptions(index, event.target.value);
  };

  return (
    <div className={`${styles["container"]}`}>
      <button type="button" onClick={onClickSkillHandler}>
        <FiMinusCircle />
        {label}
      </button>
      <select onChange={onChangeOptionHandler} value={level}>
        {options?.map((opt, index) => (
          <option key={index}>{opt}</option>
        ))}
      </select>
    </div>
  );
};
