import { ProtectedApi } from "./../Helpers/api";

export const GetReferences = async (id) => {
  return ProtectedApi.get(`/reference/consultant/${id}`);
};
export const AddConsultantReference = async (payload, consultantId) => {
  return ProtectedApi.post(
    "/reference",
    { ...payload },
    { params: { consultantId } }
  );
};
export const DeleteReference = async (referenceId) => {
  return ProtectedApi.delete("/reference/" + referenceId);
};
