import { SuggestiveResults } from "../../../../../../Components/SuggestiveResults";
import { useSuggestions } from "../../../../../../Hooks/useSuggestions";
import { getSkills } from "../../../../../../Services/Skills";
import { SignUpButtonSelection } from "./SignUpButtonSelection";

export const SkillForm = ({ selectedSkills, setSelectedSkills }) => {
  const levels = ["Débutant", "Intermediaire", "Expert"];

  const { suggestions, displaySuggestions, onChangeSearch, onClickSuggestion } =
    useSuggestions({ callFunction: getSkills });

  const onDeleteSkill = (index) => {
    const newSkills = [...selectedSkills];
    newSkills.splice(index, 1);
    setSelectedSkills(newSkills);
  };

  const selectSkillHandler = (newSkill) => {
    onClickSuggestion(newSkill);
    if (!selectedSkills.find(({ skill }) => skill.id === newSkill.id)) {
      setSelectedSkills((existingSkills) => [
        ...existingSkills,
        { skill: newSkill, level: "Débutant" },
      ]);
    }
  };

  const onChangeSkillLvlHandler = (index, str) => {
    const skills = [...selectedSkills];
    skills[index].level = str;
    setSelectedSkills(skills);
  };

  return (
    <div>
      <section>
        <label htmlFor="skill">
          Quelles sont vos compétences ? (<span style={{textColor: "red", color: "red"}}>3 compétences obligatoires</span>)
        </label>
        <input
          id="skill"
          name="skill"
          placeholder="Search..."
          onChange={onChangeSearch}
        />
        {displaySuggestions && suggestions && (
          <SuggestiveResults
            results={suggestions}
            onClick={selectSkillHandler}
          ></SuggestiveResults>
        )}
        {(selectedSkills ?? []).map(
          ({ skill, level: selectedLevel }, index) => (
            <SignUpButtonSelection
              key={index}
              index={index}
              label={skill.label}
              onClick={() => onDeleteSkill(index)}
              options={levels}
              onClickOptions={onChangeSkillLvlHandler}
              level={selectedLevel}
            />
          )
        )}
      </section>
    </div>
  );
};
