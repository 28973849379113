import React, { useEffect, useRef, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useSelector } from "react-redux";
import AutoEntrepreneurTable from "../../../Components/DocumentLegauxTables/AutoEntrepreneur";
import SocietyTable from "../../../Components/DocumentLegauxTables/Societe";
import InformationsEntreprise from "../../../Components/InformationsEntreprise";
import MobileNavigation from "../../../Components/Profil/MobileNavigation";
import StatusPopup from "../../../Components/StatusPopup";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import { legalFilesStatuses } from "../../../Helpers/data";
import toast from "./../../../Helpers/toast";
import {
  editProfileFiles,
  getDocumentByIdAndType,
} from "./../../../Services/User";
import style from "./style.module.css";

const DocumentsLegaux = () => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );

  const ID_FRONTRef = useRef(null);
  const ID_BACKRef = useRef(null);
  const [ID_FRONT, setID_FRONT] = useState(null);
  const [idFrontB64, setIdFrontB64] = useState(null);
  const [ID_BACK, setID_BACK] = useState(null);
  const [idBackB64, setIdBackB64] = useState(null);
  const [status, setStatus] = useState("");
  const [statusPopup, setStatusPopup] = useState(false);

  useEffect(() => {
    if (currentConsultant) {
      const { society } = currentConsultant;
      if (society?.juridicStructure) {
        setStatus(society?.juridicStructure);
      }
      const idCardDocuments = currentConsultant.documents?.find(
        (doc) => doc.type === "id_card"
      )?.documents;

      if (idCardDocuments) {
        const front = idCardDocuments.find(
          (element) => element.type === "front"
        );
        const back = idCardDocuments.find((element) => element.type === "back");
        setID_FRONT(front);
        setID_BACK(back);
      }
    }
  }, [currentConsultant]);

  useEffect(() => {
    if (ID_BACK && !ID_BACK.base64Document) {
      getDocumentByIdAndType(ID_BACK._id, "id_card").then((res) =>
        setIdBackB64(res.data)
      );
    }
    if (ID_FRONT && !ID_FRONT.base64Document) {
      getDocumentByIdAndType(ID_FRONT._id, "id_card").then((res) =>
        setIdFrontB64(res.data)
      );
    }
  }, [ID_BACK, ID_FRONT]);

  const uploadID_Front = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formdata = new FormData();
      formdata.append("file", file);
      editProfileFiles(formdata, "id_card", {
        consultantId: currentConsultant.id,
        status: "PENDING",
        side: "front",
      }).then((res) => {
        const frontDoc = res.data.find((doc) => doc.type === "front");
        setID_FRONT(frontDoc ?? null);
        toast("success", "Fichier ajouté avec succès !");
      }); 
    }
  };
  const uploadID_Back = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formdata = new FormData();
      formdata.append("file", file);
      editProfileFiles(formdata, "id_card", {
        consultantId: currentConsultant.id,
        status: "PENDING",
        side: "back",
      }).then((res) => {
        const backDoc = res.data.find((doc) => doc.type === "back");
        setID_BACK(backDoc ?? null);
        toast("success", "Fichier ajouté avec succès !");
      });
    }
  };

  let requiredDocuments;
  if (!statusPopup) {
    if (status === "self_employed") {
      requiredDocuments = <AutoEntrepreneurTable />;
    }
    if (status === "society") {
      requiredDocuments = <SocietyTable />;
    }
  }

  return (
    <div className="card col-12 col-md p-4 p-md-5 d-flex flex-column gap-5">
      {statusPopup && (
        <StatusPopup
          show={statusPopup}
          status={status}
          onClose={(status) => {
            setStatus(typeof status === "string" ? status : "");
            setStatusPopup(false);
          }}
        />
      )}
      <MobileNavigation />
      <div className="wow-subtitle">Votre statut : </div>
      <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-start gap-3">
        <div className={style["type-consultant"]}>
          <div
            className={`${style["type-consultant-img-container"]} ${
              status === "self_employed" ? style["chosen"] : ""
            }`}
            onClick={(e) => {
              if (!status) {
                setStatus("self_employed");
                setStatusPopup(true);
              }
            }}
          >
            <img src="/freelancer.svg" alt="freelancer" />
          </div>
          <div className="wow-body bold">Auto-entrepreneur</div>
        </div>
        <div className={style["type-consultant"]}>
          <div
            className={`${style["type-consultant-img-container"]} ${
              status === "society" ? style["chosen"] : ""
            }`}
            onClick={(e) => {
              if (!status) {
                setStatus("society");
                setStatusPopup(true);
              }
            }}
          >
            <img src="/societe.svg" alt="societe" />
          </div>
          <div className="wow-body bold text-center">
            Société : SAS, EURL, SARL ou autres
          </div>
        </div>
        <div className={style["type-consultant"]}>
          <div
            className={`${style["type-consultant-img-container"]} ${
              status === "wage_porting" ? style["chosen"] : ""
            }`}
            onClick={(e) => {
              if (!status) {
                setStatus("wage_porting");
                setStatusPopup(true);
              }
            }}
          >
            <img src="/consultant.svg" alt="consultant" />
          </div>
          <div className="wow-body bold">Portage salarial</div>
        </div>
      </div>
      <div className="small-text">
        <a
          target="blank"
          className={style["link-skill"]}
          href="https://wheelofwork.notion.site/Comment-faire-si-ma-soci-t-est-en-cours-d-immatriculation-7203380c60dd4d4daaf7ef067c521e74"
        >
          Comment faire si ma société est en cours d'immatriculation ?
        </a>
      </div>
      <div className={`${style["link-skill-2-container"]} small-text`}>
        <a
          target="blank"
          className={style["link-skill-2"]}
          href="https://wheelofwork.notion.site/Comment-changer-de-forme-juridique-b8485e2869594b21b05353eaa3cfa63f"
        >
          Comment modifier mon statut d'entreprise ?
        </a>
      </div>

      {currentConsultant && currentConsultant.society && (
        <InformationsEntreprise society={currentConsultant.society} />
      )}
      <div className="w-100 d-flex flex-column gap-3">
        <div className="wow-subtitle bold">Ma pièce d'identité</div>
        <div className="w-100 d-flex flex-column flex-md-row gap-5">
          <input
            type="file"
            name="ID_FRONT"
            id="ID_FRONT"
            accept="image/*"
            ref={ID_FRONTRef}
            hidden
            onChange={uploadID_Front}
          />
          <input
            type="file"
            name="ID_BACK"
            id="ID_BACK"
            accept="image/*"
            ref={ID_BACKRef}
            hidden
            onChange={uploadID_Back}
          />
          <div className="w-100 d-flex flex-column align-items-center gap-3">
            <div
              className={`${style["upload-container"]} ${
                !ID_FRONT ? style["upload-container-mask"] : ""
              }`}
              onClick={(e) => ID_FRONTRef.current.click()}
            >
              {idFrontB64 && idFrontB64.base64Document ? (
                <img
                  className={style["id-img"]}
                  src={b64ToObjectUrl(
                    idFrontB64.base64Document,
                    idFrontB64.filetype
                  )}
                  alt="id recto"
                />
              ) : (
                <>
                  <FiUpload className="header-1 green-text" />
                  <div className="wow-subtitle green-text bold">
                    Téléchargez ou déposez la copie du recto de votre carte
                    d'identité
                  </div>
                  <div className="wow-body green-text">
                    Fichiers supportés PNG, JPG, JPEG
                  </div>
                </>
              )}
            </div>
            <div className="wow-subtitle bold">Recto</div>
            {ID_FRONT && (
              <div
                className={`${style["document-status"]} small-text`}
                status={ID_FRONT.status}
              >
                {legalFilesStatuses[ID_FRONT.status]}
              </div>
            )}
          </div>
          <div className="w-100 d-flex flex-column align-items-center gap-3">
            <div
              className={`${style["upload-container"]} ${
                !ID_BACK ? style["upload-container-mask"] : ""
              }`}
              onClick={(e) => ID_BACKRef.current.click()}
            >
              {idBackB64 && idBackB64.base64Document ? (
                <img
                  className={style["id-img"]}
                  src={b64ToObjectUrl(
                    idBackB64.base64Document,
                    idBackB64.filetype
                  )}
                  alt="id verso"
                />
              ) : (
                <>
                  <FiUpload className="header-1 green-text" />
                  <div className="wow-subtitle green-text bold">
                    Téléchargez ou déposez la copie du verso de votre carte
                    d'identité
                  </div>
                  <div className="wow-body green-text">
                    Fichiers supportés PNG, JPG, JPEG
                  </div>
                </>
              )}
            </div>
            <div className="wow-subtitle bold">Verso</div>
            {ID_BACK && (
              <div
                className={`${style["document-status"]} small-text`}
                status={ID_BACK.status}
              >
                {legalFilesStatuses[ID_BACK.status]}
              </div>
            )}
          </div>
        </div>
      </div>
      {status && status !== "wage_porting" && !statusPopup && (
        <div className="wow-subtitle bold">
          Liste des documents dont nous avons besoin
        </div>
      )}
      {requiredDocuments}
    </div>
  );
};

export default DocumentsLegaux;
