import React, { useState } from "react";
import { FiChevronRight, FiHeart } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { b64ToObjectUrl } from "../../Helpers/base64-decode";
import { LikeOrDislikeMission } from "../../Services/Missions";
import style from "./style.module.css";

const Mission = ({ mission, favoriteId, onChangeLike, isFavorite }) => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [like, setLike] = useState(isFavorite || !!mission.favorite);

  const onLikeOrDislike = (e) => {
    let payload = {
      mission: { id: mission.id },
      consultant: { id: currentConsultant.id },
    };
    if (mission.favorite || favoriteId) {
      payload = { ...payload, id: mission.favorite?.id || favoriteId };
    }
    LikeOrDislikeMission(payload).then((res) => {
      setLike(!like);
      onChangeLike(res.data);
    });
  };

  const logo = mission?.society?.logo ?? mission?.logo;

  return (
    <div
      className={style["mission-item"] + " card w-100 d-flex flex-column gap-3"}
    >
      {mission && (
        <>
          {/* <div className={style['matching']}>70% matching</div> */}
          <FiHeart
            className={`${style["like"]} ${like ? "liked" : ""}`}
            onClick={onLikeOrDislike}
          />
          <div className="d-flex flex-column flex-sm-row gap-2 gap-sm-5 align-items-center mt-2">
            <div className={style["img-container"]}>
              {logo && !mission.isConfidential ? (
                <img
                  src={b64ToObjectUrl(logo.base64Document, logo.filetype)}
                  alt="candidature img"
                />
              ) : (
                <img src={"/wow-ny-logo/wow-circle-white.svg"} alt="wheel pf work logo" 
                style={{backgroundColor: 'black',border: '4px solid black',borderRadius: '50%'}}/>
              )}
            </div>
            <div className="w-100 d-flex flex-column gap-3">
              <div className="small-text black-text">
                {mission.isConfidential
                  ? "Entreprise confidentielle"
                  : mission.society?.label}
              </div>
              <div className="header-4">{mission.label}</div>
              <div className="d-flex flex-row w-100">
                <div className="w-50 d-flex flex-column">
                  <div className="wow-body bold">Durée</div>
                  <div className="small-text black-text">
                    {mission.monthsDuration} mois
                  </div>
                </div>
                <div className="w-50">
                  <div className="wow-body bold">Localisation</div>
                  <div className="small-text black-text">
                    {mission.address?.city}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-100 d-flex flex-row justify-content-between align-items-center pt-2"
            style={{ borderTop: "1px var(--grey-2) solid" }}
          >
            <div className="d-flex flex-row gap-2">
              {/* {mission.skills?.map((skill, i) => {
                return i < 3 ? (
                  <p key={i} className="small-text blue-text m-0">
                    {skill.label}
                  </p>
                ) : null;
              })}
              {mission.skills?.length > 3 && (
                <p className="small-text m-0">
                  +{mission.skills.length - 3} compétences
                </p>
              )} */}
            </div>
            <Link to={`/mission/${mission.id}`}>
              <button className="default-btn d-none d-md-flex">
                Voir plus
                <FiChevronRight className="mx-1" />
              </button>
            </Link>
          </div>
          <Link
            className={style["mission-link"]}
            to={`/mission/${mission.id}`}
          ></Link>
        </>
      )}
    </div>
  );
};

export default Mission;
