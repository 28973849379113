import React, { useEffect } from "react";
import Navbar from "../../Components/AppLayout/Navbar";
import style from "./style.module.css";
import { withRouter } from "react-router";

const AppLayout = ({ children, location }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);
  return (
    <>
      <Navbar />
      <div className={style["app-layout-content"]}>{children}</div>
    </>
  );
};

export default withRouter(AppLayout);
