export const userActions = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  SET_CURRENT_CONSULTANT: "SET_CURRENT_CONSULTANT",
  UPDATE_PROGRESS: "UPDATE_PROGRESS",
  SET_CURRENT_CONSULTANT_AVAILIBITY: "SET_CURRENT_CONSULTANT_AVAILIBITY",
  SET_CURRENT_CONSULTANT_SOCIETY: "SET_CURRENT_CONSULTANT_SOCIETY",
  SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS:
    "SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS",
  SET_CURRENT_CONSULTANT_NOTIFICATIONS: "SET_CURRENT_CONSULTANT_NOTIFICATIONS",
};
