import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { register } from "../../../../../Services/Auth";
import styles from "../style.module.css";

export const FinalStep = () => {
  const history = useHistory();
  const signUpStepper = useSelector((state) => state.signUpReducer);

  const errors = Object.keys(signUpStepper).filter(
    (key) => !signUpStepper[key]
  );
  if (errors?.length > 0) {
    history.replace("/auth/sign-up");
    alert(
      "Votre profil n'a pas été complété correctement, veuillez-le vérifier à nouveau"
    );
  }

  useEffect(() => {
    register(signUpStepper).then();
  }, [signUpStepper]);

  return (
    <div className={styles["container"]}>
      <div></div>
      <div>
        <p>Merci pour ton inscription!</p>
        <p>L'équipe WOW reviendra vers toi sous 48 heures.</p>
      </div>
      <div className="flex d-flex justify-content-center">
        <Link
          to={{
            pathname: "https://www.linkedin.com/company/wheelofwork/",
          }}
          target="_blank"
          className={styles["link"]}
        >
          Suivez nos actus sur Linkedin
        </Link>
      </div>
    </div>
  );
};
