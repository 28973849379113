import React from 'react'
import style from './style.module.css'


const ToggleSwitch = ({ name, value, onChange }) => {
    return <label className={style['switch']} htmlFor={name}>
        <input type="checkbox"
            value={value}
            checked={value}
            onChange={onChange}
            className={style['switch-checkbox']}
            name={name} id={name} />
        <div className={style['switch-circle']}></div>
        <div className={style['switch-bg']}></div>
    </label>
}

export default ToggleSwitch