import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import style from "./style.module.css";

const InformationsEntreprise = ({ society }) => {
  const societyLegalInformation = society ?? null;
  const [showed, setShowed] = useState(false);
  const myForm = useRef(null);
  const toggleOpen = (e) => {
    setShowed(!showed);
    if (
      !myForm.current.style.maxHeight ||
      myForm.current.style.maxHeight === "0px"
    ) {
      myForm.current.style.maxHeight = myForm.current.scrollHeight + "px";
    } else {
      myForm.current.style.maxHeight = null;
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      label: "",
      responsiblePersonName: "",
      occupation: "",
      rcs: "",
      siren: "",
      address: {
        street: "",
        postalCode: "",
        city: "",
      },
      type: "",
      tva: "",
      ...societyLegalInformation,
    },
  });


  return (
    <div>
      {societyLegalInformation && (
        <>
          <div className="w-100">
            <div
              className="wow-subtitle bold w-100 d-flex flex-row justify-content-between cursor-pointer mb-3"
              onClick={toggleOpen}
            >
              Les informations de l'entreprise
              <span>{showed ? "-" : "+"}</span>
            </div>
            <form
              ref={myForm}
              className={`row align-items-end justify-content-center ${style["accordion-form"]}`}
            >
              {societyLegalInformation.juridicStructure === "WAGE_PORTING" ? (
                <>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="label"
                    >
                      Nom de la societé de portage
                    </label>
                    <input
                      id="label"
                      name="label"
                      value={formik.values.label}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="responsiblePersonName"
                    >
                      Nom du responsable de la société
                    </label>
                    <input
                      id="responsiblePersonName"
                      name="responsiblePersonName"
                      value={formik.values.responsiblePersonName}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="type"
                    >
                      Type de société
                    </label>
                    <input
                      id="type"
                      name="type"
                      value={
                        formik.values.type === "OTHER"
                          ? "Autre"
                          : formik.values.type === "AUTO_ENTREPRENEUR"
                            ? "Auto entrepreneur"
                            : formik.values.type
                      }
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="occupation"
                    >
                      Fonction
                    </label>
                    <input
                      id="occupation"
                      name="occupation"
                      value={formik.values.occupation}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="label"
                    >
                      Nom de la société
                    </label>
                    <input
                      id="label"
                      name="label"
                      value={formik.values.label}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="rcs"
                    >
                      Ville RCS
                    </label>
                    <input
                      id="rcs"
                      name="rcs"
                      value={formik.values.rcs}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="siren"
                    >
                      siren
                    </label>
                    <input
                      id="siren"
                      name="siren"
                      value={formik.values.siren}
                      className="default-input"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                  <div className="w-100"></div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <label
                      className="small-text bold mb-2 cursor-pointer"
                      htmlFor="address.street"
                    >
                      Adresse siège social
                    </label>
                    <input
                      id="address.street"
                      name="address.street"
                      value={formik.values.address.street}
                      className="default-input"
                      type="text"
                      placeholder="Adresse"
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <input
                      id="address.postalCode"
                      name="address.postalCode"
                      value={formik.values.address.postalCode}
                      className="default-input"
                      type="text"
                      placeholder="Code Postal"
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md d-flex flex-column mb-3 ">
                    <input
                      id="address.city"
                      name="address.city"
                      value={formik.values.address.city}
                      className="default-input"
                      type="text"
                      placeholder="Ville"
                      disabled
                    />
                  </div>
                  {societyLegalInformation.tva && (
                    <div className="col-12 d-flex flex-column mb-3 ">
                      <label
                        className="small-text bold mb-2 cursor-pointer"
                        htmlFor="tva"
                      >
                        N° de TVA
                      </label>
                      <input
                        id="tva"
                        name="tva"
                        value={formik.values.tva}
                        className="default-input"
                        type="text"
                        disabled
                        placeholder="TVA"
                      />
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default InformationsEntreprise;
