import React from "react";
import { FiClock, FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import { durations } from "../../../Helpers/data";
import { capitalizeFirstLetter } from "../../../Helpers/string";
import style from "./style.module.css";

const CurrentMission = ({ year, month, timesheet, mission, invoice }) => {
  //mission
  //mission.society
  //mission.society.logo
  
  //TIMESHEET
  //timesheet
  //year
  //month
  //toSendTimesheet

  //toSendInvoice
  //invoicePaymentDate
  const logo = mission?.society?.logo ?? mission?.logo;

  return (
    <div
      className={style["mission-item"] + " card w-100 d-flex flex-row gap-3"}
    >
      <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-5 align-items-center">
        <div className={style["img-container"]}>
          {logo ? (<img
              src={b64ToObjectUrl(logo.base64Document, logo.filetype)}
              alt="candidature img"
            />)
          : (<img
            src="/wow-ny-logo/wow-circle-white.svg"
            alt="candidature img"
            style={{backgroundColor: 'black',border: '4px solid black',borderRadius: '50%'}}
          />)
          }
          
        </div>
        <div className="w-100 d-flex flex-column gap-3">
          <div className="header-4">
            {mission.label} -{" "}
            {capitalizeFirstLetter(
              new Date(year, month - 1).toLocaleString(
                "fr-Fr",
                { month: "long" }
              )
            )}{" "}
            {year}
          </div>
          <div className="small-text black-text">
            {mission?.society?.label}
          </div>
        </div>
      </div>
      <div className="w-100 d-flex flex-column flex-md-row gap-3 justify-content-center justify-content-md-end align-items-end">
        {!timesheet || (timesheet?.status !== 'PENDING_APPROVAL' && timesheet?.status !== 'ADMIN_APPROVED') ? (
          <Link
            to={`/timesheet/${mission.id}/${year}/${month}`}
          >
            <button className="default-btn">
              Soumettre le compte rendu d'activité
            </button>
          </Link>
        ) : !invoice || invoice?.status === 'REFUSED' ? (
          <Link to={`/profile/factures`}>
            <button className="default-btn">Transmettre ma facture</button>
          </Link>
        ) : (
          invoice?.invoicePaymentDate && (
            <div className="small-text">
              <b>Date de paiement : </b>
              {new Date(invoice.invoicePaymentDate).toLocaleDateString()}
            </div>
          )
        )}
        <Link
          to={{
            pathname: "/contrats",
            state: { id: mission.id },
          }}
        >
          <button className="default-btn primary-btn">Voir mon contrat</button>
        </Link>
      </div>
    </div>
  );
};

export default CurrentMission;
